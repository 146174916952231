import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Collection } from "../../constants/types";
import { Checkbox } from "../common/Checkbox";
import { SearchBar } from "../common/SearchBar";

export const SelectCollection = ({
  selectedIds,
  attributeAddedToCollection,
}: {
  selectedIds: number[];
  attributeAddedToCollection: string;
}) => {
  const url = process.env.REACT_APP_ADMIN_URL;
  const [collection, setCollection] = useState<Collection[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const LIMIT = 10;

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${url}/collections/`, {
        params: {
          limit: LIMIT,
          pageNumber,
          searchInput,
        },
      });
      if (pageNumber === 1) {
        setCollection(response.data.data.results);
      } else {
        setCollection((prevData) => [
          ...prevData,
          ...response.data.data.results,
        ]);
      }
      setTotalPages(response.data.data.totalPages);
    } catch (e) {
      toast.error("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  }, [pageNumber, searchInput]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNumber((prev) => prev + 1);
      }
    }),
  );
  useEffect(() => {
    if (pageNumber <= totalPages || pageNumber === 1) {
      getData();
    }
  }, [pageNumber, getData, totalPages]);

  useEffect(() => {
    setIsLoading(false);
  }, [pageNumber]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const handleSelect = (id: number) => {
    const item = selectedItems.find((item) => item === id);
    if (item) {
      setSelectedItems((prev) => prev.filter((item) => item !== id));
    } else {
      const newItem = collection.find((row) => row.id === id);
      if (newItem) {
        setSelectedItems((prev) => [...prev, newItem.id]);
      }
    }
  };

  const handleAdd = async () => {
    const urls: Promise<void>[] = [];
    selectedItems.map((collectionId) => {
      let payload = {
        [attributeAddedToCollection]: selectedIds,
        collectionId: collectionId,
      };
      urls.push(
        axios.put(
          `${url}/collections/addRelationship/${collectionId}`,
          payload,
        ),
      );
    });
    await Promise.allSettled(urls)
      .then(() => {
        toast(`Relationships were successfully created!`, {
          bodyClassName: "text-green-500 ",
          progressClassName: "bg-green-500",
        });
      })
      .catch(() => {
        toast(`Error creating new relationship between corps and collections`, {
          bodyClassName: "text-red-500 ",
          progressClassName: "bg-red-500",
        });
      });
  };

  const handleSearch = (value: string) => {
    setPageNumber(1);
    setSearchInput(value);
  };

  return (
    <div className="bg-white w-96 p-2 absolute rounded-lg left-28 top-28 z-50">
      <div className="p-3">
        <SearchBar onSearch={(value: string) => handleSearch(value)} />
      </div>
      <div className="mt-3 flex justify-end">
        <button
          className="rounded-lg bg-orange-500 text-white px-2 py-1 h-10 w-20"
          onClick={handleAdd}
        >
          Add
        </button>
      </div>
      <div className="h-56 overflow-scroll scrollbar-hide pe-2">
        {collection.map((item, i) => (
          <div
            className="flex mt-2"
            key={item.id}
            ref={i === collection.length - 1 ? setLastElement : null}
          >
            <Checkbox
              onClick={() => {
                handleSelect(item.id);
              }}
              checked={selectedItems.includes(item.id)}
            />
            <span className="ps-2">{item.name}</span>
          </div>
        ))}
        {isLoading && <div>Loading...</div>}
      </div>
    </div>
  );
};
