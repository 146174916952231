export const imagePath = "/images/";
export const imageIconPath = "/images/icons/";
const images = {
  logo: imagePath + "logo.svg",
  analytics: imageIconPath + "Analytics.svg",
  giftcard: imageIconPath + "card_giftcard.svg",
  customer: imageIconPath + "Customers.svg",
  finance: imageIconPath + "Finances.svg",
  home: imageIconPath + "Home.svg",
  order: imageIconPath + "Orders.svg",
  product: imageIconPath + "Products.svg",
  tick: imageIconPath + "Tick.svg",
  sortAsc: imageIconPath + "SortAsc.svg",
  search: imageIconPath + "search.svg",
  sortDesc: imageIconPath + "SortDesc.svg",
  checkmark: imageIconPath + "Tick.svg",
  visibility: {
    live: imageIconPath + "Live.svg",
    unlisted: imageIconPath + "Unlisted.svg",
    scheduled: imageIconPath + "Scheduled.svg",
  },
  cross: imageIconPath + "Cross.svg",
  plus: imageIconPath + "Plus.svg",
  archive: imageIconPath + "Archive.svg",
  delete: imageIconPath + "Delete.svg",
  edit: imageIconPath + "Edit.svg",
  minus: imageIconPath + "Minus.svg",
  dash: imageIconPath + "Dash.svg",
  bin: imageIconPath + "Bin.svg",
  drag: imageIconPath + "Drag.svg",
  copy: imageIconPath + "Copy.svg",
  deliveryTruck: imageIconPath + "DeliveryTruck.svg",
  envelope: imageIconPath + "Envelope.png",
  phone: imageIconPath + "Phone.svg",
  profile: imageIconPath + "Profile.svg",
  homeGrey: imageIconPath + "HomeGrey.svg",
  calender: imageIconPath + "Calender.svg",
  unarchive: imageIconPath + "Unarchive.svg",
  filter: imageIconPath + "Filter.svg",
  caretUp: imageIconPath + "CaretUp.svg",
  caretDown: imageIconPath + "CaretDown.svg",
};
export default images;
