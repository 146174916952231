type TPageSelectionProps = {
  currentTab: string;
  change: (page: string) => void;
  tabs: { tab: any }[];
};

export const PageSelection = ({
  currentTab,
  change,
  tabs,
}: TPageSelectionProps) => {
  return (
    <div className="flex space-x-2">
      {tabs.map((i: { tab: string }) => (
        <div key={i.tab}>
          <button
            className={`px-6 py-2 border-b-2 text-sm font-semibold ${
              i.tab === currentTab
                ? "text-orange-500 border-b-2 border-b-orange-500"
                : "text-slate-400"
            }`}
            onClick={() => change(i.tab)}
          >
            <div className="align-top text-base">{i.tab}</div>
          </button>
        </div>
      ))}
    </div>
  );
};
