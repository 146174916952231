import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../context/Breadcrumb.context";
import { SearchBar } from "../common/SearchBar";
import { Table, TableHeader } from "../common/Table";
import { FilterPanel, TFilterPanel } from "./FilterPanel";
import {
  GiftLinksFilterWindow,
  TGiftLinksFilters,
} from "./GiftLinksFilterWindow";
import { GiftLinksRow } from "./GiftLinksRow";
import { TGetData } from "./OrdersTab";

const headers: TableHeader[] = [
  { key: "campaign.id", heading: "CAMPAIGN ID", isSortable: true },
  { key: "CampaignRecipient.id", heading: "ORDER ID", isSortable: true },
  { key: "giftLink", heading: "GIFT LINK" },
  { key: "CampaignRecipient.createdAt", heading: "DATE", isSortable: true },
  {
    key: "order.firstName",
    heading: "CUSTOMER",
    isSortable: true,
  },
  {
    key: "CampaignRecipient.isForTesting",
    heading: "STATUS",
    isSortable: true,
  },
  { key: "tools", heading: "" },
];

const initialFilters: TFilterPanel = {
  time: {
    from: new Date(0),
    to: new Date(),
  },
  filter: {
    campaigns: [],
    status: [],
  },
};

export const GiftLinksTab = () => {
  const url = process.env.REACT_APP_ADMIN_URL;
  const { giftLinksTable, setGiftLinksTable } = useContext(BreadcrumbContext);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState(giftLinksTable.pageNumber);
  const [itemsPerPage, setItemsPerPage] = useState(giftLinksTable.limit);
  const [sortBy, setSortBy] = useState(giftLinksTable.sort);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">(
    giftLinksTable.sortOrder,
  );
  const [isFetching, setIsFetching] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [searchInput, setSearchInput] = useState(giftLinksTable.q);
  const getData = useCallback(
    async (filters: TGetData) => {
      const controller = new AbortController();
      setIsFetching(true);
      try {
        const response = await axios.get(`${url}/orders/gift-links`, {
          params: {
            ...filters,
            sort: sortBy,
            sortOrder,
          },
          signal: controller.signal,
        });

        const { data } = response.data;

        if (data && data.results) {
          setData(data.results);
          setTotalPages(data.totalPages);
        } else {
          setData([]);
          setTotalPages(0);
        }
      } catch (error) {
        console.error("Error fetching gift links:", error);
        setIsFetching(false);
      }
      setIsFetching(false);
      return () => controller.abort();
    },
    [url, sortBy, sortOrder],
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
  };

  useEffect(() => {
    getData({
      ...filters,
      sort: sortBy,
      sortOrder,
      pageNumber,
      limit: itemsPerPage,
      q: searchInput,
    });
  }, [getData, filters, itemsPerPage, pageNumber, sortBy, sortOrder]);

  const handleFiltersChange = (data: TGiftLinksFilters) => {
    if (data) {
      const newFilters = filters.filter;
      newFilters.campaigns = data.campaigns;
      newFilters.status = data.status;
      setFilters({ ...filters, filter: newFilters });
    }
  };

  const handleRefresh = () => {
    setFilters(initialFilters);
    setSearchInput("");
    setPageNumber(1);
    setItemsPerPage(10);
    setSortBy("");
    setSortOrder("DESC");
    getData({
      ...initialFilters,
      q: "",
      sort: "",
      sortOrder: "DESC",
      pageNumber: 1,
      limit: 10,
    });
  };

  useEffect(() => {
    setGiftLinksTable({
      time: { from: filters.time.from, to: filters.time.to },
      filter: {
        campaigns: filters.filter.campaigns,
        status: filters.filter.status,
      },
      q: searchInput,
      sort: sortBy,
      sortOrder: sortOrder,
      pageNumber: pageNumber,
      limit: itemsPerPage,
    });
  }, [filters, searchInput, sortBy, sortOrder, pageNumber, itemsPerPage]);

  return (
    <div className="col-span-12 w-full grid grid-cols-4 gap-y-2">
      <div className="col-span-12">
        <FilterPanel
          value={filters}
          filterWindow={
            <GiftLinksFilterWindow
              values={{
                status: filters.filter.status,
                campaigns: filters.filter.campaigns,
              }}
              onFiltersChange={(data) => handleFiltersChange(data)}
            />
          }
          onFilterChange={(filters) => setFilters(filters)}
          refresh={handleRefresh}
        />
      </div>
      <div className="col-span-12 h-12">
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className="col-span-12 overflow-scroll">
        <Table
          headers={headers}
          rows={data.map((item) => ({
            id: item.id,
            component: <GiftLinksRow component={item} />,
          }))}
          pageNumber={pageNumber}
          setPageNumber={(p) => setPageNumber(p)}
          setItemsPerPage={(p) => setItemsPerPage(p)}
          itemsPerPage={itemsPerPage}
          totalPages={totalPages}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setSelectProductIds={() => {}}
          isLoading={isFetching}
          loadingMessage="Fetching gift links..."
        />
      </div>
    </div>
  );
};
