import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import images from "../../constants/images";
import { BreadcrumbContext } from "../../context/Breadcrumb.context";
import { Modal } from "../common/Modal";
import { SearchBar } from "../common/SearchBar";
import { Table, TableHeader } from "../common/Table";
import { CampaignDetail } from "./CampaignDetail";
import { CustomerDetail } from "./CustomerDetail";
import { FilterPanel, TFilterPanel } from "./FilterPanel";
import { OrderRow } from "./OrderRow";
import { OrdersFilterWindow, TOrderFilters } from "./OrdersFilterWindow";
import { ProductDetail } from "./ProductDetail";

enum OrderTableHeaders {
  OrderId = "ORDER ID",
  Date = "DATETIME",
  CampaignName = "CAMPAIGN NAME",
  Brand = "BRAND",
  GiftSelected = "GIFT SELECTED",
  Recipient = " RECIPIENT NAME",
  Mode = "MODE",
  Status = "STATUS",
  Remark = "REMARK",
}

const headers: TableHeader[] = [
  {
    key: "ConsolidatedOrder.id",
    heading: OrderTableHeaders.OrderId,
    isSortable: true,
  },
  {
    key: "ConsolidatedOrder.createdAt",
    heading: OrderTableHeaders.Date,
    isSortable: true,
  },
  {
    key: "campaign.name",
    heading: OrderTableHeaders.CampaignName,
    isSortable: true,
  },
  {
    key: "BRAND",
    heading: OrderTableHeaders.Brand,
    isSortable: false,
  },
  { key: "giftSelected", heading: OrderTableHeaders.GiftSelected },
  {
    key: "ConsolidatedOrder.firstName",
    heading: OrderTableHeaders.Recipient,
    isSortable: true,
  },
  { key: "mode", heading: OrderTableHeaders.Mode, isSortable: true },
  {
    key: "ConsolidatedOrder.status.name",
    heading: OrderTableHeaders.Status,
    isSortable: true,
  },
  { key: "REMARK", heading: OrderTableHeaders.Remark, isSortable: false },
];

const initialFilters: TFilterPanel = {
  time: {
    from: new Date(0),
    to: new Date(),
  },
  filter: {
    campaigns: [],
    status: [],
    mode: [],
  },
};

export type TGetData = TFilterPanel & {
  q: string;
  sort: string;
  sortOrder: "ASC" | "DESC";
  pageNumber: number;
  limit: number;
};

export const OrdersTab = () => {
  const { ordersTable, setOrdersTable } = useContext(BreadcrumbContext);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [filters, setFilters] = useState(initialFilters);

  const url = process.env.REACT_APP_ADMIN_URL;
  const [pageNumber, setPageNumber] = useState(ordersTable.pageNumber);
  const [itemsPerPage, setItemsPerPage] = useState(ordersTable.limit);
  const [sortBy, setSortBy] = useState(ordersTable.sort);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">(
    ordersTable.sortOrder,
  );
  const [isFetching, setIsFetching] = useState(true);
  const [searchInput, setSearchInput] = useState(ordersTable.q);
  const [orderStatuses, setOrderStatuses] = useState<any[]>([]);
  const [showCustomerDetailModal, setShowCustomerDetailModal] =
    useState<boolean>(false);
  const [selectedOrderItems, setSelectedOrderItems] = useState<any>({});
  const [showOrderStatusModal, setShowOrderStatusModal] =
    useState<boolean>(false);
  const [showProductModal, setShowProductModal] = useState<boolean>(false);
  const [showCampaignDetailModal, setShowCampaignDetailModal] =
    useState<boolean>(false);
  const [showRemarksModal, setShowRemarksModal] = useState<boolean>(false);
  const giftingUrl = process.env.REACT_APP_GIFTING_URL;

  const getData = useCallback(
    async (filters: TGetData) => {
      const controller = new AbortController();
      setIsFetching(true);

      axios
        .get(`${url}/orders/all`, {
          params: { ...filters, sort: sortBy, sortOrder },
          signal: controller.signal,
        })
        .then((resp) => {
          const result = resp.data.data;
          setData(result.results);
          console.log(result.results);
          setTotalPages(result.totalPages);
          setIsFetching(false);
        })
        .catch(() => setIsFetching(false));
      return () => controller.abort();
    },
    [url, sortBy, sortOrder],
  );
  console.log(data, "data");
  const handleSearch = (value: string) => {
    setSearchInput(value);
  };

  const handleSave = () => {
    axios
      .put(`${url}/orders/${selectedOrderItems.id}`, {
        note: selectedOrderItems.note ? selectedOrderItems.note : null,
      })
      .then(() => {
        toast.success("Order updated successfully", {
          position: "top-right",
          bodyClassName: "text-green-500",
        });
      })
      .catch(() => {
        toast.error("Error updating order", {
          position: "top-right",
          bodyClassName: "text-red-500",
        });
      });
  };

  const handleFiltersChange = (data: TOrderFilters) => {
    const newFilters = filters.filter as TOrderFilters;
    newFilters.mode = data.mode;
    newFilters.campaigns = data.campaigns;
    newFilters.status = data.status;
    setFilters({ ...filters, filter: newFilters });
  };

  const handleRefresh = () => {
    setFilters(initialFilters);
    setSearchInput("");
    setPageNumber(1);
    setItemsPerPage(10);
    setSortBy("");
    setSortOrder("DESC");
    getData({
      ...initialFilters,
      q: "",
      sort: "",
      sortOrder: "DESC",
      pageNumber: 1,
      limit: 10,
    });
  };

  useEffect(() => {
    getData({
      ...filters,
      sort: sortBy,
      sortOrder,
      pageNumber,
      limit: itemsPerPage,
      q: searchInput,
    });
  }, [
    getData,
    filters,
    itemsPerPage,
    pageNumber,
    sortBy,
    sortOrder,
    searchInput,
  ]);

  const selectedOrderRow = (itemId: number) => {
    setSelectedOrderItems(data.find((item) => item.id === itemId));
  };

  useEffect(() => {
    axios
      .get(`${url}/orders/orderStatuses`)
      .then((res) => {
        setOrderStatuses(res.data.data);
      })
      .catch(() => {
        toast.error("Error updating order", {
          position: "top-right",
          bodyClassName: "text-red-500",
        });
      });
  }, []);

  const updateConsolidatedOrderStatus = (
    newOrderStatus: string,
    orderId: number,
  ) => {
    axios
      .put(`${url}/orders/orderStatus/${orderId}`, {
        newOrderStatus: newOrderStatus,
      })
      .then(async () => {
        toast.success("Order updated successfully", {
          position: "top-right",
          bodyClassName: "text-green-500",
        });

        if (newOrderStatus === "COMPLETED") {
          try {
            await sendOrderCompletedEmail(orderId);
          } catch (error) {
            console.error("Failed to send order completion email", error);
          }
        }

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(() => {
        toast.error("Error updating order", {
          position: "top-right",
          bodyClassName: "text-red-500",
        });
      });
  };

  const sendOrderCompletedEmail = async (orderId: number) => {
    try {
      const response = await axios.post(
        `${giftingUrl}/api/gifting/order/completed-email`,
        {
          orderId,
        },
      );
      if (response.status === 200) {
        toast.success("Order completion email sent successfully", {
          position: "top-right",
          bodyClassName: "text-green-500",
        });
      }
    } catch (error) {
      toast.error("Failed to send order completion email", {
        position: "top-right",
        bodyClassName: "text-red-500",
      });
    }
  };

  useEffect(() => {
    setOrdersTable({
      time: { from: filters.time.from, to: filters.time.to },
      filter: {
        campaigns: filters.filter.campaigns,
        status: filters.filter.status,
      },
      q: searchInput,
      sort: sortBy,
      sortOrder: sortOrder,
      pageNumber: pageNumber,
      limit: itemsPerPage,
    });
  }, [filters, searchInput, sortBy, sortOrder, pageNumber, itemsPerPage]);

  return (
    <div className="col-span-12 w-full grid grid-cols-4 gap-y-2">
      <div className="col-span-12">
        <FilterPanel
          value={filters}
          filterWindow={
            <OrdersFilterWindow
              values={
                {
                  mode:
                    "mode" in filters.filter ? filters.filter.mode : undefined,
                  status: filters.filter.status,
                  campaigns: filters.filter.campaigns,
                } as TOrderFilters
              }
              onFiltersChange={(data) => handleFiltersChange(data)}
            />
          }
          onFilterChange={(filters) => setFilters(filters)}
          refresh={handleRefresh}
        />
      </div>
      <div className="col-span-12 h-12">
        <SearchBar onSearch={handleSearch} />
      </div>

      <div className="col-span-12 overflow-scroll">
        <Table
          headers={headers}
          rows={data.map((item) => ({
            id: item.id,
            component: (
              <OrderRow
                key={item.id}
                rowInfo={item}
                openCustomerDetail={() => setShowCustomerDetailModal(true)}
                openOrderStatus={() => setShowOrderStatusModal(true)}
                openProductDetail={() => {
                  setShowProductModal(true);
                }}
                openCampaignDetail={() => {
                  setShowCampaignDetailModal(true);
                }}
                openRemarks={() => setShowRemarksModal(true)}
              />
            ),
          }))}
          pageNumber={pageNumber}
          setPageNumber={(p) => setPageNumber(p)}
          setItemsPerPage={(p) => setItemsPerPage(p)}
          itemsPerPage={itemsPerPage}
          totalPages={totalPages}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setSelectProductIds={() => {}}
          isLoading={isFetching}
          loadingMessage="Fetching orders..."
          selectedItemId={selectedOrderRow}
        />
      </div>
      {showCustomerDetailModal && selectedOrderItems && (
        <CustomerDetail
          customer={selectedOrderItems}
          closeModal={() => {
            setShowCustomerDetailModal(false);
          }}
        />
      )}
      {selectedOrderItems.orders && (
        <Modal
          isModalVisible={showProductModal}
          children={
            <ProductDetail
              orders={selectedOrderItems.orders}
              closeModal={() => setShowProductModal(false)}
            />
          }
        />
      )}

      <Modal
        isModalVisible={showOrderStatusModal}
        children={
          <div className="bg-white p-8 w-96 rounded-md">
            <div className="flex justify-between">
              <div className="text-xl font-LeagueSpartan">Status Update</div>
              <button
                onClick={() => setShowOrderStatusModal(false)}
                id={selectedOrderItems.id}
              >
                <img src={images.cross} alt="Close" />
              </button>
            </div>
            <div className="mt-4 text-sm text-slate-500">
              Status for order ID {selectedOrderItems?.id}
            </div>
            <select
              name="orderStatus"
              id="orderStatus"
              value={selectedOrderItems?.status?.name}
              onChange={(e) => {
                updateConsolidatedOrderStatus(
                  e.target.value,
                  selectedOrderItems?.id,
                );
              }}
              className="border-none mt-2"
            >
              {orderStatuses &&
                orderStatuses.map((orderStatus) => {
                  return (
                    <option key={orderStatus.name} value={orderStatus.name}>
                      {orderStatus.name}
                    </option>
                  );
                })}
            </select>
          </div>
        }
      />

      <Modal
        isModalVisible={showCampaignDetailModal}
        children={
          <CampaignDetail
            campaign={selectedOrderItems.campaign}
            closeModal={() => setShowCampaignDetailModal(false)}
          />
        }
      />

      <Modal
        isModalVisible={showRemarksModal}
        children={
          <div className="bg-white p-6 rounded-md shadow-md w-96">
            <h2 className="text-xl font-semibold mb-4">Add a Note</h2>
            <textarea
              className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:ring-orange-500"
              rows={5}
              value={selectedOrderItems.note ? selectedOrderItems.note : ""}
              onChange={(e) => {
                setSelectedOrderItems({
                  ...selectedOrderItems,
                  note: e.target.value,
                });
              }}
              placeholder="Enter your note here..."
              id={selectedOrderItems.id}
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 text-slate-400 rounded-md hover:bg-gray-100"
                onClick={() => {
                  setShowRemarksModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};
