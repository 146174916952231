import axios from "axios";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import images from "../constants/images";
import { DragAndDrop } from "./common/DragAndDrop";
import { AddEditCategory } from "./dashboard/CategoryTab";

const initialValues: CategoryInfo = {
  name: "",
  icon: "",
  image: null,
};

const categorySchema = Yup.object().shape({
  name: Yup.string().required("Required."),
  icon: Yup.string(),
  image: Yup.string().nullable(),
});

export type CategoryInfo = {
  id?: number;
  name: string;
  icon: string;
  image: string | null;
  productCategories?: any;
};

type TAddCategoryPage = {
  data?: AddEditCategory;
  onClose: () => void;
};

export const AddCategoryPage = ({ data, onClose }: TAddCategoryPage) => {
  const url = process.env.REACT_APP_ADMIN_URL;

  if (data) {
    initialValues.name = data.name;
    initialValues.icon = data.icon;
    initialValues.image = data.image;
  }

  const create = async ({ name, icon, image }: CategoryInfo) => {
    const payload = {
      name,
      icon,
      image,
    };

    await axios
      .post(`${url}/categories`, payload)
      .then(() => {
        toast.success("Category created successfully", {
          position: "top-right",
          bodyClassName: "text-green-500",
        });
        onClose();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message, {
          position: "top-right",
          bodyClassName: "text-red-500",
        });
      });
  };

  const update = async (values: CategoryInfo) => {
    const { name, icon, image } = values;

    const payload = {
      id: data?.id,
      name: name,
      icon: icon,
      image: image ? image : null,
    };

    await axios
      .put(`${url}/categories/${data?.id}`, payload)
      .then(() => {
        toast.success("Category updated successfully", {
          position: "top-right",
          bodyClassName: "text-green-500",
        });
        onClose();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message, {
          bodyClassName: "text-red-500",
        });
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Formik
      validationSchema={categorySchema}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        data?.id !== 0 ? update(values) : create(values);
        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        handleChange,
        handleBlur,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="bg-black bg-opacity-60 flex items-center justify-center overflow-scroll fixed top-0 left-0 w-full h-full">
            <div className="bg-white rounded-lg sm:w-3/5 xl:w-1/2">
              <div className="mx-8 my-6">
                <div className="flex justify-between items-start">
                  <h3 className="text-lg font-bold mb-6 text-black">
                    {data?.id ? "Edit" : "New"} Category
                  </h3>
                  <button onClick={handleClose}>
                    <img src={images.cross} alt="" />
                  </button>
                </div>
                <Field name="name">
                  {() => (
                    <div>
                      <div className="flex">
                        <p className="text-md">Main Category</p>
                        <span className="italic text-slate-500 text-xs pl-1.5 self-center">
                          required
                        </span>
                      </div>
                      <input
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="border-[1px] border-[neutral-400] p-2 text-base rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none mt-2"
                        placeholder="Category Name"
                      />
                    </div>
                  )}
                </Field>
                {errors.name && touched.name ? (
                  <div className="text-red-500 text-sm mt-2 mb-6">
                    {errors.name}
                  </div>
                ) : (
                  <></>
                )}
                <Field name="icon">
                  {() => (
                    <div className="flex justify-between mt-4">
                      <span className="text-md flex items-center">Icon</span>
                      <input
                        type="text"
                        name="icon"
                        value={values.icon}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="border-[1px] border-[neutral-400] p-2 text-base rounded-lg focus:border-2 focus:border-orange-600 outline-none w-1/3"
                        placeholder="Icon"
                      />
                    </div>
                  )}
                </Field>
                {errors.icon && touched.icon ? (
                  <div className="text-red-500 text-sm mt-2 mb-6">
                    {errors.icon}
                  </div>
                ) : (
                  <></>
                )}

                <Field name="image">
                  {() => (
                    <div className="mt-4">
                      <span className="text-md">Image</span>
                      <DragAndDrop
                        numOfImages={1}
                        getAzureImageUrl={(e: string[]) => {
                          setFieldValue("image", e[0]);
                        }}
                        updateImgUrl={values.image ? [values.image] : [""]}
                      />
                    </div>
                  )}
                </Field>
                {errors.image && touched.image ? (
                  <div className="text-red-500 text-sm mt-2 mb-6 text-center">
                    {errors.image}
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex justify-end mt-8 mb-6">
                  <button
                    type="submit"
                    className="rounded-lg py-1 px-2 text-white bg-slate-400"
                  >
                    {data?.id ? "Update" : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
