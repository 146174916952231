import { createContext, useState } from "react";
import { DeepPartial,Corp } from "../constants/types"; 



export const CorpContext = createContext<{
  corp: DeepPartial<Corp>;
  setCorp: React.Dispatch<React.SetStateAction<DeepPartial<Corp>>>;
  setCorpId: (corpId: number) => void;
}>({
  corp: {},
  setCorp: () => {},
  setCorpId: () => {},
});

export const CorpContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [corp, setCorp] = useState<DeepPartial<Corp>>({});

  const setCorpId = (corpId: number) => {
    setCorp((prevCorp) => ({
      ...prevCorp,
      id: corpId,
    }));
  };

  return (
    <CorpContext.Provider value={{ corp, setCorp, setCorpId }}>
      {children}
    </CorpContext.Provider>
  );
};
