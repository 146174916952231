import { Link } from "react-router-dom";
import { Checkbox } from "../common/Checkbox";

type RowProps = {
  row: {
    sku: string;
    name: string;
    minPrice: number;
    maxPrice: number;
    selected: boolean;
    image?: string;
  };
  onSelected: {
    selected: boolean;
    handleSelect: (id: string) => void;
  };
};

export const VoucherRow = ({ row, onSelected }: RowProps) => {
  const handleCheck = () => {
    onSelected.handleSelect(row.sku);
  };

  return (
    <tr
      key={row.sku}
      className={`items-center text-center border-b-2 border-gray-300 ${
        onSelected.selected ? "bg-[#FFEAE1]" : ""
      }`}
    >
      <td>
        <Checkbox
          key={row.sku}
          onClick={handleCheck}
          checked={onSelected.selected}
        />
      </td>
      {onSelected.selected ? (
        <Link draggable="false" to={`/voucher/${row.sku}`}>
          <td className="grid grid-cols-6 p-2">
            <div className="col-span-1 flex justify-center">
              <img src={row.image} alt="" className="w-24" />
            </div>
            <div className="col-span-5 p-2 flex items-center">
              <span className="text-left">{row.name}</span>
            </div>
          </td>
        </Link>
      ) : (
        <td className="grid grid-cols-6 p-2">
          <div className="col-span-1 flex justify-center">
            <img src={row.image} alt="" className="w-24" />
          </div>
          <div className="col-span-5 p-2 flex items-center">
            <span className="text-left">{row.name}</span>
          </div>
        </td>
      )}

      <td>
        <p className="mb-2">${row.minPrice.toFixed(2)}</p>
      </td>
      <td>
        {" "}
        <p className="mb-2">${row.maxPrice.toFixed(2)}</p>
      </td>
    </tr>
  );
};
