import { useContext, useEffect, useState } from "react";
import images from "../../constants/images";
import { PickUp } from "../../constants/types";
import { ProductContext } from "../../context/Product.context";
import { Checkbox } from "../common/Checkbox";

export const PickUpField = () => {
  const {
    product,
    setProduct,
    addPickUpOptions,
    removePickUpOptions,
    editPickUpOptions,
  } = useContext(ProductContext);

  const [minDaysNeeded, setMinDaysNeeded] = useState<number>(0);
  const [maxDaysNeeded, setMaxDaysNeeded] = useState<number>(0);

  useEffect(() => {
    const pickUpArr =
      product.pickUp || product.pickUp !== undefined ? product.pickUp : [];
    if (pickUpArr.length === 0) {
      setMinDaysNeeded(0);
      setMaxDaysNeeded(0);
    } else {
      let minEarliest = pickUpArr[0]?.pickUpDurationEarliest ?? undefined;
      let maxLatest = pickUpArr[0]?.pickUpDurationLatest ?? undefined;

      for (let i = 1; i < pickUpArr.length; i++) {
        const pickUp = pickUpArr[i];
        if (
          pickUp &&
          pickUp.pickUpDurationEarliest !== undefined &&
          (minEarliest === undefined ||
            pickUp.pickUpDurationEarliest < minEarliest)
        ) {
          minEarliest = pickUp.pickUpDurationEarliest;
        }
        if (
          pickUp &&
          pickUp.pickUpDurationLatest !== undefined &&
          (maxLatest === undefined || pickUp.pickUpDurationLatest > maxLatest)
        ) {
          maxLatest = pickUp.pickUpDurationLatest;
        }
      }
      setMinDaysNeeded(minEarliest ?? 0);
      setMaxDaysNeeded(maxLatest ?? 0);
    }
  }, [product.pickUp]);

  const addLocation = () => {
    addPickUpOptions();
  };

  const handleLocationChange = (index: number, value: string) => {
    editPickUpOptions(index, {
      address: value,
      pickUpDurationEarliest: minDaysNeeded,
      pickUpDurationLatest: maxDaysNeeded,
    } as PickUp);
  };

  const removeLocation = (index: number) => {
    removePickUpOptions(index);
  };

  const handleCheckboxChange = () => {
    setProduct((prevProduct) => {
      const updatedProduct = {
        ...prevProduct,
        hasSelfPickupOption: !prevProduct.hasSelfPickupOption,
      };
      return updatedProduct;
    });
  };

  return (
    <>
      <div className=" my-3">
        <div className="flex">
          <Checkbox
            onClick={handleCheckboxChange}
            checked={product.hasSelfPickupOption ? true : false}
          />
          <span
            className={`text-[14px] min-w-[200px] font-PublicSans ${
              product.hasSelfPickupOption ? "text-orange-600" : undefined
            }`}
          >
            Available for Pickup
          </span>
        </div>

        {product.hasSelfPickupOption && (
          <div className="">
            <div className="my-1 mt-4">
              <span>Location</span>

              {product.pickUp &&
                product.pickUp.map((_, index) => (
                  <div className="flex" key={index}>
                    <input
                      type="text"
                      className="mt-1 border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 w-full focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="Location"
                      value={_?.address}
                      onChange={(e) =>
                        handleLocationChange(Number(index), e.target.value)
                      }
                    />
                    <button
                      className="flex items-center ps-4"
                      onClick={() => removeLocation(index)}
                    >
                      <img src={images.bin} alt="" />
                    </button>
                  </div>
                ))}

              <div className="text-orange-600">
                <button
                  className="inline-flex items-center"
                  onClick={addLocation}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "4px" }}
                  >
                    <path
                      d="M12 5.5V19.5M5 12.5H19"
                      stroke="#ea580c"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ps-2 pt-1">Add Location</span>
                </button>
              </div>
            </div>

            <div className="mt-4">
              <span>Dates</span>
              <div className="flex justify-between my-1">
                <div className="w-5/12 ">
                  <small className="ps-2">Minimum Days</small>
                  <input
                    type="number"
                    name="minDaysNeeded"
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 block w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="0"
                    min="0"
                    value={minDaysNeeded}
                    onChange={(e) => {
                      const newMinDaysNeeded = Number(e.target.value);
                      setMinDaysNeeded(newMinDaysNeeded);

                      const updatedPickUp = (product?.pickUp || []).map(
                        (pickUp) => ({
                          ...pickUp,
                          pickUpDurationEarliest: newMinDaysNeeded,
                        }),
                      );

                      setProduct((prevProduct) => ({
                        ...prevProduct,
                        pickUp: updatedPickUp,
                      }));
                    }}
                  />
                </div>

                <div className="flex items-center">
                  <img src={images.minus} alt="" />
                </div>

                <div className="w-5/12 ">
                  <small className="ps-2">Maximum Days</small>
                  <input
                    type="number"
                    name="maxDaysNeeded"
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 block w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="0"
                    min="0"
                    value={maxDaysNeeded}
                    onChange={(e) => {
                      const newMaxDaysNeeded = Number(e.target.value);
                      setMaxDaysNeeded(newMaxDaysNeeded);

                      const updatedPickUp = (product?.pickUp || []).map(
                        (pickUp) => ({
                          ...pickUp,
                          pickUpDurationLatest: newMaxDaysNeeded,
                        }),
                      );

                      setProduct((prevProduct) => ({
                        ...prevProduct,
                        pickUp: updatedPickUp,
                      }));
                    }}
                    onBlur={(e) => {
                      if (maxDaysNeeded < minDaysNeeded) {
                        setMaxDaysNeeded(minDaysNeeded);
                        const updatedPickUp = (product?.pickUp || []).map(
                          (pickUp) => ({
                            ...pickUp,
                            pickUpDurationLatest: minDaysNeeded,
                          }),
                        );
                        setProduct((prevProduct) => ({
                          ...prevProduct,
                          pickUp: updatedPickUp,
                        }));
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={`border-b-2 border-slate-400 mt-4`}></div>
          </div>
        )}
      </div>
    </>
  );
};
