import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { Category } from "../../constants/types";
import { AddCategoryPage, CategoryInfo } from "../AddCategoryPage";
import { LoadingSpinner } from "../common/LoadingSpinner";
import { SearchBar } from "../common/SearchBar";
import { CategoryCard } from "./CategoryCard";

type TCategoryTab = {
  action: (createNew: () => void) => void;
};

export type AddEditCategory = Omit<CategoryInfo, "productCategories">;

export const CategoryTab = ({ action }: TCategoryTab) => {
  const [data, setData] = useState<Category[]>([]);
  const [selectedItem, setSelectedItem] = useState<AddEditCategory>({
    id: 0,
    name: "",
    icon: "",
    image: null,
  });
  const [addCategory, setAddCategory] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const loader = useRef(null);

  const LIMIT = 10;

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNumber((prev) => prev + 1);
      }
    }),
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/categories`, {
        params: {
          limit: LIMIT,
          pageNumber,
          searchInput,
        },
      })
      .then((resp) => {
        if (pageNumber === 1) {
          setData(resp.data.data.results);
        } else {
          setData((prev) => [...prev, ...resp.data.data.results]);
        }
        setTotalPages(resp.data.data.totalPages);
      });
    setIsLoading(false);
  }, [pageNumber, searchInput]);

  useEffect(() => {
    setIsLoading(false);
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber <= totalPages || pageNumber === 1) {
      getData();
    }
  }, [pageNumber, getData, totalPages]);

  const handleAddCategory = useCallback(() => {
    setAddCategory(!addCategory);
  }, [addCategory]);

  useEffect(() => {
    action(() => handleAddCategory);
  }, [action, handleAddCategory]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const handleEditCategory = (info: AddEditCategory) => {
    setAddCategory(true);
    setSelectedItem(info);
  };

  const handleClose = () => {
    setSelectedItem({
      id: 0,
      name: "",
      icon: "",
      image: null,
    });
    setAddCategory(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && searchInput) {
        getData();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [getData, searchInput]);

  return (
    <div className="grid gap-y-2">
      <SearchBar onSearch={(value: string) => handleSearch(value)} />

      <div className="grid grid-cols-3 gap-3">
        {data.map((_, i) => {
          const { id, name, icon, image, productCategories } = data[
            i
          ] as CategoryInfo;

          return isLoading && pageNumber <= totalPages && i === LIMIT - 1 ? (
            <div key={id}>
              <CategoryCard
                name={name}
                productCategories={productCategories}
                edit={() => handleEditCategory({ id, name, image, icon })}
              />
            </div>
          ) : (
            <div key={id} ref={setLastElement}>
              <CategoryCard
                name={name}
                productCategories={productCategories}
                edit={() => handleEditCategory({ id, name, image, icon })}
              />
            </div>
          );
        })}
      </div>
      {isLoading && pageNumber !== totalPages - 1 && (
        <div ref={loader} className="flex justify-center mt-4">
          <LoadingSpinner className="" />
        </div>
      )}

      {addCategory && (
        <AddCategoryPage data={selectedItem} onClose={handleClose} />
      )}
    </div>
  );
};
