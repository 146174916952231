import { useEffect, useState } from "react";
import images from "../../../constants/images";
import { Checkbox } from "../Checkbox";

export const VisibilityFilter = ({
  setVisibilityFilter,
  selectedVisibility,
}: {
  setVisibilityFilter: (
    displayLive: boolean,
    displayUnlisted: boolean,
    displayScheduled: boolean,
  ) => void;
  selectedVisibility: {
    selectedLive: boolean;
    selectedUnlisted: boolean;
    selectedScheduled: boolean;
  };
}) => {
  const [showBody, setShowBody] = useState<boolean>(false);
  const [showLive, setShowLive] = useState<boolean>(
    selectedVisibility.selectedLive,
  );
  const [showUnlisted, setShowUnlisted] = useState<boolean>(
    selectedVisibility.selectedUnlisted,
  );
  const [showScheduled, setShowScheduled] = useState<boolean>(
    selectedVisibility.selectedScheduled,
  );

  useEffect(() => {
    setShowLive(selectedVisibility.selectedLive);
    setShowUnlisted(selectedVisibility.selectedUnlisted);
    setShowScheduled(selectedVisibility.selectedScheduled);
  }, [selectedVisibility]);

  useEffect(() => {
    setVisibilityFilter(showLive, showUnlisted, showScheduled);
  }, [showLive, showScheduled, showUnlisted]);

  return (
    <div>
      <div className="flex justify-between border-t-2 border-y-slate-200 py-2">
        <div>VISIBILITY</div>
        <img
          src={showBody ? images.caretUp : images.caretDown}
          onClick={() => setShowBody(!showBody)}
        />
      </div>
      {showBody && (
        <div className="py-4 space-y-2 text-slate-600 border-t-2 border-y-slate-100">
          <div className="flex">
            <Checkbox
              checked={showLive}
              onClick={() => setShowLive(!showLive)}
            />
            Live
          </div>
          <div className="flex">
            <Checkbox
              checked={showUnlisted}
              onClick={() => setShowUnlisted(!showUnlisted)}
            />
            Unlisted
          </div>
          <div className="flex">
            <Checkbox
              checked={showScheduled}
              onClick={() => setShowScheduled(!showScheduled)}
            />
            Scheduled
          </div>
        </div>
      )}
    </div>
  );
};
