import { useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CreateAccountPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [username, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmpassword, setConfirmPassword] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<{
    value: string;
    format: string;
  }>({
    value: searchParams.get("phoneNumber") || "",
    format: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    username: "",
    confirmpassword: "",
    phoneNumber: "",
  });

  const register = useCallback(() => {
    if (Object.values(errors).find((v) => v !== "")) {
      toast.error("Enter valid details");
      return;
    }
    if (email === "" || password === "" || username === "") {
      toast.error("Enter valid details");
      return;
    }
    if (password !== confirmpassword) {
      toast.error("Password and confirm password doesn't match");
      return;
    }

    if (!phoneNumber.value) {
      toast.error("Phone number is required");
      return;
    }

    if (
      phoneNumber.value.length !==
      String(phoneNumber.format).replace(/[^.]/g, "").length
    ) {
      toast.error("Phone number is not valid");
      return;
    }

    const callback = searchParams.get("callback");

    axios
      .post(`${process.env.REACT_APP_AUTH_URL}/api/auth/access`, {
        email,
        password,
        username,
        phoneNumber: phoneNumber.value,
        callback,
      })
      .then((res) => {
        console.log(res);
        toast.success("Registration successful");
        navigate("/corps");
      })
      .catch((err) => {
        console.log(err);
        if (axios.isAxiosError(err)) {
          toast.error(err.response?.data.message || "Invalid Credentials");
          return;
        }
        toast.error("Invalid Credentials");
      });
  }, [
    confirmpassword,
    email,
    errors,
    navigate,
    password,
    phoneNumber.format,
    phoneNumber.value,
    searchParams,
    username,
  ]);

  return (
    <div className="flex flex-col gap-2 p-4">
      <label className="text-zinc-800 text-base font-leagueSpartan-400">
        User Name
      </label>
      <div
        className="flex items-center border rounded-md p-1"
        style={{ width: "100%", height: "45px" }}
      >
        <input
          type="text"
          placeholder="Enter your user name"
          className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300"
          value={username}
          onChange={(e) => {
            const v = e.target.value;
            if (v === "") {
              setErrors((e) => ({ ...e, username: "User name required." }));
            } else {
              setErrors((e) => ({ ...e, username: "" }));
            }
            setUserName(v);
          }}
        />
      </div>
      <label className="text-red-500 text-sm">{errors.username}</label>

      <label className="text-zinc-800 text-base font-leagueSpartan-400">
        Email Address
      </label>
      <div
        className="flex items-center border rounded-md p-1"
        style={{ width: "100%", height: "45px" }}
      >
        <input
          type="text"
          placeholder="Enter your email address"
          className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300"
          value={email}
          onChange={(e) => {
            const v = e.target.value;
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v)) {
              setErrors((e) => ({ ...e, email: "Invalid email." }));
            } else {
              setErrors((e) => ({ ...e, email: "" }));
            }
            setEmail(v);
          }}
        />
      </div>
      <label className="text-red-500 text-sm">{errors.email}</label>

      <label className="text-zinc-800 text-base font-leagueSpartan-400">
        Phone Number
      </label>
      <div className="flex items-center border rounded-md p-1">
        <PhoneInput
          inputClass="w-full rounded-lg outline-none border-none border-0 focus:ring-0 text-gray-700 font-leagueSpartan-300"
          country={"sg"}
          preferredCountries={["sg"]}
          enableSearch={true}
          placeholder=""
          value={phoneNumber.value}
          inputProps={{ name: "phoneNumber", required: true }}
          onMount={(value, data) =>
            setPhoneNumber({ value, format: (data as any).format })
          }
          onChange={(value, data) =>
            setPhoneNumber({ value, format: (data as any).format })
          }
        />
      </div>
      <label className="text-red-500 text-sm">{errors.phoneNumber}</label>

      <label className="text-zinc-800 text-base font-leagueSpartan-400">
        Password
      </label>
      <div
        className="flex items-center border rounded-md p-1"
        style={{ width: "100%", height: "45px" }}
      >
        <input
          type="password"
          placeholder="Enter your password"
          className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300"
          value={password}
          onChange={(e) => {
            const v = e.target.value;
            if (v.length < 6) {
              setErrors((e) => ({
                ...e,
                password:
                  "Password must be greater than or equal to 6 characters.",
              }));
            } else {
              setErrors((e) => ({ ...e, password: "" }));
            }
            setPassword(v);
          }}
        />
      </div>
      <label className="text-red-500 text-sm">{errors.password}</label>

      <label className="text-zinc-800 text-base font-leagueSpartan-400">
        Confirm Password
      </label>
      <div
        className="flex items-center border rounded-md p-1"
        style={{ width: "100%", height: "45px" }}
      >
        <input
          type="password"
          placeholder="Enter your confirm password"
          className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300"
          value={confirmpassword}
          onChange={(e) => {
            const v = e.target.value;
            if (v !== password) {
              setErrors((e) => ({
                ...e,
                confirmpassword: "Password and confirm password doesn't match.",
              }));
            } else {
              setErrors((e) => ({ ...e, confirmpassword: "" }));
            }
            setConfirmPassword(v);
          }}
        />
      </div>
      <label className="text-red-500 text-sm">{errors.confirmpassword}</label>

      <div className="flex justify-center mt-2 ">
        <button
          className="h-[45px]  bg-orange-500 w-1/5 rounded-lg text-white font-leagueSpartan-400 text-[15px]"
          onClick={register}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default CreateAccountPage;
