import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import images from "../../constants/images";
import { DeepPartial, Product } from "../../constants/types";
import FileUpload from "../common/FileUpload";

type Props = {
  isModalVisible: () => void;
};

export const ImportModal = ({ isModalVisible }: Props) => {
  const [fileUploadData, setFileUploadData] = useState({});
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [file, setFile] = useState<File | null | undefined>();

  const handleFileUpload = (file: File | null | undefined) => {
    if (file!.size / 1024 > 5120) {
      toast.error("File too large");
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // @ts-ignore
        const data = e.target.result;
        const csv = require("csvtojson");
        csv()
          .fromString(data)
          .then(async (jsonObj: any) => {
            const json = jsonObj as {
              Name: string;
              Description: string;
              image_1: string;
              image_2: string;
              image_3: string;
              image_4: string;
              image_5: string;
              image_6: string;
              SKU: string;
              "Quantity Limitation": string;
              "Start Visibility (mm/dd/yyyy)": string;
              "End Visibility (mm/dd/yyyy)": string;
              Brand: string;
              "Has Self Pickup Option?": string;
              "Has Delivery Option?": string;
              "Self Pickup Address": string;
              "List Price": string;
              "Retail Price": string;
              "Cost Price": string;
              "Delivery Fee": string;
              "Sell Platform": string;
              "Sell Platform URL"?: string | null;
              "Product Type": string;
              isDeleted: string;
              "Product Categories": string;
              Collection: string;
              tagNames: string;
            }[];
            const data: DeepPartial<Product>[] = [];
            const promises: Promise<DeepPartial<Product>>[] = json.map(
              async (i): Promise<DeepPartial<Product>> => {
                const _i: DeepPartial<Product> = {
                  name: i.Name?.trim(),
                  description: i.Description?.trim(),
                  image_1:
                    ((i.image_1 ?? "").match(/https?:\/\/[^\s]+/) ?? [])[0] ||
                    "",
                  image_2:
                    ((i.image_2 ?? "").match(/https?:\/\/[^\s]+/) ?? [])[0] ||
                    "",
                  image_3:
                    ((i.image_3 ?? "").match(/https?:\/\/[^\s]+/) ?? [])[0] ||
                    "",
                  image_4:
                    ((i.image_4 ?? "").match(/https?:\/\/[^\s]+/) ?? [])[0] ||
                    "",
                  image_5:
                    ((i.image_5 ?? "").match(/https?:\/\/[^\s]+/) ?? [])[0] ||
                    "",
                  image_6:
                    ((i.image_6 ?? "").match(/https?:\/\/[^\s]+/) ?? [])[0] ||
                    "",

                  sku: i.SKU?.trim(),
                  quantityLimitation: +(i["Quantity Limitation"] ?? ""),
                  startVisibility: new Date(
                    i["Start Visibility (mm/dd/yyyy)"] === ""
                      ? "01/01/2001"
                      : i["Start Visibility (mm/dd/yyyy)"].toString(),
                  ),
                  endVisibility:
                    i["End Visibility (mm/dd/yyyy)"] === ""
                      ? null
                      : new Date(i["End Visibility (mm/dd/yyyy)"].toString()),
                  brand: i.Brand?.trim(),
                  hasSelfPickupOption:
                    i["Has Self Pickup Option?"].toLowerCase().trim() ===
                    "true",
                  hasDeliveryOption:
                    i["Has Delivery Option?"].toLowerCase()?.trim() === "true",
                  selfPickupAddress: i["Self Pickup Address"]?.trim(),
                  listPrice: +(i["List Price"] ?? "").replace(/[^\d.-]/g, ""),
                  retailPrice: +(i["Retail Price"] ?? "").replace(
                    /[^\d.-]/g,
                    "",
                  ),
                  costPrice: +(i["Cost Price"] ?? "").replace(/[^\d.-]/g, ""),
                  deliveryFee: +(i["Delivery Fee"] ?? "").replace(
                    /[^\d.-]/g,
                    "",
                  ),
                  sellPlatform: i["Sell Platform"]?.trim(),
                  sellPlatformUrl: i["Sell Platform URL"]?.trim(),
                  productType: i["Product Type"]?.trim(),
                  isDeleted: i.isDeleted?.toLowerCase().trim() === "true",
                  collectionNames:
                    i.Collection?.split(",")
                      .map((collection) => collection.trim())
                      .filter((collection) => collection !== "") || [],
                  categoryNames:
                    i["Product Categories"]
                      ?.split(",")
                      .map((category) => category.trim())
                      .filter((category) => category !== "") || [],
                  tagNames:
                    i.tagNames
                      ?.split(",")
                      .map((tag) => tag.trim())
                      .filter((tag) => tag !== "") || [],
                };
                if (
                  (_i.name === "" || _i.name === undefined) &&
                  (_i.description === "" || _i.description === undefined) &&
                  (_i.brand === "" || _i.brand === undefined)
                ) {
                  return Promise.resolve({});
                }
                return Promise.resolve(_i);
              },
            );
            const resolvedPromises = await Promise.all(promises);
            data.push(...resolvedPromises);
            if (data.length > 100) {
              toast.warn("Max 100 products allowed!");
              return;
            }
            setFileUploadData(data);
            axios
              .post(`${process.env.REACT_APP_ADMIN_URL}/products/uploadFile`, {
                products: data,
              })
              .then((res) => {
                toast.success(
                  `${file.name} - Products were successfully uploaded!`,
                );
                setErrorMessages((errors) => errors.concat(res.data.data));
              })
              .catch((error) => {
                setErrorMessages((prevErrorMessages) => [
                  ...prevErrorMessages,
                  `${file.name} - ${error.response.data.message}`,
                ]);
              });
          });
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="bg-white p-8">
      <div className="grid justify-items-end">
        <button onClick={isModalVisible}>
          <img src={images.cross} />
        </button>
      </div>
      <div>
        <div className=" flex gap-2 mt-4 font-leagueSpartan-400 text-[16px] text-neutrals-800">
          <img src={images.drag} alt="" className="cursor-pointer" />
          <div className="text-xs">
            <li>Max upload of 100 products in csv</li>
            <li>
              Please ensure that the Catgory, Collection and Tags exists in
              system before tagging it to the product in csv. Non-existing tags
              will be skipped
            </li>
            <li>
              Use a (,) to separate your Category, Collection and Tags. E.g.,
              [Appreciate, Farewell]
            </li>
            <li>
              Please ensure new products have a <mark>name</mark> and an image
              link address in <mark>image_1</mark> column, else the product will
              not be saved
            </li>
            <li>
              Product will be updated if there is an existing product with the
              same <mark>name and brand</mark>
            </li>
          </div>
        </div>
        <FileUpload handleFileUpload={setFile} />
        <div>{file !== null && file !== undefined ? file.name : null}</div>
      </div>
      <button
        className={`text-white mt-4 rounded-lg px-4 flex py-2 ${
          file ? "bg-orange-500" : "bg-neutral-200"
        }`}
        onClick={() => {
          if (file) {
            handleFileUpload(file);
            setFile(undefined);
          }
        }}
      >
        Upload
      </button>

      <div className="text-red-500">
        {Array.isArray(errorMessages) && errorMessages.length > 0 && (
          <div>
            {errorMessages.map((message, index) => {
              return <div key={index}>{message}</div>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};
