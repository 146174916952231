import { HoverPopUp } from "../common/HoverPopUp";
import { OrderStatusOptions } from "./OrdersFilterWindow";

type TOrderRow = {
  rowInfo: any;
  openCustomerDetail: () => void;
  openOrderStatus: () => void;
  openProductDetail: () => void;
  openCampaignDetail: () => void;
  openRemarks: () => void;
};

export const OrderRow = ({
  rowInfo,
  openCustomerDetail,
  openOrderStatus,
  openProductDetail,
  openCampaignDetail,
  openRemarks,
}: TOrderRow) => {
  const {
    id,
    createdAt,
    campaign,
    firstName,
    lastName,
    shippingMethod,
    status,
    note,
  } = rowInfo;

  return (
    <>
      <td className="font-bold">{id}</td>
      <td className="font-bold">
        {createdAt &&
          new Intl.DateTimeFormat("en-SG", {
            dateStyle: "medium",
            timeStyle: "short",
          }).format(new Date(createdAt))}
      </td>
      <td className="font-bold" onClick={openCampaignDetail}>
        {campaign.name}
      </td>
      <td className="px-4 py-2">
        {rowInfo?.orders &&
          rowInfo?.orders.map((order: any) => (
            <div key={order.product?.id} className="w-max">
              {order.product?.brand}
              <span className="ms-3 bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border-blue-400">
                x{order.quantity}
              </span>
            </div>
          ))}
      </td>
      <td className="px-4 py-2" onClick={openProductDetail}>
        {rowInfo?.orders &&
          rowInfo?.orders.map((order: any) => (
            <div key={order.product?.id} className="w-max">
              {order.product?.name}
              <span className="ms-3 bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border-blue-400">
                x{order.quantity}
              </span>
            </div>
          ))}
      </td>
      <td
        className="font-bold"
        onClick={openCustomerDetail}
      >{`${firstName} ${lastName}`}</td>
      <td>
        {shippingMethod === "PHYSICAL_PICKUP"
          ? "Pick up"
          : shippingMethod === "PHYSICAL_DELIVER"
          ? "Delivery"
          : "NA"}
      </td>
      <td onClick={openOrderStatus}>
        <div
          className={`capitalize rounded-lg text-sm text-center align-bottom px-2 pt-2 mx-4 w-5/6 h-8
          ${
            status?.name === OrderStatusOptions.Pending
              ? "bg-orange-50 text-orange-500"
              : status?.name === OrderStatusOptions.Procured
              ? "bg-blue-100 text-blue-500"
              : status?.name === OrderStatusOptions.Completed
              ? "bg-green-100 text-green-500"
              : ""
          }`}
        >
          {status?.name}
        </div>
      </td>
      <HoverPopUp
        info={note}
        onClick={openRemarks}
        truncatedInfo={
          note ? note.slice(0, 10) : <span className="text-slate-500">NA</span>
        }
      />
    </>
  );
};
