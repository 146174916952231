import { useContext, useState } from "react";
import { ReactComponent as Cross } from "../../constants/images/icons/Cross.svg";
import { OrderContext } from "../OrderPage/OrderContextProvider";

type PropType = {
  action: () => void;
};

enum DateRange {
  From = "from",
  To = "to",
}

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-based
  const day = date.getDate();

  // Pad month and day with zero if needed
  const monthStr = month < 10 ? "0" + month : month;
  const dayStr = day < 10 ? "0" + day : day;

  return `${year}-${monthStr}-${dayStr}`;
};

export const DashboardDatePickerModel = ({ action }: PropType) => {
  const { statsDateRange, setStatsDateRange } = useContext(OrderContext);
  const [error, setError] = useState("");
  const [from, setFrom] = useState(statsDateRange.from);
  const [to, setTo] = useState(statsDateRange.to);

  const handleDateChange = (value: string, type: string) => {
    const date = new Date(value);
    if (type === DateRange.From) {
      setFrom(date);
    } else {
      setTo(date);
    }
  };

  const handleFixedDateSelect = (days: number) => {
    const newFrom = new Date(
      statsDateRange.to.getTime() - (days - 1) * 24 * 60 * 60 * 1000,
    );
    setFrom(newFrom);
  };

  const handleSubmit = () => {
    if (statsDateRange.from.getTime() - statsDateRange.to.getTime() > 0) {
      setError("From date should be before To date");
    } else {
      setError("");
      setStatsDateRange({ from, to });
      action();
    }
  };

  return (
    <div className="inset-0 fixed backdrop-blur-sm bg-black bg-opacity-80 flex justify-center items-center z-40">
      <div>
        <div className="w-[596px] min-h-[380px] shadow flex-col rounded-xl bg-white">
          <div className="flex justify-end mx-3  ">
            <div className="w-6 h-6 " />{" "}
            <button className="text-black text-xl mt-2" onClick={action}>
              <Cross />
            </button>
          </div>
          <div className="p-20">
            <div className="flex flex-col items-center">
              <div className="flex gap-2">
                <button
                  className="p-2 px-3 font-leagueSpartan-400 text-orange border border-orange rounded-lg hover:bg-lightOrange"
                  onClick={() => handleFixedDateSelect(7)}
                >
                  Last 7 days
                </button>
                <button
                  className="p-2 px-3 font-leagueSpartan-400 text-orange border border-orange rounded-lg hover:bg-lightOrange"
                  onClick={() => handleFixedDateSelect(15)}
                >
                  Last 15 days
                </button>
                <button
                  className="p-2 px-3 font-leagueSpartan-400 text-orange border border-orange rounded-lg hover:bg-lightOrange"
                  onClick={() => handleFixedDateSelect(30)}
                >
                  Last 30 days
                </button>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col gap-2 p-4">
                  <label className="mx-1 font-leagueSpartan-400">From</label>
                  <input
                    type="date"
                    className="w-[180px] border border-neutral-300 font-leagueSpartan-400 rounded-lg"
                    value={formatDate(from)}
                    onChange={(e) => {
                      handleDateChange(e.target.value, DateRange.From);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-2 p-4">
                  <label className="mx-1 font-leagueSpartan-400">To</label>
                  <input
                    type="date"
                    className="w-[180px] border border-neutral-300 font-leagueSpartan-400 rounded-lg"
                    value={formatDate(to)}
                    onChange={(e) => {
                      handleDateChange(e.target.value, DateRange.To);
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1">
                {error && <p className="text-red-500">{error}</p>}
                <div className="flex justify-center">
                  <button
                    className="mt-4 p-2 px-3 rounded-lg bg-orange-500 font-leagueSpartan-400 text-sm text-white block"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
