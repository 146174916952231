import { useContext } from "react";
import { ProductContext } from "../../context/Product.context";
import { Variant } from "./Variant";

export const VariantsField = () => {
  const { product, addVariant } = useContext(ProductContext);

  return (
    <div className="rounded-lg bg-white shadow-md">
      <div className="container p-6">
        <div className="text-[20px]">Variants</div>
        <div>
          {product.variations &&
            product.variations.map((_, index: number) => (
              <Variant index={index} />
            ))}
        </div>
        <div className="pt-4 pb-2 text-orange-600">
          <button className="inline-flex items-center" onClick={addVariant}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "4px" }}
            >
              <path
                d="M12 5.5V19.5M5 12.5H19"
                stroke="#ea580c"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ps-2 pt-1">Add Variant</span>
          </button>
        </div>
      </div>
    </div>
  );
};
