import React, { useEffect, useRef, useState } from "react";

type PopUpWindowProps = {
  button: JSX.Element;
  children: JSX.Element;
  className?: string;
};

export const PopUpWindow = ({
  button,
  children,
  className,
}: PopUpWindowProps) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const popupRef: any = useRef();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {button &&
        React.cloneElement(button, {
          onClick: () => setPopupOpen(!isPopupOpen),
        })}

      {isPopupOpen && (
        <div
          ref={popupRef}
          className={`absolute z-40 mt-2 bg-white border border-gray-300 shadow-md rounded-md ${className}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};
