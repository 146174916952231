import { useNavigate } from "react-router-dom";

export const TabView = ({
  tabs,
  currentTab,
  children,
}: {
  tabs: string[];
  currentTab: string;
  children: string | JSX.Element | JSX.Element[];
}) => {
  enum route {
    "Product" = "products",
    "Collection" = "collections",
    "Category" = "categories",
    "Voucher" = "voucher",
    "Donation" = "donation",
    "Themes" = "themes"
  }
  const navigate = useNavigate();

  const navigateToPage = (tab: string) => {
    navigate(`/${route[tab as keyof typeof route]}`);
  };
  return (
    <>
      <div className="flex space-x-2">
        {tabs.map((tab) => (
          <div key={tab}>
            <button
              className={`px-6 py-2 border-b-2 text-sm font-semibold ${
                currentTab === tab
                  ? "text-orange-500 border-b-2 border-b-orange-500"
                  : "text-slate-400"
              }`}
              onClick={() => navigateToPage(tab)}
            >
              <div className="align-top text-base">{tab}</div>
            </button>
          </div>
        ))}
      </div>
      {children}
    </>
  );
};
