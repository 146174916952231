import { Link, useNavigate } from "react-router-dom";
import { Corp } from "../../constants/types"; 

export const CorpsRow = ({
  corp,
  pageNumber,
}: {
  corp: Corp;
  pageNumber?: number;
}) => {
  const navigate = useNavigate();

  const handleEditCorp = () => {
    navigate(`/corps/${corp.id}`, { state: { fromPage: pageNumber } });
  };

  return (
    <>
      <td className="p-2">{corp.id}</td>
      <td className="p-2">
        <Link
          draggable="false"
          to={`/corps/${corp.id}`}
          state={{ fromPage: pageNumber }}
        >
          <div className="flex">
            <img
              draggable="false"
              src={corp.logo}
              alt=""
              className="max-w-24 object-contain"
            />
            <div className="max-w-96">
              <div className="p-2 flex items-center text-left">
                <span className="hover:underline hover:underline-offset-2">
                  {corp.organisationName}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </td>
      <td className="p-2">{corp.email}</td>
      <td className="p-2">{corp.phoneNumber}</td>
      <td className="p-2">{`${corp.firstName} ${corp.middleName} ${corp.lastName}`}</td>
      <td className="p-2">{corp.referralId}</td>
      <td className="p-2">{corp.govId}</td>
      <td className="p-2">{corp.taxId}</td>
      <td className="p-2">{corp.taxNumber}</td>
     
    </>
  );
};
