import { useEffect, useState } from "react";
import { TabView } from "../TabView";
import { CategoryTab } from "../dashboard/CategoryTab";
import { CollectionTab } from "../dashboard/CollectionTab";
import { DonationTab } from "../dashboard/DonationTab";
import { ProductTab } from "../dashboard/ProductTab";
import { Vouchers } from "../dashboard/Voucher";
import {ThemesTab} from "../dashboard/ThemesTab";

export enum ProductPageTabs {
  Product = "Product",
  Collection = "Collection",
  Category = "Category",
  Voucher = "Voucher",
  Donation = "Donation",
  Themes = "Themes",
}

export const ProductPage: React.FC<{ redirectTab: ProductPageTabs }> = ({
  redirectTab,
}) => {
  const [tab, setTab] = useState(redirectTab);
  const [createNewAction, setCreateNewAction] = useState<
    undefined | (() => void)
  >(undefined);

  useEffect(() => {
    setTab(redirectTab);
  }, [redirectTab]);

  return (
    <div className="bg-slate-50 min-h-screen">
      <div className="p-6 grid grid-flow-row gap-6 ">
        <div className="flex justify-between align-middle">
          <div className="flex justify-between gap-2">
            <h1 className="text-3xl">{tab} Management</h1>
          </div>
          {createNewAction && (
            <div className="grid grid-flow-col gap-2 my-1 text-sm">
              <button
                className="bg-orange-500 text-white rounded-lg px-4 flex py-3"
                onClick={createNewAction}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span className="px-2">New {tab}</span>
              </button>
            </div>
          )}
        </div>
        <TabView tabs={Object.values(ProductPageTabs)} currentTab={tab}>
          <div className="pe-3 grid grid-flow-row gap-6">
            <div>
              {tab === ProductPageTabs.Product ? (
                <ProductTab
                  action={(createNew) => setCreateNewAction(createNew)}
                />
              ) : tab === ProductPageTabs.Collection ? (
                <CollectionTab
                  action={(createNew) => setCreateNewAction(createNew)}
                />
              ) : tab === ProductPageTabs.Category ? (
                <CategoryTab
                  action={(createNew) => setCreateNewAction(createNew)}
                />
              ) : tab === ProductPageTabs.Voucher ? (
                <Vouchers />
              ) : tab === ProductPageTabs.Donation ? (
                <DonationTab
                  action={(createNew) => setCreateNewAction(createNew)}
                />
              ) : (
                <ThemesTab
                  action={(createNew) => setCreateNewAction(createNew)}
                />
              )}
            </div>
          </div>
        </TabView>
      </div>
    </div>
  );
};
