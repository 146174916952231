import { useContext, useState } from "react";
import { Spinner } from "../component/Spinner/Spinner";
import { UserContext } from "../context/UserContextProvider";

export const LoginPage = () => {
  const { login, setUser } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [error, setError] = useState("");

  const handleLogin = async () => {
    setIsLoggingIn(true);
    await login(email, password, setUser)
      .catch((err) => setError(err.response.data.message))
      .finally(() => setIsLoggingIn(false));
  };

  return (
    <>
      <section className="bg-orange-300">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex flex-col items-center mb-6 text-2xl font-semibold text-gray-900">
            <img
              className="w-32 h-32 mr-2"
              src="https://app.smilie.io/images/logo.svg"
              alt="logo"
            />
            Operations Dashboard
          </div>
          <div className="w-full bg-purple-500 rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center">
                Sign in to your account
              </h1>
              <div className="text-red-500 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                {error}
              </div>
              <div className="space-y-4 md:space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-orange-200"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="name@company.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-orange-200"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button
                  onClick={handleLogin}
                  className="w-full text-white bg-white-600 hover:bg-oranges-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? <Spinner /> : <>Sign in</>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
