import { useContext } from "react";
import images from "../../constants/images";
import { ProductContext } from "../../context/Product.context";

type PropType = {
  index: number;
};

const defaultDelivery = {
  name: "",
  deliveryFee: 0,
  deliveryDurationEarliest: 0,
  deliveryDurationLatest: 0,
};

export const DeliveryRow = ({ index }: PropType) => {
  const { product, editDeliveryOptions, removeDeliveryOptions } =
    useContext(ProductContext);

  return (
    <div className="my-1">
      <div className="flex justify-between items-center">
        <label>Name of Delivery Type</label>
        <input
          type="text"
          className="border-[1px] border-[neutral-400] w-1/2 rounded-lg px-2 pt-1 my-4 focus:border-2 focus:border-orange-600 outline-none"
          placeholder=""
          value={product.delivery?.[index]?.name || ""}
          onChange={(e) => {
            const currentDelivery = product.delivery?.[index];
            editDeliveryOptions(index, {
              ...defaultDelivery,
              ...currentDelivery,
              name: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex justify-between items-center">
        <label>Delivery Fee</label>
        <input
          type="number"
          value={product.delivery?.[index]?.deliveryFee || ""}
          className="border-[1px] border-[neutral-400] w-1/2 rounded-lg px-2 pt-1 my-4 focus:border-2 focus:border-orange-600 outline-none"
          placeholder="$0.00"
          min="0"
          onChange={(e) => {
            const currentDelivery = product.delivery?.[index];
            editDeliveryOptions(index, {
              ...defaultDelivery,
              ...currentDelivery,
              deliveryFee: Number(e.target.value),
            });
          }}
        />
      </div>
      <div>
        <span>Dates</span>
        <div className="flex justify-between my-1">
          <div className="w-5/12 ">
            <small className="ps-2">Minimum</small>
            <input
              type="number"
              name="minDaysNeeded"
              className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 block w-full focus:border-2 focus:border-orange-600 outline-none"
              placeholder="0"
              value={product.delivery?.[index]?.deliveryDurationEarliest || ""}
              min="0"
              onChange={(e) => {
                const currentDelivery = product.delivery?.[index];
                editDeliveryOptions(index, {
                  ...defaultDelivery,
                  ...currentDelivery,
                  deliveryDurationEarliest: Number(e.target.value),
                });
              }}
            />
          </div>

          <div className="flex items-center">
            <img src={images.minus} alt="" />
          </div>

          <div className="w-5/12 ">
            <small className="ps-2">Maximum</small>
            <input
              type="number"
              name="maxDaysNeeded"
              className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600} block w-full focus:border-2 focus:border-orange-600 outline-none"
              placeholder="0"
              value={product.delivery?.[index]?.deliveryDurationLatest || ""}
              min="0"
              onChange={(e) => {
                const currentDelivery = product.delivery?.[index];
                editDeliveryOptions(index, {
                  ...defaultDelivery,
                  ...currentDelivery,
                  deliveryDurationLatest: Number(e.target.value),
                });
              }}
              onBlur={(e) => {
                const currentDelivery = product.delivery?.[index];
                if (
                  !currentDelivery?.deliveryDurationLatest ||
                  !currentDelivery?.deliveryDurationEarliest
                )
                  return;
                if (
                  currentDelivery.deliveryDurationLatest <
                  currentDelivery.deliveryDurationEarliest
                ) {
                  editDeliveryOptions(index, {
                    ...defaultDelivery,
                    ...currentDelivery,
                    deliveryDurationLatest:
                      currentDelivery?.deliveryDurationEarliest,
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="flex p-2 justify-end">
          <button
            className=" ps-4"
            onClick={() => removeDeliveryOptions(index)}
          >
            <img src={images.bin} alt="" />
          </button>
        </div>
      </div>
      <div className={`border-b-2 border-slate-400 mt-4`}></div>
    </div>
  );
};
