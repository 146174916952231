import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Corp } from "../../constants/types";
import { Checkbox } from "../common/Checkbox";
import { SearchBar } from "../common/SearchBar";

export const SelectCorp = ({
  selectedItems,
  itemType,
}: {
  selectedItems: number[];
  itemType: "collection" | "theme"; //refactor to use generics
}) => {
  const url = process.env.REACT_APP_ADMIN_URL;
  const [corps, setCorps] = useState<Corp[]>([]);
  const [selectedCorps, setSelectedCorps] = useState<number[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const LIMIT = 10;

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${url}/corp`, {
        params: {
          limit: LIMIT,
          pageNumber,
          searchInput,
        },
      });
      if (pageNumber === 1) {
        setCorps(response.data.data.results);
      } else {
        setCorps((prevData) => [...prevData, ...response.data.data.results]);
      }
      setTotalPages(response.data.data.totalPages);
    } catch (e) {
      toast.error("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  }, [pageNumber, searchInput]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNumber((prev) => prev + 1);
      }
    }),
  );

  useEffect(() => {
    if (pageNumber <= totalPages || pageNumber === 1) {
      getData();
    }
  }, [pageNumber, getData, totalPages]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const handleSelect = (id: number) => {
    const item = selectedCorps.find((item) => item === id);
    if (item) {
      setSelectedCorps((prev) => prev.filter((item) => item !== id));
    } else {
      setSelectedCorps((prev) => [...prev, id]);
    }
  };

  const handleAdd = async () => {
    const urls: Promise<void>[] = [];
    selectedCorps.forEach((corpId) => {
      selectedItems.forEach((itemId) => {
        const { endpoint, payload } =
          itemType === "collection"
            ? {
                endpoint: "corpCollection",
                payload: { corpId, collectionId: itemId },
              }
            : { endpoint: "corpThemes", payload: { corpId, themeId: itemId } };
        urls.push(axios.post(`${url}/${endpoint}`, payload));
      });
    });
    await Promise.allSettled(urls).then((results) => {
      const anyRejected = results.some(
        (result) => result.status === "rejected",
      );
      if (anyRejected) {
        toast(
          `An error occurred while creating Corps and ${itemType}s relationships.`,
          {
            bodyClassName: "text-red-500",
            progressClassName: "bg-red-500",
          },
        );
      } else {
        console.log("hihere");

        toast(
          `Corps and ${itemType}s relationships were successfully created!`,
          {
            bodyClassName: "text-green-500",
            progressClassName: "bg-green-500",
          },
        );
      }
    });
  };

  const handleSearch = (value: string) => {
    setPageNumber(1);
    setSearchInput(value);
  };

  return (
    <div className="bg-white w-96 p-2 absolute rounded-lg left-28 top-28 z-50">
      <div className="p-3">
        <SearchBar onSearch={(value: string) => handleSearch(value)} />
      </div>
      <div className="mt-3 flex justify-end">
        <button
          className="rounded-lg bg-orange-500 text-white px-2 py-1 h-10 w-20"
          onClick={handleAdd}
        >
          Add
        </button>
      </div>
      <div className="h-56 overflow-scroll scrollbar-hide pe-2">
        {corps.map((item, i) => (
          <div
            className="flex mt-2"
            key={item.id}
            ref={i === corps.length - 1 ? setLastElement : null}
          >
            <Checkbox
              onClick={() => {
                handleSelect(item.id);
              }}
              checked={selectedCorps.includes(item.id)}
            />
            <span className="ps-2">{item.organisationName}</span>
          </div>
        ))}
        {isLoading && <div>Loading...</div>}
      </div>
      <ToastContainer />
    </div>
  );
};
