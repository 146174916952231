import { MouseEventHandler } from "react";

type TCheckbox = {
  checked: boolean;
  className?: string;
  readOnly?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  name?: string;
};
export const Checkbox = ({
  checked,
  className,
  readOnly = true,
  onClick,
  name,
}: TCheckbox) => {
  return (
    <div
      className={`flex rounded-xl cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div className="my-auto px-2">
        <input
          className="text-2xl text-orange-500 bg-gray-100 border-gray-300 focus:ring-orange-500 focus:ring-1 w-6 h-6 rounded"
          type="checkbox"
          name={name}
          checked={checked}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};
