import { useContext, useState } from "react";
import { ReactComponent as Calendar } from "../../constants/images/icons/Calendar.svg";
import { formatDate } from "../../utils/api";
import { OrderContext } from "../OrderPage/OrderContextProvider";
import { DashboardDatePickerModel } from "./DashboardDatePickerModel";

export const DashboardDateSelect = () => {
  const { statsDateRange } = useContext(OrderContext);
  const [isOpen, setIsOpen] = useState(false);

  const from = statsDateRange.from;
  const to = statsDateRange.to;

  const daysDiff = Math.ceil(
    (statsDateRange.to.getTime() - statsDateRange.from.getTime()) / 86400000 +
      1,
  );

  const handleDateSelect = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className="flex items-center justify-center border border-neutrals-400 bg-white rounded-lg p-3 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Calendar />
        <div className="ml-3 font-leagueSpartan-300 text-[16px]">
          <span className="font-extrabold text-xl">
            Last {daysDiff} {daysDiff < 2 ? "Day" : "Days"}
          </span>
          : {formatDate(from.toISOString())} - {formatDate(to.toISOString())}
        </div>
      </div>
      {isOpen && <DashboardDatePickerModel action={handleDateSelect} />}
    </>
  );
};
