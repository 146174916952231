import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as Copy } from "../../constants/images/icons/Copy.svg";
import { ReactComponent as TripleDots } from "../../constants/images/icons/TripleDots.svg";
import { formatDate } from "../../utils/api";
import { PopUpWindow } from "../common/PopUpWindow";

export const GiftLinksRow = (rowInfo: any) => {
  const {
    id,
    orderRecipientDetails,
    consolidatedOrder,
    url_id,
    createdAt,
    isForTesting,
    giftClaimed,
    thankYouNote,
    triggerTimeStamp,
    linkClicked,
    isSingle,
    campaign,
    order,
    isDeactivated,
  } = rowInfo.component;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const corpUrl = process.env.REACT_APP_CORP_URL;
  const adminUrl = process.env.REACT_APP_ADMIN_URL;
  const frontendUrl = process.env.REACT_APP_REACT_FE_URL;
  const giftingUrl = process.env.REACT_APP_GIFTING_URL;

  const toggleLink = () => {
    axios
      .put(`${adminUrl}/orders/gift-links/${id}`, {
        status: !isDeactivated,
      })
      .then((resp) => {
        toast.success(
          `Link ${isDeactivated ? "activated" : "deactivated"} successfully`,
        );
      })
      .catch((err) => {
        toast.error("Failed to update link status");
      });
  };

  const sendReminderEmail = () => {
    const payload = {
      isSingle: campaign?.isSingle,
      recipientData: {
        id,
        email: orderRecipientDetails.email,
        link: frontendUrl + "/g/" + url_id,
        name: orderRecipientDetails.firstName,
        status: giftClaimed,
        thankYouNote,
        account: "?",
        dateSent: triggerTimeStamp,
      },
      senderName: "",
    };

    axios
      .post(`${giftingUrl}/api/gifting/recipient/email`, payload)
      .then(() => {
        toast.success("Email sent successfully");
      })
      .catch(() => {
        toast.error("Email failed to send");
      });
  };

  const sendSelectGiftEmail = () => {
    const payload = {
      userId: id,
      to: orderRecipientDetails.email,
      from: "Smilie",
      receiverName: orderRecipientDetails.firstName,
      senderName: "somebody",
    };

    axios
      .post(`${corpUrl}/api/corp/campaign/pick-gift-email`, payload)
      .then(() => {
        toast.success("Email sent successfully");
      })
      .catch(() => {
        toast.error("Email failed to send");
      });
  };

  return (
    <>
      <td>{campaign?.id}</td>
      <td>{order?.id}</td>
      <td className="text-start">
        <p className="text-slate-400 capitalize">{campaign?.name}</p>
        <div className="flex">
          <span className="text-lg">{`${frontendUrl}/g/${url_id}`}</span>
          <Copy
            className="w-4 h-4 cursor-pointer fill-black ms-2"
            onClick={() => copyToClipboard(`${frontendUrl}/g/${url_id}`)}
          />
        </div>
      </td>
      <td>{formatDate(createdAt)}</td>
      <td className="text-start capitalize">
        <p>{`${orderRecipientDetails?.firstName} ${orderRecipientDetails?.lastName}`}</p>
        <p className="text-slate-400 font-sm">{orderRecipientDetails?.email}</p>
      </td>
      <td>
        {isDeactivated ? (
          <span className="bg-red-200 text-red-900 rounded-lg px-2 py-2">
            Deactivated
          </span>
        ) : linkClicked ? (
          giftClaimed ? (
            <span className="bg-green-200 text-green-900 rounded-lg px-2 py-2">
              Gift Claimed
            </span>
          ) : (
            <span className="bg-orange-200 text-yellow-900 rounded-lg px-2 py-2">
              Opened
            </span>
          )
        ) : (
          <span className="bg-gray-200 text-gray-900 rounded-lg px-2 py-2">
            Not Clicked
          </span>
        )}
      </td>

      <td className="relative">
        <PopUpWindow
          className={"right-0"}
          button={
            <button>
              <TripleDots className="fill-black w-6 h-6 pt-1" />
            </button>
          }
          children={
            <div className="bg-white rounded-md shadow-md w-48">
              {!giftClaimed && (
                <>
                  <button
                    className="w-full ps-4 py-2 text-left hover:bg-gray-100"
                    onClick={sendReminderEmail}
                  >
                    Send Select Gift Email
                  </button>

                  <button
                    className="w-full ps-4 py-2 text-left hover:bg-gray-100"
                    onClick={toggleLink}
                  >
                    {isDeactivated ? "Activate" : "Deactivate"} Link
                  </button>
                </>
              )}
            </div>
          }
        />
      </td>
    </>
  );
};
