export const capitalize = (str: string): string => {
  const words = str.split(" ");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(" ");
};

export const formatDate = (timeString: string): string => {
  const date = new Date(timeString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options);
};

type SortDirection = "asc" | "desc";

export const sortBy = <T>(
  array: T[],
  property: keyof T,
  direction: SortDirection = "asc",
): T[] => {
  const compare = (a: T, b: T): number => {
    const aValue = a[property];
    const bValue = b[property];

    if (aValue < bValue) {
      return direction === "asc" ? -1 : 1;
    } else if (aValue > bValue) {
      return direction === "asc" ? 1 : -1;
    }

    return 0;
  };
  return [...array].sort(compare);
};

export const pushID = (element: number, selectedIDs: number[]) => {
  let index = selectedIDs.indexOf(element);
  if (index > -1) {
    return selectedIDs.filter((item) => item !== element);
  } else {
    return [...selectedIDs, element];
  }
};

export const matchPhrase = (itemName: string, phrase: string) => {
  const regex = new RegExp(phrase, "i"); // 'i' flag for case-insensitive matching
  return regex.test(itemName);
};
