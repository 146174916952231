import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import images from "../../constants/images";
import {
  Product,
  ProductCategoryTag,
  ProductCollectionTag,
  ProductTag,
} from "../../constants/types";
import {
  BreadcrumbContext,
  ProductTableFilterAttributes,
} from "../../context/Breadcrumb.context";
import { Modal } from "../common/Modal";
import { SearchBar } from "../common/SearchBar";
import { Table, TableHeader } from "../common/Table";
import { DateAddedFilter } from "../common/TableFilters/DateAddedFilter";
import { FilterProp } from "../common/TableFilters/FilterProp";
import {
  PriceFilter,
  PriceRangeFilter,
} from "../common/TableFilters/PriceRangeFilter";
import { VisibilityFilter } from "../common/TableFilters/VisibilityFilter";
import { ImportModal } from "../product/ImportModal";
import { ProductRow } from "./ProductRow";
import { SelectPanel } from "./SelectPanel";

const headers: TableHeader[] = [
  { heading: "BRAND", key: "Product.brand", isSortable: true },
  { heading: "PRODUCT", key: "Product.name", isSortable: true },
  { heading: "SMILIE PRICE", key: "Product.listPrice", isSortable: true },
  { heading: "VISIBILITY", key: "VISIBILITY", isSortable: false },
  { heading: "DATE ADDED", key: "Product.createdAt", isSortable: true },
  {
    heading: "CATEGORY",
    key: "productCategory.name",
    isSortable: true,
  },
];

type TProductTab = {
  action: (createNew: () => void) => void;
};

export const ProductTab = ({
  action,
}: {
  action: (createNew: () => void) => void;
}) => {
  const navigate = useNavigate();
  const {
    productTable,
    setProductTable,
    productTableFilters,
    setProductTableFilters,
  } = useContext(BreadcrumbContext);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectProductIds, setSelectProductIds] = useState<number[]>([]);

  const [isFetchingProducts, setIsFetchingProducts] = useState(true);
  const [showProductTableFilter, setShowProductTableFilter] =
    useState<boolean>(false);
  const [maxProductListPrice, setMaxProductListPrice] = useState<number>(150);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [tableFilter, setTableFilter] =
    useState<ProductTableFilterAttributes>(productTableFilters);

  const [openImportModal, setOpenImportModal] = useState(false);

  const multipleSelectOnClick = () => {
    if (selectProductIds.length > 0) {
      setSelectProductIds([]);
      return;
    }

    setSelectProductIds((prev) => [
      ...prev,
      // TODO: Make api call to fetch all ids without pagination and add here
      ...products.map((product) => product.id),
    ]);
  };

  const fetchProducts = () => {
    const controller = new AbortController();

    setIsFetchingProducts(true);
    const tableFilterPayload = {
      ...productTableFilters,
      dateAdded: {
        startDate: productTableFilters.dateAdded.startDate?.toISOString(),
        endDate: productTableFilters.dateAdded.endDate?.toISOString(),
      },
    };

    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/products`, {
        params: {
          limit: productTable.limit,
          sort: productTable.sort,
          sortOrder: productTable.sortOrder,
          pageNumber: productTable.pageNumber,
          searchInput: productTable.searchInput,
          tableFilters: tableFilterPayload,
        },
        signal: controller.signal,
      })
      .then((res) => {
        setProducts(res.data.data.results);
        setProductTable({
          ...productTable,
          totalPages: res.data.data.totalPages,
        });
        setIsFetchingProducts(false);
        setMaxProductListPrice(res.data.data.maxListPriceProduct);
      })
      .catch(() => setIsFetchingProducts(false));
    return () => controller.abort();
  };

  useEffect(() => {
    fetchProducts();
  }, [
    productTable.limit,
    productTable.sort,
    productTable.sortOrder,
    productTable.pageNumber,
    productTable.searchInput,
  ]);

  const onFilter = () => {
    setProductTableFilters(tableFilter);
    setIsFilterUpdated(true);
  };
  useEffect(() => {
    if (isFilterUpdated) {
      fetchProducts();
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const createNewAction = useCallback(() => {
    navigate("/products/add");
  }, [navigate]);

  useEffect(() => {
    action(() => createNewAction);
  }, [action, createNewAction]);

  const convertToCsv = async (includeProductDataset: boolean) => {
    interface DataItem {
      name: string;
      description: string;
      sku: string;
      quantityLimitation: number;
      startVisibility: Date;
      endVisibility: Date;
      brand: string;
      hasSelfPickupOption: boolean;
      hasDeliveryOption: boolean;
      selfPickupAddress: string;
      listPrice: number;
      retailPrice: number;
      costPrice: number;
      deliveryFee: number;
      sellPlatform: string;
      sellPlatformUrl?: string | null;
      productType: string;
      isDeleted: boolean;
      collectionTags: ProductCollectionTag[];
      categoryTags: ProductCategoryTag[];
      tagNames: ProductTag[];
      image_1: string;
      image_2: string;
      image_3: string;
      image_4: string;
      image_5: string;
      image_6: string;
    }
    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    const headerToPropertyMap: { [key: string]: keyof DataItem } = {
      Name: "name",
      Description: "description",
      SKU: "sku",
      "Quantity Limitation": "quantityLimitation",
      "Start Visibility (mm/dd/yyyy)": "startVisibility",
      "End Visibility (mm/dd/yyyy)": "endVisibility",
      Brand: "brand",
      "Has Self Pickup Option?": "hasSelfPickupOption",
      "Has Delivery Option?": "hasDeliveryOption",
      "Self Pickup Address": "selfPickupAddress",
      "List Price": "listPrice",
      "Retail Price": "retailPrice",
      "Cost Price": "costPrice",
      "Delivery Fee": "deliveryFee",
      "Sell Platform": "sellPlatform",
      "Sell Platform URL": "sellPlatformUrl",
      "Product Type": "productType",
      isDeleted: "isDeleted",
      Collection: "collectionTags",
      "Product Categories": "categoryTags",
      tagNames: "tagNames",
      image_1: "image_1",
      image_2: "image_2",
      image_3: "image_3",
      image_4: "image_4",
      image_5: "image_5",
      image_6: "image_6",
    };

    const headersCsv = [
      "Name",
      "Description",
      "SKU",
      "Quantity Limitation",
      "Start Visibility (mm/dd/yyyy)",
      "End Visibility (mm/dd/yyyy)",
      "Brand",
      "Has Self Pickup Option?",
      "Has Delivery Option?",
      "Self Pickup Address",
      "List Price",
      "Retail Price",
      "Cost Price",
      "Delivery Fee",
      "Sell Platform",
      "Sell Platform URL",
      "Product Type",
      "isDeleted",
      "Collection",
      "Product Categories",
      "tagNames",
      "image_1",
      "image_2",
      "image_3",
      "image_4",
      "image_5",
      "image_6",
    ];

    let csv = "";
    csv = headersCsv.join(columnDelimiter) + lineDelimiter;
    if (includeProductDataset) {
      await axios
        .get(`${process.env.REACT_APP_ADMIN_URL}/products`, {
          params: {
            limit: productTable.limit,
            sort: productTable.sort,
            sortOrder: productTable.sortOrder,
            pageNumber: productTable.pageNumber,
            searchInput: productTable.searchInput,
          },
        })
        .then((res) => {
          const exportedProduct: DataItem[] = res.data.data.results;

          exportedProduct.forEach((item: DataItem) => {
            let propertyValue = "";
            headersCsv.forEach((header) => {
              if (typeof header === "string") {
                const propertyKey = headerToPropertyMap[header];
                if (item[propertyKey]) {
                  if (propertyKey === "categoryTags") {
                    const listOfValues: string[] = (
                      item[propertyKey] as ProductCategoryTag[]
                    ).map((categoryTag) => categoryTag.category.name);
                    propertyValue = listOfValues.toString();
                  } else if (propertyKey === "collectionTags") {
                    const listOfValues: string[] = (
                      item[propertyKey] as ProductCollectionTag[]
                    ).map((collectionTag) => collectionTag.collection.name);
                    propertyValue = listOfValues.toString();
                  } else if (propertyKey === "tagNames") {
                    const listOfValues: string[] = (
                      item[propertyKey] as ProductTag[]
                    ).map((productTag) => productTag.productTagMaster.name);
                    propertyValue = listOfValues.toString();
                  } else {
                    propertyValue =
                      item[propertyKey as keyof DataItem]?.toString() ?? "";
                  }
                }
                csv += '"' + propertyValue + '"' + ",";
                propertyValue = "";
              }
            });
            csv += lineDelimiter;
          });
        })
        .catch((error) => {
          toast.error(`Error encountered when fetching all products`);
        });
    }
    return csv;
  };

  const downloadTemplate = async (includeProductDataset: boolean) => {
    try {
      const data = await convertToCsv(includeProductDataset);
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "smilie_bulk_product_template.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(`Error downloading template - ${error}`);
    }
  };

  const unarchiveProduct = (id: number) => {
    axios
      .put(`${process.env.REACT_APP_ADMIN_URL}/products/undelete/${id}`)
      .then((res) => {
        toast.success(`Product ${id} has been unarchived`);
      })
      .catch((error) => {
        toast.error("Error encountered: ", error.message);
      });
  };

  const handleDatesFilter = (startDate: Date | null, endDate: Date | null) => {
    setTableFilter({
      ...tableFilter,
      dateAdded: { startDate: startDate, endDate: endDate },
    });
  };

  const handleVisibilityFilter = (
    showLive: boolean,
    showUnlisted: boolean,
    showScheduled: boolean,
  ) => {
    setTableFilter({
      ...tableFilter,
      visibilityFilter: { showLive, showUnlisted, showScheduled },
    });
  };

  const handlePriceRangeFilter = (priceFilter: PriceFilter) => {
    setTableFilter({
      ...tableFilter,
      priceRange: priceFilter,
    });
  };

  return (
    <>
      <div className="mb-4 flex">
        <button
          className="bg-orange-500 text-white rounded-lg px-4 flex py-3"
          onClick={() => setOpenImportModal(!openImportModal)}
        >
          Import
        </button>
        <button
          className="bg-orange-500 text-white rounded-lg px-4 flex py-3 ml-2"
          onClick={() => downloadTemplate(true)}
        >
          Export
        </button>
        <button
          className="bg-orange-500 text-white rounded-lg px-4 flex py-3 ml-2"
          onClick={() => downloadTemplate(false)}
        >
          Download Template
        </button>
        <div>
          {openImportModal && (
            <Modal
              children={
                <ImportModal isModalVisible={() => setOpenImportModal(false)} />
              }
              isModalVisible={openImportModal}
            />
          )}
        </div>
      </div>
      <div>
        <div className="flex">
          <SearchBar
            className="w-11/12"
            onSearch={(value) => {
              setProductTable({
                ...productTable,
                searchInput: value,
                pageNumber: 1,
              });
            }}
          />
          <img
            className={`pl-4`}
            src={images.filter}
            onClick={() => setShowProductTableFilter(!showProductTableFilter)}
          />
        </div>
        {showProductTableFilter && (
          <FilterProp
            onClick={onFilter}
            filterComponents={[
              <VisibilityFilter
                setVisibilityFilter={handleVisibilityFilter}
                selectedVisibility={{
                  selectedLive: tableFilter.visibilityFilter.showLive,
                  selectedUnlisted: tableFilter.visibilityFilter.showUnlisted,
                  selectedScheduled: tableFilter.visibilityFilter.showScheduled,
                }}
              />,
              <DateAddedFilter
                saveDates={handleDatesFilter}
                selectedDates={{
                  selectedStartDate: tableFilter.dateAdded.startDate,
                  selectedEndDate: tableFilter.dateAdded.endDate,
                }}
              />,
              <PriceRangeFilter
                savePriceFilter={handlePriceRangeFilter}
                maxPrice={maxProductListPrice}
                selectedPrices={tableFilter.priceRange}
              />,
            ]}
          />
        )}

        <div className="mt-4">
          <Table
            headers={headers}
            rows={products.map((product) => ({
              id: product.id,
              component: (
                <ProductRow
                  product={product}
                  pageNumber={productTable.pageNumber}
                />
              ),
              isDeleted: product.isDeleted,
              unarchive: unarchiveProduct,
            }))}
            pageNumber={productTable.pageNumber}
            setPageNumber={(p) => {
              setProductTable({ ...productTable, pageNumber: p });
            }}
            setItemsPerPage={(p: number) => {
              setProductTable({ ...productTable, limit: p, pageNumber: 1 });
            }}
            itemsPerPage={productTable.limit}
            totalPages={productTable.totalPages}
            isMultipleRowSelectable={true}
            multipleSelectOnClick={multipleSelectOnClick}
            multipleSelectChecked={selectProductIds.length > 0}
            multipleSelectedIds={selectProductIds}
            setSelectProductIds={setSelectProductIds}
            selectionPanel={
              <SelectPanel
                selectedIds={selectProductIds}
                handleRemoveSelection={() => setSelectProductIds([])}
              />
            }
            isLoading={isFetchingProducts}
            loadingMessage="Fetching products..."
            sortBy={productTable.sort}
            setSortBy={(column) =>
              setProductTable({
                ...productTable,
                sort: column,
              })
            }
            sortOrder={productTable.sortOrder}
            setSortOrder={(order: "ASC" | "DESC") =>
              setProductTable({
                ...productTable,
                sortOrder: order,
              })
            }
          />
        </div>
      </div>
    </>
  );
};
