import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { formatDate } from "../../../utils/api";
import "./DatePicker.css";

export const DatePicker = ({
  values,
  onChange,
}: {
  values: {
    startDate: Date;
    endDate: Date | null;
  };
  onChange: (startDate: Date, endDate: Date | null) => void;
}) => {
  const [startDate, setStartDate] = useState<Date | null>(values.startDate);
  const [endDate, setEndDate] = useState<Date | null>(values.endDate);

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    onChange(new Date(0), null);
  };

  const handleDates = (date: Date) => {
    if (!startDate || date < startDate) {
      setStartDate(new Date(date));
      setEndDate(null);
      return;
    }

    if (!endDate || date > endDate) {
      setEndDate(new Date(date));
      return;
    }
    onChange(startDate, endDate);
  };

  useEffect(() => {
    if (startDate !== null) onChange(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <>
      <div>
        <Calendar
          calendarType="hebrew"
          defaultView="month"
          onClickDay={handleDates}
          tileClassName={({ date, view }) =>
            view === "month" &&
            startDate &&
            date <= (endDate ?? startDate) &&
            date >= startDate
              ? "selected"
              : ""
          }
        />
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleReset}
          className="rounded-lg px-2 pt-1 py-1 text-slate-400 "
        >
          <span className="underline underline-offset-2">Reset</span>
        </button>
      </div>
      <div className="mt-4">
        <div>
          <p>From</p>
          <input
            type="text"
            className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full"
            placeholder={startDate ? formatDate(startDate.toString()) : ""}
          />
          <span className="text-xs italic text-red-500">
            * Mandatory to include a start date before saving
          </span>
        </div>
        <div className="mt-2">
          <p>To</p>
          <input
            type="text"
            className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full"
            placeholder={endDate ? formatDate(endDate.toString()) : ""}
          />
        </div>
      </div>
    </>
  );
};
