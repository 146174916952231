import { useContext } from "react";
import images from "../constants/images";
import { UserContext } from "../context/UserContextProvider";

export const Header = () => {
  const { user, logout } = useContext(UserContext);

  return (
    <div className="shadow bg-white p-3 px-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={images.logo}
            className="mr-4 h-[50px]"
            alt="Smilie Ops Logo"
          />
          <div className="text-[24px] text-neutrals-800 font-leagueSpartan-400 ml-2">
            {user?.username}
          </div>
        </div>
        <div className="grid grid-flow-col">
          <button
            className="bg-orange-600 text-white rounded-lg px-4 flex py-2"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};
