import images from "../../constants/images";
import { SubCategory } from "../../constants/types";

type TCategoryCard = {
  name: string;
  productCategories: SubCategory[];
  edit: () => void;
};

export const CategoryCard = ({
  name,
  productCategories,
  edit,
}: TCategoryCard) => {
  return (
    <div className="h-64 shadow-lg rounded-lg bg-white">
      <div className="container p-4 h-full">
        <div className="grid grid-cols-12">
          <div className="col-span-11">
            <p className="text-neutral-400">Main Category</p>
            <div className="text-xl capitalize">{name}</div>
          </div>
          <div className="col-span-1">
            <button onClick={edit}>
              <img src={images.edit} alt="" />
            </button>
          </div>
        </div>

        <p className="text-neutral-400 mt-4">Tags</p>
        {productCategories.length > 0 ? (
          <div className="flex flex-wrap">
            {productCategories.map((item: SubCategory) => (
              <div
                key={item.id}
                className="rounded-full px-3 py-1 m-1 items-center whitespace-nowrap bg-lightOrange"
              >
                {item.name}
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center mt-12">
            <span>No tags available</span>
          </div>
        )}
      </div>
    </div>
  );
};
