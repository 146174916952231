import { createContext, useState } from "react";

export type PaginatedTableAttributes = {
  limit: number;
  sort: string;
  sortOrder: "ASC" | "DESC";
  pageNumber: number;
  searchInput: string;
  totalPages: number;
};

export type ProductTableFilterAttributes = {
  visibilityFilter: {
    showLive: boolean;
    showUnlisted: boolean;
    showScheduled: boolean;
  };
  dateAdded: { startDate: Date | null; endDate: Date | null };
  priceRange: { minPrice: number; maxPrice: number };
};

export type ConsolidatedOrdersOrGiftLinksFilterAndPagination = {
  time: {
    from: Date;
    to: Date;
  };
  filter: {
    campaigns: any[];
    status: any[];
    mode?: any[];
  };
  q: string;
  sort: string;
  sortOrder: "ASC" | "DESC";
  pageNumber: number;
  limit: number;
};

export const BreadcrumbContext = createContext<{
  productTable: PaginatedTableAttributes;
  setProductTable: React.Dispatch<
    React.SetStateAction<PaginatedTableAttributes>
  >;
  collectionTable: PaginatedTableAttributes;
  setCollectionTable: React.Dispatch<
    React.SetStateAction<PaginatedTableAttributes>
  >;
  donationTable: PaginatedTableAttributes;
  setDonationTable: React.Dispatch<
    React.SetStateAction<PaginatedTableAttributes>
  >;
  themeTable: PaginatedTableAttributes;
  setThemeTable: React.Dispatch<React.SetStateAction<PaginatedTableAttributes>>;
  productTableFilters: ProductTableFilterAttributes;
  setProductTableFilters: React.Dispatch<
    React.SetStateAction<ProductTableFilterAttributes>
  >;
  ordersTable: ConsolidatedOrdersOrGiftLinksFilterAndPagination;
  setOrdersTable: React.Dispatch<
    React.SetStateAction<ConsolidatedOrdersOrGiftLinksFilterAndPagination>
  >;
  giftLinksTable: ConsolidatedOrdersOrGiftLinksFilterAndPagination;
  setGiftLinksTable: React.Dispatch<
    React.SetStateAction<ConsolidatedOrdersOrGiftLinksFilterAndPagination>
  >;
}>({
  productTable: {
    limit: 10,
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    searchInput: "",
    totalPages: 0,
  },
  setProductTable: () => {},
  collectionTable: {
    limit: 10,
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    searchInput: "",
    totalPages: 0,
  },
  setCollectionTable: () => {},
  donationTable: {
    limit: 10,
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    searchInput: "",
    totalPages: 0,
  },
  setDonationTable: () => {},
  themeTable: {
    limit: 10,
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    searchInput: "",
    totalPages: 0,
  },
  setThemeTable: () => {},
  productTableFilters: {
    visibilityFilter: {
      showLive: true,
      showScheduled: true,
      showUnlisted: true,
    },
    dateAdded: { startDate: null, endDate: null },
    priceRange: { minPrice: 0, maxPrice: 150 },
  },
  setProductTableFilters: () => {},
  ordersTable: {
    time: {
      from: new Date(0),
      to: new Date(),
    },
    filter: {
      campaigns: [],
      status: [],
      mode: [],
    },
    q: "",
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    limit: 10,
  },
  setOrdersTable: () => {},
  giftLinksTable: {
    time: {
      from: new Date(0),
      to: new Date(),
    },
    filter: {
      campaigns: [],
      status: [],
      mode: [],
    },
    q: "",
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    limit: 10,
  },
  setGiftLinksTable: () => {},
});

export const BreadcrumbContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [productTable, setProductTable] = useState<PaginatedTableAttributes>({
    limit: 10,
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    searchInput: "",
    totalPages: 0,
  });
  const [collectionTable, setCollectionTable] =
    useState<PaginatedTableAttributes>({
      limit: 10,
      sort: "",
      sortOrder: "DESC",
      pageNumber: 1,
      searchInput: "",
      totalPages: 0,
    });
  const [donationTable, setDonationTable] = useState<PaginatedTableAttributes>({
    limit: 10,
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    searchInput: "",
    totalPages: 0,
  });
  const [themeTable, setThemeTable] = useState<PaginatedTableAttributes>({
    limit: 10,
    sort: "",
    sortOrder: "DESC",
    pageNumber: 1,
    searchInput: "",
    totalPages: 0,
  });

  const [productTableFilters, setProductTableFilters] =
    useState<ProductTableFilterAttributes>({
      visibilityFilter: {
        showLive: true,
        showScheduled: true,
        showUnlisted: true,
      },
      dateAdded: { startDate: null, endDate: null },
      priceRange: { minPrice: 0, maxPrice: 150 },
    });

  const [giftLinksTable, setGiftLinksTable] =
    useState<ConsolidatedOrdersOrGiftLinksFilterAndPagination>({
      time: {
        from: new Date(0),
        to: new Date(),
      },
      filter: {
        campaigns: [],
        status: [],
        mode: [],
      },
      q: "",
      sort: "",
      sortOrder: "DESC",
      pageNumber: 1,
      limit: 10,
    });
  const [ordersTable, setOrdersTable] =
    useState<ConsolidatedOrdersOrGiftLinksFilterAndPagination>({
      time: {
        from: new Date(0),
        to: new Date(),
      },
      filter: {
        campaigns: [],
        status: [],
        mode: [],
      },
      q: "",
      sort: "",
      sortOrder: "DESC",
      pageNumber: 1,
      limit: 10,
    });
  const value = {
    productTable,
    setProductTable,
    collectionTable,
    setCollectionTable,
    donationTable,
    setDonationTable,
    themeTable,
    setThemeTable,
    productTableFilters,
    setProductTableFilters,
    ordersTable,
    setOrdersTable,
    giftLinksTable,
    setGiftLinksTable,
  };

  return (
    <BreadcrumbContext.Provider value={value}>
      {children}
    </BreadcrumbContext.Provider>
  );
};
