import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "./common/DatePicker/DatePicker";
import { DragAndDrop } from "./common/DragAndDrop";
import { SearchTable } from "./common/SearchTable";
import { TableWrapper } from "./dashboard/TableWrapper";

type propType = {
  title: string;
};

enum Visibility {
  Live = "Live",
  Scheduled = "Scheduled",
  Unlisted = "Unlisted",
}

export const AddCollectionPage = (props: propType) => {
  const params = useParams();
  const { id } = params;
  const { title } = props;
  const [titleText, setTitleText] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const [visibility, setVisibility] = useState<Visibility>(Visibility.Live);
  const [schedule, setSchedule] = useState<{
    startDate: Date;
    endDate: Date | null;
  }>({ startDate: new Date(), endDate: null });

  const [category, setCategory] = useState<number[]>([]);
  const [account, setAccount] = useState<number[]>([]);
  const [product, setProduct] = useState<number[]>([]);
  const [azureImageUrl, setAzureImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => window.scrollTo(0, 0), []);

  const url = process.env.REACT_APP_ADMIN_URL;
  const navigate = useNavigate();

  const getAzureImageUrl = (imgUrlArray: string[]) => {
    if (imgUrlArray.length > 0) {
      setAzureImageUrl(imgUrlArray[0]);
    }
  };

  const upload = async () => {
    const payload = {
      name: titleText,
      description: description,
      notes: notes,
      startVisibility: schedule.startDate,
      endVisibility: schedule.endDate,
      corp: account,
      collectionCategories: category,
      collectionTags: product,
      picture: azureImageUrl,
    };

    axios
      .post(`${url}/collections`, payload)
      .then((res) => {
        toast.success("Collection creation successful");
        navigate(`/collections/${res.data.data.id}`);
      })
      .catch((error) => {
        toast.error("Error encountered: ", error.message);
      });
  };

  const update = async () => {
    const payload = {
      id: Number(id),
      name: titleText,
      description: description,
      notes: notes,
      startVisibility: schedule.startDate,
      endVisibility: schedule.endDate,
      corp: account,
      collectionCategories: category,
      collectionTags: product,
      picture: azureImageUrl,
    };

    axios
      .put(`${url}/collections/${id}`, payload)
      .then((res) => {
        toast.success("Collection updated successful");
      })
      .catch((error) => {
        toast.error("Error encountered: ", error.message);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    if (id) {
      axios
        .get(`${process.env.REACT_APP_ADMIN_URL}/collections/${id}`, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          const collectionData = data.data;
          setTitleText(collectionData.name);
          setDescription(collectionData.description);
          setNotes(collectionData.notes);
          setAzureImageUrl(collectionData.picture);
          if (collectionData.startVisibility && collectionData.endVisibility) {
            visibilityStatus(
              new Date(collectionData.startVisibility),
              collectionData.endVisibility
                ? new Date(collectionData.endVisibility)
                : null,
            );
            setSchedule({
              startDate: new Date(collectionData.startVisibility),
              endDate: collectionData.endVisibility
                ? new Date(collectionData.endVisibility)
                : null,
            });
          }
          setAccount(
            collectionData.corp.map(
              (corp: { corp: { id: any } }) => corp.corp.id,
            ),
          );
          setCategory(
            collectionData.collectionCategories.map(
              (collectionCategory: { id: any }) => collectionCategory.id,
            ),
          );
          setProduct(
            collectionData.collectionTags.map(
              (collectionTag: { product: { id: any } }) =>
                collectionTag.product.id,
            ),
          );
          setIsLoading(false);
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          toast.error(error.message);
          setIsLoading(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [id]);

  const visibilityStatus = (
    startVisibility: Date,
    endVisibility: Date | null,
  ) => {
    let visibilityStatus = Visibility.Unlisted;
    const todayDate = new Date();
    const nullDate = new Date(0);

    if (
      startVisibility <= todayDate &&
      (endVisibility === null ||
        endVisibility.toString() === nullDate.toString())
    ) {
      visibilityStatus = Visibility.Live;
    } else if (
      (endVisibility !== null &&
        startVisibility.toString() !== nullDate.toString() &&
        endVisibility >= todayDate) ||
      (startVisibility > todayDate &&
        (endVisibility === null ||
          endVisibility.toString() === nullDate.toString()))
    ) {
      visibilityStatus = Visibility.Scheduled;
    }
    setVisibility(visibilityStatus);
  };

  const setScheduleWithVisibilityStatus = (visibility: string) => {
    if (visibility === Visibility.Live) {
      setSchedule({ startDate: new Date(), endDate: null });
    } else if (visibility === Visibility.Unlisted) {
      setSchedule({
        startDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
        endDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
      });
    }
  };

  useEffect(() => {
    setScheduleWithVisibilityStatus(visibility);
  }, [visibility]);

  const handleSchedule = (startDate: Date, endDate: Date | null) => {
    setSchedule({ startDate: startDate, endDate: endDate });
  };

  const handleCategory = (idArr: number[]) => {
    setCategory(idArr);
  };

  const handleAccount = (idArr: number[]) => {
    setAccount(idArr);
  };

  const handleProduct = (idArr: number[]) => {
    setProduct(idArr);
  };

  const productTableComponent = TableWrapper(
    `${process.env.REACT_APP_ADMIN_URL}/products`,
    [
      { heading: "BRAND", key: "Product.brand", isSortable: true },
      { heading: "PRODUCT", key: "Product.name", isSortable: true },
      { heading: "SMILIE PRICE", key: "Product.costPrice", isSortable: true },
      { heading: "VISIBILITY", key: "VISIBILITY", isSortable: false },
      { heading: "DATE ADDED", key: "Product.createdAt", isSortable: true },
      { heading: "CATEGORY", key: "productCategory.name", isSortable: true },
    ],
    handleProduct,
    product,
    isLoading,
  );

  return (
    <div className="container p-4">
      <div className="flex justify-between items-center">
        <div className="text-[32px]">
          {id ? "Edit" : "Add"} {title}
        </div>
        <div className="gap-1 flex align-middle">
          <Link to="/collections">
            <button className="w-full rounded-lg px-2 pt-1 text-slate-400">
              Cancel
            </button>
          </Link>
          <button
            className="w-full rounded-lg px-4 pt-1 text-white bg-zinc-600"
            onClick={id ? update : upload}
          >
            Save
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-6 mt-4">
        {/* left column */}
        <div className="col-span-7 gap-6">
          <div className="grid gap-6">
            <div className="rounded-lg bg-white shadow-md">
              <div className=" p-6">
                <div className="text-[20px]">Media</div>
                <div className="justify-center">
                  <DragAndDrop
                    getAzureImageUrl={getAzureImageUrl}
                    updateImgUrl={azureImageUrl ? [azureImageUrl] : []}
                    numOfImages={1}
                  />
                </div>
              </div>
            </div>

            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div>
                  <div className="flex">
                    <p className="text-[16px] font-semibold">Title</p>
                    <span className="italic text-slate-500 text-xs pl-1.5 self-center">
                      required
                    </span>
                  </div>
                  <input
                    type="text"
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Title of Collection"
                    onChange={(e) => setTitleText(e.target.value)}
                    value={titleText}
                  />
                </div>
                <div className="mt-3">
                  <div className="flex">
                    <p className="text-[16px] font-semibold">Description</p>
                    <span className="italic text-slate-500 text-xs pl-1.5 self-center">
                      required
                    </span>
                  </div>
                  <textarea
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Add Description"
                    rows={5}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </div>
                <div className="mt-3">
                  <p className="text-[16px] font-semibold">Notes</p>
                  <textarea
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Enter notes here"
                    rows={5}
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* right column */}
        <div className="col-span-5">
          <div className="grid gap-6">
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div className="text-[20px]">Collection Visibility</div>
                <div className="justify-center">
                  <select
                    name="visibility"
                    value={visibility}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg} ${border-[1px] border-[neutral-400] w-full rounded-lg my-4 focus:border-2 focus:border-orange-600 outline-none"
                    onChange={(e) =>
                      setVisibility(e.target.value as Visibility)
                    }
                  >
                    <option value={Visibility.Live}>Live</option>
                    <option value={Visibility.Scheduled}>Scheduled</option>
                    <option value={Visibility.Unlisted}>Unlisted</option>
                  </select>
                </div>
                {visibility === Visibility.Scheduled ? (
                  <div>
                    <DatePicker
                      values={{
                        startDate: schedule.startDate
                          ? schedule.startDate
                          : new Date(),
                        endDate: schedule.endDate
                          ? schedule.endDate
                          : new Date(),
                      }}
                      onChange={handleSchedule}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div className="text-xl">Accounts</div>
                {!isLoading && (
                  <SearchTable
                    apiURL={`${process.env.REACT_APP_ADMIN_URL}/corp`}
                    usage="Account"
                    columnName="email"
                    save={(idArr) => handleAccount(idArr)}
                    selectedValues={account}
                  />
                )}
              </div>
            </div>
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div className="text-xl">Category</div>
                {!isLoading && (
                  <SearchTable
                    apiURL={`${process.env.REACT_APP_ADMIN_URL}/categories`}
                    usage="Category"
                    columnName="name"
                    save={(idArr) => handleCategory(idArr)}
                    selectedValues={category}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{productTableComponent}</div>

      <ToastContainer position="top-right" theme="colored" autoClose={3000} />
    </div>
  );
};
