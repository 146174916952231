import { createContext, useState } from "react";
import { UserContextProvider } from "./UserContextProvider";

export enum AppTabs {
  Home = "Home",
  Orders = "Orders",
  Products = "Products",
  Customers = "Customers",
  Finances = "Finances",
  Analytics = "Analytics",
  GiftCards = "Gift Cards",
}

type TAppContext = {
  scroll: boolean;
  setScroll: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: AppTabs;
  setActiveTab: React.Dispatch<React.SetStateAction<AppTabs>>;
  isSideBarHidden: boolean;
  setIsSideBarHidden: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppContext = createContext<TAppContext>({
  scroll: true,
  setScroll: () => {},
  activeTab: AppTabs.Products,
  setActiveTab: () => {},
  isSideBarHidden: false,
  setIsSideBarHidden: () => {},
});

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

export const AppContextProvider = ({ children }: Props) => {
  const [activeTab, setActiveTab] = useState(AppTabs.Products);
  const [isSideBarHidden, setIsSideBarHidden] = useState(false);
  const [scroll, setScroll] = useState(true);

  return (
    <AppContext.Provider
      value={{
        scroll,
        setScroll,
        activeTab,
        setActiveTab,
        isSideBarHidden,
        setIsSideBarHidden,
      }}
    >
      <UserContextProvider>{children}</UserContextProvider>
    </AppContext.Provider>
  );
};
