import { createContext, useState } from "react";
import {
  DeepPartial,
  PickUp,
  Product,
  ProductVariant,
} from "../constants/types";

export type DeliveryOption = {
  name: string;
  deliveryFee: number;
  deliveryDurationLatest: number;
  deliveryDurationEarliest: number;
};

export const ProductContext = createContext<{
  product: DeepPartial<Product>;
  setProduct: React.Dispatch<React.SetStateAction<DeepPartial<Product>>>;
  setProductId: (productId: number) => void;
  setAzureImageUrl: (imgUrlArray: string[]) => void;
  setSchedule: (startDate: Date, endDate: Date | null) => void;
  addVariant: () => void;
  removeVariant: (index: number) => void;
  editVariant: (index: number, newVariant: ProductVariant) => void;
  addVariantOption: (variantIndex: number) => void;
  updateVariantOption: (
    index: number,
    optionIndex: number,
    newValue: string,
  ) => void;
  deleteVariantOption: (index: number, optionIndex: number) => void;
  addDeliveryOptions: () => void;
  removeDeliveryOptions: (index: number) => void;
  editDeliveryOptions: (index: number, option: DeliveryOption) => void;
  addPickUpOptions: () => void;
  removePickUpOptions: (index: number) => void;
  removeAllPickUpOptions: () => void;
  editPickUpOptions: (index: number, option: PickUp) => void;
}>({
  product: {
    image_1: "",
    image_2: "",
    image_3: "",
    image_4: "",
    image_5: "",
    image_6: "",
    brand: "",
    name: "",
    description: "",
    sellPlatformUrl: null,
    productType: "Physical",
    sku: "",
    quantityLimitation: 0,
    costPrice: 0,
    retailPrice: 0,
    listPrice: 0,
    startVisibility: new Date(),
    endVisibility: null,
    variations: [],
    pickUp: [],
    delivery: [],
  },
  setProduct: () => {},
  setProductId: (productId: number) => {},
  setAzureImageUrl: (imgUrlArray: string[]) => {},
  setSchedule: (startDate: Date, endDate: Date | null) => {},
  addVariant: () => {},
  removeVariant: (index: number) => {},
  editVariant: (index: number, newVariant: ProductVariant) => {},
  addVariantOption: (variantIndex: number) => {},
  updateVariantOption: (
    index: number,
    optionIndex: number,
    newValue: string,
  ) => {},
  deleteVariantOption: (index: number, optionIndex: number) => {},
  addDeliveryOptions: () => {},
  removeDeliveryOptions: (index: number) => {},
  editDeliveryOptions: (index: number, option: DeliveryOption) => {},
  addPickUpOptions: () => {},
  removePickUpOptions: (index: number) => {},
  removeAllPickUpOptions: () => {},
  editPickUpOptions: (index: number, option: PickUp) => {},
});

export const ProductContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [product, setProduct] = useState<DeepPartial<Product>>({
    image_1: "",
    image_2: "",
    image_3: "",
    image_4: "",
    image_5: "",
    image_6: "",
    brand: "",
    name: "",
    description: "",
    sellPlatformUrl: null,
    hasSelfPickupOption: false,
    hasDeliveryOption: false,
    productType: "Physical",
    sku: "",
    quantityLimitation: 0,
    costPrice: 0,
    retailPrice: 0,
    listPrice: 0,
    startVisibility: new Date(),
    endVisibility: null,
    variations: [],
    pickUp: [],
    delivery: [],
  });

  const setProductId = (productId: number) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      id: productId,
    }));
  };

  const setAzureImageUrl = (imgUrlArray: string[]) => {
    for (let i = 0; i < 6; i++) {
      setProduct((prevProduct) => {
        return {
          ...prevProduct,
          [`image_${i + 1}`]: imgUrlArray[i],
        };
      });
    }
  };

  const setSchedule = (startDate: Date, endDate: Date | null) => {
    setProduct((product) => {
      return {
        ...product,
        startVisibility: startDate,
        endVisibility: endDate,
      };
    });
  };

  const addVariant = () => {
    setProduct((prevProduct) => {
      const updatedVariations = prevProduct.variations || [];
      const newVariation = { name: "", options: [{ value: "" }] };
      const updatedProduct = {
        ...prevProduct,
        variations: [...updatedVariations, newVariation],
      };

      return updatedProduct;
    });
  };

  const addVariantOption = (variantIndex: number) => {
    setProduct((prevProduct) => {
      if (!prevProduct.variations || !prevProduct.variations[variantIndex]) {
        return prevProduct;
      }

      const newVariationOption = { value: "" };
      const updatedVariations = prevProduct.variations.map((variant, i) => {
        if (i === variantIndex && variant && Array.isArray(variant.options)) {
          const updatedOptions = [...variant.options, newVariationOption];
          return { ...variant, options: updatedOptions };
        }
        return variant;
      });

      return { ...prevProduct, variations: updatedVariations };
    });
  };

  const removeVariant = (index: number) => {
    setProduct((prevProduct) => {
      const updatedVariations = prevProduct.variations?.filter(
        (_, i) => i !== index,
      );
      const updatedProduct = {
        ...prevProduct,
        variations: updatedVariations,
      };
      return updatedProduct;
    });
  };

  const editVariant = (index: number, newVariant: ProductVariant) => {
    setProduct((prevProduct) => {
      const updatedVariations =
        prevProduct.variations?.map((variant, i: number) => {
          if (i === index) {
            return newVariant;
          }
          return variant;
        }) || [];
      const updatedProduct = {
        ...prevProduct,
        variations: updatedVariations,
      };
      return updatedProduct;
    });
  };

  const updateVariantOption = (
    index: number,
    optionIndex: number,
    newValue: string,
  ) => {
    setProduct((prevProduct) => {
      const updatedVariations =
        prevProduct.variations?.map((variant, i) => {
          if (i === index && variant && Array.isArray(variant.options)) {
            const updatedOptions = [...variant.options];
            if (optionIndex >= 0 && optionIndex < updatedOptions.length) {
              updatedOptions[optionIndex] = {
                ...updatedOptions[optionIndex],
                value: newValue,
              };
              return { ...variant, options: updatedOptions };
            }
          }
          return variant;
        }) || [];
      const updatedProduct = {
        ...prevProduct,
        variations: updatedVariations,
      };
      return updatedProduct;
    });
  };

  const deleteVariantOption = (index: number, optionIndex: number) => {
    setProduct((prevProduct) => {
      const updatedVariations =
        prevProduct.variations?.map((variant, i) => {
          if (i === index && variant && Array.isArray(variant.options)) {
            const updatedOptions = variant.options.filter(
              (_, idx) => idx !== optionIndex,
            );
            return { ...variant, options: updatedOptions };
          }
          return variant;
        }) || [];
      const updatedProduct = {
        ...prevProduct,
        variations: updatedVariations,
      };
      return updatedProduct;
    });
  };

  const addDeliveryOptions = () => {
    setProduct((prevProduct) => {
      const newDeliveryOption = prevProduct.delivery || [];
      const newDelivery = {
        name: "",
        deliveryFee: 0,
        deliveryDurationLatest: 0,
        deliveryDurationEarliest: 0,
      };
      const updatedProduct = {
        ...prevProduct,
        delivery: [...newDeliveryOption, newDelivery],
      };
      return updatedProduct;
    });
  };

  const removeDeliveryOptions = (index: number) => {
    setProduct((prevProduct) => {
      const updateDelivery = prevProduct.delivery?.filter(
        (_, i) => i !== index,
      );
      const updatedDelivery = {
        ...prevProduct,
        delivery: updateDelivery,
      };
      return updatedDelivery;
    });
  };
  const editDeliveryOptions = (index: number, option: DeliveryOption) => {
    if (!product.delivery || index < 0 || index >= product.delivery.length) {
      return;
    }
    const newDeliveryOptions = [...product.delivery];
    newDeliveryOptions[index] = option;
    setProduct((prevProduct) => ({
      ...prevProduct,
      delivery: newDeliveryOptions,
    }));
  };

  const addPickUpOptions = () => {
    setProduct((prevProduct) => {
      const updatedPickUps = prevProduct.pickUp || [];
      const newPickUp = {
        address: "",
        pickUpDurationEarliest: 0,
        pickUpDurationLatest: 0,
      };
      const updatedProduct = {
        ...prevProduct,
        pickUp: [...updatedPickUps, newPickUp],
      };

      return updatedProduct;
    });
  };

  const removePickUpOptions = (index: number) => {
    setProduct((prevProduct) => {
      const updatePickUp = prevProduct.pickUp?.filter((_, i) => i !== index);
      const updatedPickUp = {
        ...prevProduct,
        pickUp: updatePickUp,
      };
      return updatedPickUp;
    });
  };

  const removeAllPickUpOptions = () => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      pickUp: [],
    }));
  };

  const editPickUpOptions = (index: number, option: PickUp) => {
    if (!product.pickUp || index < 0 || index >= product.pickUp.length) {
      return;
    }
    const newPickUpOption = [...product.pickUp];
    newPickUpOption[index] = option;
    setProduct((prevProduct) => ({
      ...prevProduct,
      pickUp: newPickUpOption,
    }));
  };

  const value = {
    product,
    setProduct,
    setProductId,
    setAzureImageUrl,
    setSchedule,
    addVariant,
    removeVariant,
    addVariantOption,
    editVariant,
    updateVariantOption,
    deleteVariantOption,
    addDeliveryOptions,
    removeDeliveryOptions,
    editDeliveryOptions,
    addPickUpOptions,
    removePickUpOptions,
    removeAllPickUpOptions,
    editPickUpOptions,
  };

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};
