import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Product } from "../../constants/types";
import { formatDate } from "../../utils/api";
import { VisibilityDropdown } from "./VisibilityDropdown";

export const ProductRow = ({
  product,
  pageNumber,
}: {
  product: Product;
  pageNumber?: number;
}) => {
  const {
    id,
    name,
    image_1,
    listPrice,
    costPrice,
    createdAt,
    brand,
    startVisibility,
    endVisibility,
    categoryTags,
  } = product;

  const update = async (startDate: Date, endDate: Date | null) => {
    const payload = {
      ...product,
      startVisibility: startDate,
      endVisibility: endDate,
      categoryTags: categoryTags.map((ct) => ct.category.id),
    };

    axios
      .put(`${process.env.REACT_APP_ADMIN_URL}/products/${payload.id}`, payload)
      .then((res) => {
        toast.success(`Product ${id}'s date was updated successful`);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <td className="p-2">{brand}</td>
      <td className="p-2">
        <Link
          draggable="false"
          to={`/products/${id}`}
          state={{ fromPage: pageNumber }}
        >
          <div className="flex">
            <img
              draggable="false"
              src={image_1}
              alt=""
              className="max-w-24 object-contain"
            />
            <div className="max-w-96">
              <div className="p-2 flex items-center text-left">
                <span className="hover:underline hover:underline-offset-2">
                  {name}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </td>
      <td className="p-2">
        <p className="mb-2">${listPrice.toFixed(2)}</p>
        <p className="text-orange-600">
          Margin: ${(listPrice - costPrice).toFixed(2)}
        </p>
      </td>
      <td className="p-2">
        <VisibilityDropdown
          VisibilityComponentProp={{
            startVisibility: new Date(startVisibility),
            endVisibility: endVisibility ? new Date(endVisibility) : null,
          }}
          saveDates={update}
        />
      </td>
      <td className="p-2">{formatDate(createdAt.toString())}</td>
      <td className="p-2 text-left text-nowrap">
        <ul>
          {categoryTags.map((category) => (
            <li
              key={category.category.id.toString()}
              id={category.category.id.toString()}
            >
              ({category.category.id}) {category.category.name}
            </li>
          ))}
        </ul>
      </td>
    </>
  );
};
