import axios from "axios";

const azureUrl = process.env.REACT_APP_CORP_URL + "/api/corp/media/";

export const uploadingImgToAzure = async (formData: FormData) => {
  try {
    const res = await axios.post(azureUrl, formData);
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deletingImgFromAzure = async (imgUrl: any) => {
  try {
    const data = { url: imgUrl };
    const res = await axios.delete(azureUrl, { data });
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
