import { isAxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { deletingImgFromAzure, uploadingImgToAzure } from "../../utils/upload";

type TDragAndDrop = {
  getAzureImageUrl: Function;
  updateImgUrl: string[];
  numOfImages: number;
};

export const DragAndDrop = ({
  getAzureImageUrl,
  updateImgUrl,
  numOfImages,
}: TDragAndDrop) => {
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [azureImageUrls, setAzureImageUrls] = useState<string[]>([]);

  /**
   * this function accepts the images/
   */
  /**OnDrop image upload function */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const hasAnyFilesExceedSize = acceptedFiles.some(
      // corp validation (10mb), as well as k8s nginx ingress default limit is 1mb
      (file) => file.size > 1 * 1024 * 1024,
    );

    if (hasAnyFilesExceedSize) {
      toast.error("File size must be less than 1Mb");
      return;
    }

    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...acceptedFiles,
    ]);
  }, []);

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    multiple: true,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    maxFiles: numOfImages,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  /**
   * Upload image function, Upload to azure after selecting locally.
   */

  const uploadToAzure = async (formData: any) => {
    return uploadingImgToAzure(formData)
      .then((res) => {
        if (isAxiosError(res)) {
          // Handle error if needed
          return Promise.reject(res.response?.data.message || "Invalid data!");
        } else {
          // Return the response data if successful
          return res?.data;
        }
      })
      .catch((error) => {
        // Handle error if needed
        toast.error("Error uploading image:", error);
        return Promise.reject(error);
      });
  };

  const handleUploadImage = useCallback(() => {
    if (
      selectedImages.length > numOfImages ||
      selectedImages.length + azureImageUrls.length > numOfImages
    ) {
      toast.error(`Only ${numOfImages} allowed`);
      setSelectedImages([]);
      return;
    }
    try {
      const formDataArray = selectedImages.map((image, index) => {
        const formData = new FormData();
        formData.append("file", image);
        return formData;
      });

      Promise.all(formDataArray.map(uploadToAzure))
        .then((responses) => {
          setAzureImageUrls((prev) => {
            return [...prev, ...responses];
          });

          getAzureImageUrl([...azureImageUrls, ...responses], false);

          setSelectedImages([]);
        })
        .catch((error) => {
          toast.error("Error uploading images:", error);
        });
    } catch (error) {
      errorMessage();
    }
  }, [azureImageUrls, getAzureImageUrl, numOfImages, selectedImages]);

  const handleRemoveUpdatingImg = (index: any) => {
    try {
      deletingImgFromAzure(azureImageUrls[index]).then((res) => {
        toast("Success!");
        let arr = azureImageUrls.filter((item, i) => {
          return i !== index;
        });
        setAzureImageUrls(arr);
        let isImgDel: boolean;
        updateImgUrl[0] === undefined ? (isImgDel = false) : (isImgDel = true);
        getAzureImageUrl(arr, isImgDel);
      });
    } catch (error) {
      errorMessage();
    }
  };

  useEffect(() => {
    if (selectedImages.length > 0) {
      handleUploadImage();
    }
    if (updateImgUrl[0] && !azureImageUrls.length) {
      setAzureImageUrls(
        updateImgUrl.filter((url) => {
          return url;
        }),
      );
    } else {
      return;
    }
  }, [updateImgUrl, selectedImages, azureImageUrls.length, handleUploadImage]);

  return (
    <div>
      {azureImageUrls.length !== numOfImages && (
        <div
          {...getRootProps()}
          className={`p-5 rounded-lg border-2 border-gray-300 border-dashed text-center cursor-pointer ${
            isDragActive ? "bg-black bg-opacity-25" : ""
          }`}
        >
          <input {...getInputProps()} />
          <div className="flex justify-center">
            <button
              type="button"
              className="mx-2 rounded-md px-4 pt-2 font-bold text-orange-600 bg-[#FFEAE1]"
            >
              Add files
            </button>
          </div>
          <div className="items-center text-center">
            <p className="text-[18px] mt-2">
              Click or drag file to this area to upload file
            </p>
            <p className="mt-2 text-slate-500">1MB max file size</p>
          </div>
        </div>
      )}
      <div className="grid grid-cols-6 gap-4">
        {azureImageUrls?.map((url, index) => {
          return (
            <div
              key={index}
              className="col-span-1 flex flex-col items-center m-2"
            >
              <img src={url} alt="" className="m-2" />
              {updateImgUrl[index] && (
                <button
                  type="button"
                  onClick={() => handleRemoveUpdatingImg(index)}
                >
                  Remove
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
