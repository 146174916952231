import { useContext } from "react";
import { ProductContext } from "../../context/Product.context";
import { Checkbox } from "../common/Checkbox";
import { DeliveryRow } from "./DeliveryRow";

export const DeliveryField = () => {
  const { product, addDeliveryOptions, removeDeliveryOptions, setProduct } =
    useContext(ProductContext);

  const handleButtonClick = () => {
    addDeliveryOptions();
  };

  const handleCheckBoxChange = () => {
    setProduct((prevProduct) => {
      const updatedProduct = {
        ...prevProduct,
        hasDeliveryOption: !prevProduct.hasDeliveryOption,
      };
      return updatedProduct;
    });
  };

  return (
    <div>
      <div className="flex w-1/12 my-3">
        <Checkbox
          onClick={handleCheckBoxChange}
          checked={product.hasDeliveryOption ? true : false}
        />
        <span
          className={`text-[14px] min-w-[200px] font-PublicSans ${
            product.hasDeliveryOption ? "text-orange-600" : ""
          }`}
        >
          Available for Delivery
        </span>
      </div>

      {product.delivery && product.hasDeliveryOption && (
        <>
          {product.delivery.map((_, index: number) => (
            <DeliveryRow index={index} key={index} />
          ))}
          <div className="mt-4 flex justify-end">
            <button
              className="ps-2 inline-flex items-center text-orange-600"
              onClick={handleButtonClick}
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "4px" }}
              >
                <path
                  d="M12 5.5V19.5M5 12.5H19"
                  stroke="#ea580c"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ps-2 pt-1">Add Delivery Option</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
