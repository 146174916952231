import { useContext } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as AnalyticsSVG } from "../constants/images/icons/Analytics.svg";
import { ReactComponent as CustomersSVG } from "../constants/images/icons/Customers.svg";
import { ReactComponent as FinancesSVG } from "../constants/images/icons/Finances.svg";
import { ReactComponent as HomeSVG } from "../constants/images/icons/Home.svg";
import { ReactComponent as OrdersSVG } from "../constants/images/icons/Orders.svg";
import { ReactComponent as ProductsSVG } from "../constants/images/icons/Products.svg";
import { ReactComponent as GiftCardsSVG } from "../constants/images/icons/card_giftcard.svg";
import { AppContext, AppTabs } from "../context/AppContextProvider";

export const Sidebar = () => {
  const { activeTab, setActiveTab, isSideBarHidden } = useContext(AppContext);

  const items: {
    name: AppTabs;
    image: JSX.Element;
    path: string;
  }[] = [
    {
      name: AppTabs.Home,
      image: (
        <HomeSVG
          className={AppTabs.Home === activeTab ? "text-orange-600" : ""}
        />
      ),
      path: "/home",
    },
    {
      name: AppTabs.Orders,
      image: (
        <OrdersSVG
          className={AppTabs.Orders === activeTab ? "text-orange-600" : ""}
        />
      ),
      path: "/orders",
    },
    {
      name: AppTabs.Products,
      image: (
        <ProductsSVG
          className={AppTabs.Products === activeTab ? "text-orange-600" : ""}
        />
      ),
      path: "products",
    },
    {
      name: AppTabs.Customers,
      image: (
        <CustomersSVG
          className={AppTabs.Customers === activeTab ? "text-orange-600" : ""}
        />
      ),
      path: "/corps",
    },
    {
      name: AppTabs.Finances,
      image: (
        <FinancesSVG
          className={AppTabs.Finances === activeTab ? "text-orange-600" : ""}
        />
      ),
      path: "/finances",
    },
    {
      name: AppTabs.Analytics,
      image: (
        <AnalyticsSVG
          className={AppTabs.Analytics === activeTab ? "text-orange-600" : ""}
        />
      ),
      path: "/analytics",
    },
    {
      name: AppTabs.GiftCards,
      image: (
        <GiftCardsSVG
          className={AppTabs.GiftCards === activeTab ? "text-orange-600" : ""}
        />
      ),
      path: "/giftcards",
    },
  ];

  return (
    <div
      className={`${
        isSideBarHidden ? "hidden" : "col-span-2 shadow-lg mt-6 min-h-screen"
      }`}
    >
      {items.map((item) => (
        <Link to={item.path} key={item.name}>
          <div
            key={item.name}
            className={`grid grid-cols-5 ps-6 py-3 rounded-r-2xl cursor-pointer mt-2 me-2 ${
              item.name === activeTab ? "bg-orange-100" : ""
            }`}
            onClick={() => setActiveTab(item.name)}
          >
            {item.image}
            <div
              className={`col-span-3 invisible md:visible  ${
                item.name === activeTab ? "text-orange-600" : ""
              }`}
            >
              {item.name}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};
