import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DragAndDrop } from "../common/DragAndDrop";

export const EditVoucher = () => {
  const params = useParams();
  const { sku } = params;
  const [id, setId] = useState(-1);
  const [brand, setBrand] = useState("");
  const [titleText, setTitleText] = useState("");
  const [description, setDescription] = useState("");
  const [denominations, setDenominations] = useState<number[]>([]);
  const [currency, setCurrency] = useState("");
  const [media, setMedia] = useState<string[]>([]);

  const getAzureImageUrl = (imgUrlArray: string[]) => {
    setMedia(imgUrlArray);
  };

  useEffect(() => {
    const controller = new AbortController();
    if (sku) {
      const payload = {
        sku: sku,
        provider: "Qwikcilver",
      };
      axios
        .get(
          `${process.env.REACT_APP_ADMIN_URL}/api/admin/voucher/getVoucherWithSku`,
          {
            signal: controller.signal,
            params: payload,
          },
        )
        .then(({ data }) => {
          const productData = data.data;
          setId(productData.id);
          setMedia([
            productData.image_1,
            productData.image_2,
            productData.image_3,
            productData.image_4,
            productData.image_5,
            productData.image_6,
          ]);
          setBrand(productData.brandName);
          setTitleText(productData.name);
          setDescription(productData.description);
          setDenominations(productData.denominations);
          setCurrency(productData.currency);
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          toast(error.message);
        });
    }
    return () => {
      controller.abort();
    };
  }, []);

  const update = async () => {
    const payload = {
      image_1: media[0] || null,
      image_2: media[1] || null,
      image_3: media[2] || null,
      image_4: media[3] || null,
      image_5: media[4] || null,
      image_6: media[5] || null,
      brandName: brand,
      name: titleText,
      description: description,
      currency: currency,
    };
    axios
      .post(
        `${process.env.REACT_APP_ADMIN_URL}/api/admin/voucher/${id}`,
        payload,
      )
      .then((res) => {
        toast.success("Voucher has been updated in the database");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="container p-4">
      <div className="flex justify-between items-center">
        <div className="text-[32px]">
          {sku ? "Edit" : "Add"} {titleText}
        </div>
        <div className="gap-1 flex align-middle">
          <Link to="/voucher">
            <button className="w-full rounded-lg px-2 pt-1 text-slate-400">
              Cancel
            </button>
          </Link>
          <button
            className="w-full rounded-lg px-4 pt-1 text-white bg-zinc-600"
            onClick={update}
          >
            Save
          </button>
        </div>
      </div>
      {/* left column */}
      <div className="col-span-7 gap-6">
        <div className="grid gap-6">
          <div className="rounded-lg bg-white shadow-md">
            <div className=" p-6">
              <div className="text-[20px]">Media</div>
              <div className="justify-center">
                <DragAndDrop
                  getAzureImageUrl={getAzureImageUrl}
                  updateImgUrl={media}
                  numOfImages={6}
                />
              </div>
            </div>
            <div className="col-span-7 gap-6">
              <div className="grid gap-6"></div>
            </div>
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div>
                  <p className="text-[16px] font-semibold">Brand</p>
                  <input
                    value={brand}
                    type="text"
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Brand name"
                    onChange={(e) => setBrand(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <p className="text-[16px] font-semibold">Title</p>
                  <input
                    type="text"
                    value={titleText}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Title text"
                    onChange={(e) => setTitleText(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <p className="text-[16px] font-semibold">Description</p>
                  <textarea
                    value={description}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Enter description here"
                    rows={5}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <p className="text-[16px] font-semibold">Denominations</p>
                  <input
                    type="text"
                    value={denominations.toString()}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Denominations"
                    readOnly
                  />
                </div>
                <div className="mt-3">
                  <p className="text-[16px] font-semibold">Currency</p>
                  <input
                    type="text"
                    value={currency}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Currency used"
                    onChange={(e) => setCurrency(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
