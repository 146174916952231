import { createBrowserRouter } from "react-router-dom";
import { AddCollectionPage } from "../component/AddCollectionPage";
import { Main } from "../component/Main";
import { OrderContextProvider } from "../component/OrderPage/OrderContextProvider";
import { OrdersPage } from "../component/OrdersPage";
import { AddDonationPage } from "../component/donation/AddDonationPage";
import { AddProductPage } from "../component/product/AddProductPage";
import { ProductPage, ProductPageTabs } from "../component/product/ProductPage";
import { EditVoucher } from "../component/voucher/EditVoucher";
import { ProductContextProvider } from "../context/Product.context";
import { CorpContextProvider } from "../context/Corp.context";
import { EditCorpPage } from "../component/corp/EditCorpPage";
import { CorpsPage } from "../component/corp/CorpsPage";
import AddCorpPage from "../component/corp/AddCorpPage";
import { AddThemesPage } from "../component/themes/AddThemesPage";

type routesProps = {
  path: string;
  element: JSX.Element;
  children?: routesProps[];
};
const routes: routesProps[] = [
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/products",
        element: <ProductPage redirectTab={ProductPageTabs.Product} />,
      },
      {
        path: "/collections",
        element: <ProductPage redirectTab={ProductPageTabs.Collection} />,
      },
      {
        path: "/categories",
        element: <ProductPage redirectTab={ProductPageTabs.Category} />,
      },
      {
        path: "/voucher",
        element: <ProductPage redirectTab={ProductPageTabs.Voucher} />,
      },
      {
        path: "/donation",
        element: <ProductPage redirectTab={ProductPageTabs.Donation} />,
      },
      {
        path: "/themes",
        element: <ProductPage redirectTab={ProductPageTabs.Themes} />,
      },
      {
        path: "/orders",
        element: (
          <OrderContextProvider>
            <OrdersPage />
          </OrderContextProvider>
        ),
      },
      {
        path: "/collections/add",
        element: <AddCollectionPage title={"Collection"} />,
      },
      {
        path: "/collections/:id",
        element: <AddCollectionPage title={"Collection"} />,
      },
      {
        path: "/themes/add",
        element: <AddThemesPage title={"Themes"} />,
      },
      {
        path: "/themes/:id",
        element: <AddThemesPage title={"Themes"} />,
      },
      {
        path: "/products/:id",
        element: (
          <ProductContextProvider>
            <AddProductPage title={"Product"} />
          </ProductContextProvider>
        ),
      },
      {
        path: "/products/add",
        element: (
          <ProductContextProvider>
            <AddProductPage title={"Product"} />
          </ProductContextProvider>
        ),
      },
      {
        path: "/products/:id",
        element: <AddProductPage title={"Product"} />,
      },
      {
        path: "/products/add",
        element: <AddProductPage title={"Product"} />,
      },

      {
        path: "/voucher/:sku",
        element: <EditVoucher />,
      },
      {
        path: "/donation/:id",
        element: <AddDonationPage />,
      },
      {
        path: "/donation/add",
        element: <AddDonationPage />,
      },
      {
        path: "/corps",
        element: (
          <CorpContextProvider>
            <CorpsPage />
          </CorpContextProvider>
        ),
      },
      {
        path: "/corps/add",
        element: (
          <CorpContextProvider>
            <AddCorpPage />
          </CorpContextProvider>
        ),
      },
      {
        path: "/corps/:id",
        element: (
          <CorpContextProvider>
            <EditCorpPage title={"Edit Corp"} />
          </CorpContextProvider>
        ),
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
