import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Plus } from "../constants/images/icons/Plus.svg";
import { GiftLinksTab } from "./OrderPage/GiftLinksTab";
import { OrderContext } from "./OrderPage/OrderContextProvider";
import { OrdersTab } from "./OrderPage/OrdersTab";
import { StatsPanel } from "./OrderPage/StatsPanel";
import { PageSelection } from "./PageSelection";

enum Tab {
  Products = "Products",
  GiftLinks = "Gift Links",
}

const ordersTabs: { tab: Tab }[] = [
  { tab: Tab.Products },
  { tab: Tab.GiftLinks },
];

export const OrdersPage = () => {
  const [tab, setTab] = useState<string>(Tab.Products);
  const { statsDateRange } = useContext(OrderContext);

  const url = process.env.REACT_APP_ADMIN_URL;

  const [status, setStatus] = useState<{
    giftLinks: number;
    pending: number;
    inTransit: number;
    delivered: number;
  }>({
    giftLinks: 0,
    pending: 0,
    inTransit: 0,
    delivered: 0,
  });

  const getStatusData = useCallback(async () => {
    await axios
      .get(`${url}/orders/status`, {
        params: {
          time: {
            from: statsDateRange.from,
            to: statsDateRange.to,
          },
        },
      })
      .then((resp) => {
        const result = resp.data.data;
        setStatus(result);
      });
  }, [statsDateRange, url]);

  // pagination logic

  useEffect(() => {
    getStatusData();
  }, [getStatusData]);

  const getNestedProperty = (obj: any, path: string) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const convertToCsv = async () => {
    interface DataItem {
      id: number;
      createdAt: Date;
      campaign: {
        id: number;
        name: string;
        campaignBalance: number;
        corp: { organisationName: string };
      };
      orders: {
        quantity: number;
        product: {
          name: string;
          brand: string;
          sellPlatformUrl: string;
          listPrice: number;
          costPrice: number;
        };
      }[];
      firstName: string;
      lastName: string;
      address: string;
      postalCode: string;
      apartment: string;
      phoneNumber: string;
      email: string;
      shippingMethod: string;
      status: { name: string };
      note: string;
    }
    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    const headerToPropertyMap: { [key: string]: string } = {
      "Order ID": "id",
      "Date Added": "createdAt",
      "Campaign ID": "campaign.id",
      "Campaign Name": "campaign.name",
      "Campaign Budget": "campaign.campaignBalance",
      "Corp Name": "campaign.corp.organisationName",
      "Product Brand": "orders.product.brand",
      "Product Name": "orders.product.name",
      "Product Quantity": "orders.quantity",
      "Smilie Price": "orders.product.listPrice",
      "Cost Price": "orders.product.costPrice",
      "Product URL": "orders.product.sellPlatformUrl",
      "First Name": "firstName",
      "Last Name": "lastName",
      "Customer Email": "email",
      "Customer Phone Number": "phoneNumber",
      "Customer Address": "address",
      "Apartment Floor": "apartment",
      "Postal Code": "postalCode",
      "Shipping Method": "shippingMethod",
      Status: "status.name",
      Notes: "note",
    };

    const headersCsv = [
      "Order ID",
      "Date Added",
      "Campaign ID",
      "Campaign Name",
      "Campaign Budget",
      "Corp Name",
      "Product Brand",
      "Product Name",
      "Product Quantity",
      "Smilie Price",
      "Cost Price",
      "Product URL",
      "First Name",
      "Last Name",
      "Customer Email",
      "Customer Phone Number",
      "Customer Address",
      "Apartment Floor",
      "Postal Code",
      "Shipping Method",
      "Status",
      "Notes",
    ];

    let csv = "";
    csv =
      '"' +
      headersCsv.join('"' + columnDelimiter + '"') +
      '"'.toString() +
      lineDelimiter;
    await axios
      .get(`${url}/orders/all`, {
        params: {
          time: { from: new Date(0), to: new Date() },
          pageNumber: 1,
          limit: 10000,
          sort: "",
          sortOrder: "DESC",
        },
      })
      .then((res) => {
        const exportedConsolidatedOrders: DataItem[] = res.data.data.results;

        exportedConsolidatedOrders.forEach((item: DataItem) => {
          let propertyValue = "";
          headersCsv.forEach((header) => {
            if (typeof header === "string") {
              const propertyKey = headerToPropertyMap[header];
              if (propertyKey === "orders.product.brand") {
                let brandColumn: string[] = [];
                item["orders"].map((order) => {
                  brandColumn.push(order.product?.brand ?? "");
                });
                propertyValue = brandColumn.join("\n").toString();
              } else if (propertyKey === "orders.product.name") {
                let nameColumn: string[] = [];
                item["orders"].map((order) => {
                  nameColumn.push(order.product?.name ?? "");
                });
                propertyValue = nameColumn.join("\n").toString();
              } else if (propertyKey === "orders.quantity") {
                let quantityColumn: number[] = [];
                item["orders"].map((order) => {
                  quantityColumn.push(order.quantity ?? "");
                });
                propertyValue = quantityColumn.join("\n").toString();
              } else if (propertyKey === "orders.product.sellPlatformUrl") {
                let platformURLColumn: string[] = [];
                item["orders"].map((order) => {
                  platformURLColumn.push(order.product?.sellPlatformUrl ?? "");
                });
                propertyValue = platformURLColumn.join("\n").toString();
              } else if (propertyKey === "orders.product.listPrice") {
                let listPriceColumn: number[] = [];
                item["orders"].map((order) => {
                  listPriceColumn.push(order.product?.listPrice ?? "");
                });
                propertyValue = listPriceColumn.join("\n").toString();
              } else if (propertyKey === "orders.product.costPrice") {
                let costPriceColumn: number[] = [];
                item["orders"].map((order) => {
                  costPriceColumn.push(order.product?.costPrice ?? "");
                });
                propertyValue = costPriceColumn.join("\n").toString();
              } else if (propertyKey === "shippingMethod") {
                if (item[propertyKey] === "PHYSICAL_PICKUP") {
                  propertyValue = "Pick Up";
                } else if (item[propertyKey] === "PHYSICAL_DELIVER") {
                  propertyValue = "Delivery";
                } else {
                  propertyValue = "NA";
                }
              } else {
                propertyValue =
                  getNestedProperty(item, headerToPropertyMap[header]) || "";
              }
            }
            csv += '"' + propertyValue + '"' + ",";
            propertyValue = "";
          });
          csv += lineDelimiter;
        });
      })
      .catch((error) => {
        toast.error(`Error encountered ${error}`);
      });

    return csv;
  };

  const downloadTemplate = async () => {
    try {
      const data = await convertToCsv();
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "smilie_bulk_consolidated_orders.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(`Error downloading template - ${error}`);
    }
  };

  return (
    <div className="max-h-screen">
      <div className="p-6 gap-6 ">
        <div className="gap-2 grid grid-cols-12">
          <h1 className=" col-span-8 text-3xl">Orders</h1>

          <div className="col-span-4 me-8 grid grid-cols-2 gap-x-2">
            <button
              onClick={() => downloadTemplate()}
              className="col-span-1 bg-orange-500 text-white rounded-lg flex items-center justify-center"
            >
              <Plus className="stroke-white" />
              <span className="ps-2 pt-1">Export Orders</span>
            </button>
          </div>

          <div className="col-span-12">
            <StatsPanel
              giftLinks={status.giftLinks}
              pending={status.pending}
              inTransit={status.inTransit}
              delivered={status.delivered}
            />
          </div>

          <div className="col-span-12">
            <PageSelection
              currentTab={tab}
              change={(page) => setTab(page)}
              tabs={ordersTabs}
            />
          </div>

          {tab === Tab.Products ? (
            <div className="col-span-12">
              <OrdersTab />
            </div>
          ) : (
            <div className="col-span-12">
              <GiftLinksTab />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
