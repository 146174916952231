import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SearchTable } from "../common/SearchTable";
import { TableWrapper } from "../dashboard/TableWrapper";

type propType = {
  title: string;
};

export const AddThemesPage = (props: propType) => {
  const params = useParams();
  const { id } = params;
  const { title } = props;
  const [themeTitle, setThemeTitle] = useState<string>("");
  const [themeIsDeleted, setThemeIsDeleted] = useState(false);

  const [account, setAccount] = useState<number[]>([]);
  const [product, setProduct] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => window.scrollTo(0, 0), []);

  const url = process.env.REACT_APP_ADMIN_URL;
  const navigate = useNavigate();

  const upload = async () => {
    const payload = {
      name: themeTitle,
      products: product,
      corps: account,
    };

    axios
      .post(`${url}/products/themes`, payload)
      .then((res) => {
        toast.success("Themes creation successful");
        navigate(`/themes`);
      })
      .catch((error) => {
        toast.error("Error encountered: ", error.message);
      });
  };

  const update = async () => {
    const payload = {
      id: Number(id),
      name: themeTitle,
      products: product,
      corps: account,
      isDeleted: themeIsDeleted,
    };

    axios
      .patch(`${url}/products/themes/${id}`, payload)
      .then((res) => {
        toast.success("Theme updated successful");
        navigate("/themes");
      })
      .catch((error) => {
        toast.error("Error encountered: ", error.message);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    if (id && !isNaN(Number(id))) {
      axios
        .get(`${url}/products/themes/${Number(id)}`, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          const themesData = data.data.themes;
          setThemeTitle(themesData.name);

          setAccount(themesData.corps.map((corp: { id: number }) => corp.id));

          setProduct(
            themesData.products.map((product: { id: number }) => product.id),
          );
          setThemeIsDeleted(themesData.isDeleted);
          setIsLoading(false);
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          toast.error(error.message);
          setIsLoading(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [id]);

  const handleAccount = (idArr: number[]) => {
    setAccount(idArr);
  };

  const handleProduct = (idArr: number[]) => {
    setProduct(idArr);
  };

  const productTableComponent = TableWrapper(
    `${process.env.REACT_APP_ADMIN_URL}/products`,
    [
      { heading: "BRAND", key: "Product.brand", isSortable: true },
      { heading: "PRODUCT", key: "Product.name", isSortable: true },
      { heading: "SMILIE PRICE", key: "Product.costPrice", isSortable: true },
      { heading: "VISIBILITY", key: "VISIBILITY", isSortable: false },
      { heading: "DATE ADDED", key: "Product.createdAt", isSortable: true },
      { heading: "CATEGORY", key: "productCategory.name", isSortable: true },
    ],
    handleProduct,
    product,
    isLoading,
  );

  return (
    <div className="container p-4">
      <div className="flex justify-between items-center">
        <div className="text-[32px]">
          {id ? "Edit" : "Add"} {title}
        </div>
        <div className="gap-1 flex align-middle">
          <Link to="/themes">
            <button className="w-full rounded-lg px-2 pt-1 text-slate-400">
              Cancel
            </button>
          </Link>
          <button
            className="w-full rounded-lg px-4 pt-1 text-white bg-zinc-600"
            onClick={id ? update : upload}
          >
            Save
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-6 mt-4">
        {/* left column */}
        <div className="col-span-7 ">
          <div className="grid gap-6">
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div>
                  <div className="flex">
                    <p className="text-[16px] font-semibold">Title</p>
                    <span className="italic text-slate-500 text-xs pl-1.5 self-center">
                      required
                    </span>
                  </div>
                  <input
                    type="text"
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Title of Collection"
                    onChange={(e) => setThemeTitle(e.target.value)}
                    value={themeTitle}
                  />
                </div>
              </div>
              <div className="container p-6">
                <div>
                  <div className="flex">
                    <p className="text-[16px] font-semibold">Delete</p>
                  </div>
                  <input
                    type="checkbox"
                    onChange={() => setThemeIsDeleted((prev) => !prev)}
                    checked={themeIsDeleted}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* right column */}
        <div className="col-span-5">
          <div className="grid gap-6">
            <div className="rounded-lg bg-white shadow-md"></div>
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div className="text-xl">Accounts</div>
                <SearchTable
                  apiURL={`${process.env.REACT_APP_ADMIN_URL}/corp`}
                  usage="Account"
                  columnName="email"
                  save={(idArr) => handleAccount(idArr)}
                  selectedValues={account}
                />
              </div>
            </div>
            <div className="rounded-lg bg-white shadow-md"></div>
          </div>
        </div>
      </div>
      <div>{productTableComponent}</div>

      <ToastContainer position="top-right" theme="colored" autoClose={3000} />
    </div>
  );
};
