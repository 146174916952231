import { useContext } from "react";
import images from "../../constants/images";
import { ProductVariant } from "../../constants/types";
import { ProductContext } from "../../context/Product.context";

type PropType = {
  index: number;
};

export const Variant = ({ index }: PropType) => {
  const {
    product,
    addVariant,
    editVariant,
    removeVariant,
    updateVariantOption,
    deleteVariantOption,
    addVariantOption,
  } = useContext(ProductContext);

  const deleteVariant = (index: number) => {
    removeVariant(index);
  };

  const removeVariantValue = (index: number, optionIndex: number) => {
    deleteVariantOption(index, optionIndex);
  };

  const addValue = () => {
    addVariantOption(index);
  };

  const handleNameUpdate = (index: number, value: string) => {
    const updateVariantOption = product.variations?.[index];
    if (updateVariantOption) {
      updateVariantOption.name = value;
    }
    editVariant(index, updateVariantOption as ProductVariant);
  };

  return (
    <>
      <div className="mt-4">
        <span>Variant Value</span>
        <input
          type="text"
          className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-slate-600 w-full focus:border-2 focus:border-orange-600 outline-none"
          placeholder="Variant Value"
          value={product.variations?.[index]?.name ?? ""}
          onChange={(e) => handleNameUpdate(index, e.target.value)}
        />
      </div>
      {product.variations && product.variations[index]?.name && (
        <div className="mt-3">
          <div className="flex justify-between">
            <span>{product.variations[index]?.name}</span>
            <button
              className="text-slate-400"
              onClick={() => deleteVariant(index)}
            >
              Remove variant
            </button>
          </div>

          <div className="mt-1">
            {product.variations[index]?.options?.map((_, optionIndex) => {
              return (
                <div key={optionIndex} className="flex justify-between mt-2">
                  <div className="flex justify-center items-center">
                    <button className="pe-1 items-center">
                      <img src={images.drag} alt="" />
                    </button>
                  </div>

                  <div className="flex w-full">
                    <input
                      type="text"
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 w-full focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="Variant"
                      value={
                        typeof product.variations?.[index] === "object"
                          ? product.variations[index]?.options?.[optionIndex]
                              ?.value ?? ""
                          : ""
                      }
                      onChange={(e) =>
                        updateVariantOption(index, optionIndex, e.target.value)
                      }
                    />
                  </div>

                  <button
                    className="flex items-center ps-2"
                    onClick={() => removeVariantValue(index, optionIndex)}
                  >
                    <img src={images.bin} alt="" />
                  </button>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end mt-1">
            <button className=" text-orange-600" onClick={addValue}>
              Add value
            </button>
          </div>
        </div>
      )}
    </>
  );
};
