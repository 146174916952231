import { useEffect, useRef, useState } from "react";
import images from "../../constants/images";
import { DatePicker } from "../common/DatePicker/DatePicker";
import { Modal } from "../common/Modal";

enum Visibility {
  Live = "live",
  Unlisted = "unlisted",
  Scheduled = "scheduled",
}

export const VisibilityDropdown = ({
  VisibilityComponentProp: { startVisibility, endVisibility },
  saveDates,
}: {
  VisibilityComponentProp: {
    startVisibility: Date;
    endVisibility: Date | null;
  };
  saveDates: (startDate: Date, endDate: Date | null) => void;
}) => {
  const [visibility, setVisibility] = useState<string>(Visibility.Live);
  const [hasDateBeenEdited, setHasDateBeenEdited] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [scheduled, setSchedule] = useState<{
    startDate: Date;
    endDate: Date | null;
  }>({
    startDate: startVisibility,
    endDate: endVisibility,
  });
  const popupRef = useRef(null);

  const handleSaveClick = () => {
    saveDates(scheduled.startDate, scheduled.endDate);
  };

  useEffect(() => {
    setSchedule({ startDate: startVisibility, endDate: endVisibility });
    visibilityStatus(startVisibility, endVisibility);
  }, [startVisibility, endVisibility]);

  const visibilityStatus = (
    startVisibility: Date,
    endVisibility: Date | null,
  ) => {
    let visibilityStatus = Visibility.Unlisted;
    const todayDate = new Date();
    const nullDate = new Date(0);

    if (
      startVisibility <= todayDate &&
      (endVisibility === null ||
        endVisibility.toString() === nullDate.toString())
    ) {
      visibilityStatus = Visibility.Live;
    } else if (
      (endVisibility !== null &&
        startVisibility.toString() !== nullDate.toString() &&
        endVisibility >= todayDate) ||
      (startVisibility > todayDate &&
        (endVisibility === null ||
          endVisibility.toString() === nullDate.toString()))
    ) {
      visibilityStatus = Visibility.Scheduled;
    }
    setVisibility(visibilityStatus);
  };

  const setScheduleWithVisibilityStatus = (visibility: string) => {
    setHasDateBeenEdited(true);
    if (visibility === Visibility.Live) {
      setSchedule({ startDate: new Date(), endDate: null });
    } else if (visibility === Visibility.Unlisted) {
      setSchedule({
        startDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
        endDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !(popupRef.current as HTMLElement).contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleSchedule = (
    startDatePicker: Date,
    endDatePicker: Date | null,
  ) => {
    setHasDateBeenEdited(true);
    setSchedule({ startDate: startDatePicker, endDate: endDatePicker });
  };

  const handleDiscard = () => {
    setHasDateBeenEdited(false);
    setSchedule({ startDate: startVisibility, endDate: endVisibility });
    visibilityStatus(startVisibility, endVisibility);
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        <img
          src={images.visibility[visibility as keyof typeof images.visibility]}
          alt=""
          className="inline pb-1 mr-2"
        />
        <select
          name="visibility"
          className="min-w-32 border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg} ${border-[1px] border-[neutral-400] rounded-lg my-4 focus:border-2 focus:border-orange-600 outline-none"
          value={visibility}
          onChange={(e) => {
            setScheduleWithVisibilityStatus(e.target.value);
            setVisibility(e.target.value);
          }}
        >
          <option value={Visibility.Live}>Live</option>
          <option value={Visibility.Scheduled}>Scheduled</option>
          <option value={Visibility.Unlisted}>Unlisted</option>
        </select>
      </div>
      {visibility === Visibility.Scheduled ? (
        <>
          {" "}
          <div>
            <Modal
              isModalVisible={open}
              children={
                <div
                  className="rounded-lg bg-white shadow-md z-50 w-96"
                  ref={popupRef}
                >
                  <div className="m-4">
                    <DatePicker
                      values={{
                        startDate:
                          scheduled.startDate instanceof Date ||
                          typeof scheduled.startDate === "string"
                            ? new Date(scheduled.startDate)
                            : new Date(),
                        endDate:
                          scheduled.endDate instanceof Date ||
                          typeof scheduled.endDate === "string"
                            ? new Date(scheduled.endDate)
                            : new Date(),
                      }}
                      onChange={handleSchedule}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="text-xs text-slate-500">
            <span>
              <ul>{scheduled.startDate.toDateString()} ~</ul>
              <ul>{scheduled.endDate?.toDateString()}</ul>
            </span>
          </div>
          <button onClick={() => setOpen(true)} className="my-2 self-center">
            <img src={images.calender} />
          </button>
        </>
      ) : (
        ""
      )}
      {hasDateBeenEdited && (
        <div className="flex justify-center">
          <button
            className="bg-orange-400 hover:bg-orange-500 text-white font-bold p-2 rounded text-sm"
            onClick={handleSaveClick}
          >
            Save
          </button>
          <button
            className="bg-white font-bold p-2 rounded border text-sm text-slate-500"
            onClick={handleDiscard}
          >
            Discard
          </button>
        </div>
      )}
    </div>
  );
};
