import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DatePicker } from "../common/DatePicker/DatePicker";
import { DragAndDrop } from "../common/DragAndDrop";
import { SearchTable } from "../common/SearchTable";

enum Visibility {
  Live = "live",
  Scheduled = "scheduled",
  Unlisted = "unlisted",
}

export const AddDonationPage = () => {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  const [images, setImages] = useState({
    pic1: "",
    pic2: "",
    pic3: "",
    pic4: "",
  });
  const [titleText, setTitleText] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [visibility, setVisibility] = useState<string>(Visibility.Live);
  const [schedule, setSchedule] = useState<{
    startDate: Date;
    endDate: Date | null;
  }>({ startDate: new Date(), endDate: null });
  const [collection, setCollection] = useState<number[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    const controller = new AbortController();
    if (id) {
      axios
        .get(`${process.env.REACT_APP_ADMIN_URL}/charity/${id}`, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          const charityData = data.data;
          setImages({
            pic1: charityData.pic1,
            pic2: charityData.pic2,
            pic3: charityData.pic3,
            pic4: charityData.pic4,
          });
          setTitleText(charityData.name);
          setDescription(charityData.description);
          setLink(charityData.link);
          if (charityData.startVisibility && charityData.endVisibility) {
            visibilityStatus(
              new Date(charityData.startVisibility),
              charityData.endVisibility
                ? new Date(charityData.endVisibility)
                : null,
            );
            setSchedule({
              startDate: new Date(charityData.startVisibility),
              endDate: charityData.endVisibility
                ? new Date(charityData.endVisibility)
                : null,
            });
          }
          setCollection(
            charityData.collection.map(
              (collection: { id: any }) => collection.id,
            ),
          );
          setIsFetching(false);
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          toast(error.message);
          setIsFetching(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [id]);

  const add = async () => {
    const payload = {
      pic1: images.pic1,
      pic2: images.pic2,
      pic3: images.pic3,
      pic4: images.pic4,
      name: titleText,
      description: description,
      link: link,
      startVisibility: schedule.startDate,
      endVisibility: schedule.endDate,
      collection: collection,
    };

    axios
      .post(`${process.env.REACT_APP_ADMIN_URL}/charity`, payload)
      .then((res) => {
        toast.success("Donation was created successful");
        navigate("/donation");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const update = async () => {
    const payload = {
      id: id,
      pic1: images.pic1,
      pic2: images.pic2,
      pic3: images.pic3,
      pic4: images.pic4,
      name: titleText,
      description: description,
      link: link,
      startVisibility: schedule.startDate,
      endVisibility: schedule.endDate,
      collection: collection,
    };
    axios
      .put(`${process.env.REACT_APP_ADMIN_URL}/charity/${id}`, payload)
      .then((res) => {
        toast.success("Donation was updated successful");
        navigate("/donation");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const visibilityStatus = (
    startVisibility: Date,
    endVisibility: Date | null,
  ) => {
    let visibilityStatus = Visibility.Unlisted;
    const todayDate = new Date();
    const nullDate = new Date(0);

    if (
      startVisibility <= todayDate &&
      (endVisibility === null ||
        endVisibility.toString() === nullDate.toString())
    ) {
      visibilityStatus = Visibility.Live;
    } else if (
      (endVisibility !== null &&
        startVisibility.toString() !== nullDate.toString() &&
        endVisibility >= todayDate) ||
      (startVisibility > todayDate &&
        (endVisibility === null ||
          endVisibility.toString() === nullDate.toString()))
    ) {
      visibilityStatus = Visibility.Scheduled;
    }
    setVisibility(visibilityStatus);
  };

  const setScheduleWithVisibilityStatus = (visibility: string) => {
    if (visibility === Visibility.Live) {
      setSchedule({ startDate: new Date(), endDate: null });
    } else if (visibility === Visibility.Unlisted) {
      setSchedule({
        startDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
        endDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
      });
    }
  };

  useEffect(() => {
    setScheduleWithVisibilityStatus(visibility);
  }, [visibility]);

  const handleSchedule = (startDate: Date, endDate: Date | null) => {
    setSchedule({ startDate: startDate, endDate: endDate });
  };

  const handleCollection = (idArr: number[]) => {
    setCollection(idArr);
  };

  const getAzureImageUrl = (imgUrlArray: string[]) => {
    setImages({
      pic1: imgUrlArray[0],
      pic2: imgUrlArray[1],
      pic3: imgUrlArray[2],
      pic4: imgUrlArray[3],
    });
  };

  return (
    <>
      <div className="container p-4">
        <div className="flex justify-between items-center">
          <div className="text-[32px]">
            {id ? "Edit" : "Add"} {titleText}
          </div>
          <div className="gap-1 flex align-middle">
            <Link to="/donation">
              <button className="w-full rounded-lg px-2 pt-1 text-slate-400">
                Cancel
              </button>
            </Link>
            <button
              className="w-full rounded-lg px-4 pt-1 text-white bg-zinc-600"
              onClick={id ? update : add}
            >
              Save
            </button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 mt-4">
          {/* left column */}
          <div className="col-span-7 gap-6">
            <div className="grid gap-6">
              <div className="rounded-lg bg-white shadow-md">
                <div className=" p-6">
                  <div className="text-[20px]">Media</div>
                  <div className="justify-center">
                    <DragAndDrop
                      getAzureImageUrl={getAzureImageUrl}
                      updateImgUrl={[
                        images.pic1 || "",
                        images.pic2 || "",
                        images.pic3 || "",
                        images.pic4 || "",
                      ]}
                      numOfImages={4}
                    />
                  </div>
                </div>
              </div>

              <div className="rounded-lg bg-white shadow-md">
                <div className="container p-6">
                  <div>
                    <div className="flex">
                      <p className="text-[16px] font-semibold">Organisation</p>
                      <span className="italic text-slate-500 text-xs pl-1.5 self-center">
                        required
                      </span>
                    </div>
                    <input
                      value={titleText}
                      type="text"
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="Organisation Name"
                      onChange={(e) => setTitleText(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <div className="flex">
                      <p className="text-[16px] font-semibold">Description</p>
                    </div>
                    <textarea
                      value={description}
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="Enter description here"
                      rows={5}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="mt-3">
                    <div className="flex">
                      <p className="text-[16px] font-semibold">Website Link</p>
                    </div>
                    <input
                      type="text"
                      value={link ?? ""}
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="www.example.com"
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </div>

                  <div className="mt-3">
                    <p className="text-[16px] font-semibold">Item Type</p>
                    <select
                      name="productType"
                      value="Donation"
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                      aria-readonly
                    >
                      <option value="Donation">Donation</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* right column */}
          <div className="col-span-5">
            <div className="grid gap-6">
              <div className="rounded-lg bg-white shadow-md">
                <div className="container p-6">
                  <div className="text-[20px]">Donation Visibility</div>
                  <div className="justify-center">
                    <select
                      name="visibility"
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg} ${border-[1px] border-[neutral-400] w-full rounded-lg my-4 focus:border-2 focus:border-orange-600 outline-none"
                      value={visibility}
                      onChange={(e) => setVisibility(e.target.value)}
                    >
                      <option value={Visibility.Live}>Live</option>
                      <option value={Visibility.Scheduled}>Scheduled</option>
                      <option value={Visibility.Unlisted}>Unlisted</option>
                    </select>
                  </div>
                  {visibility === Visibility.Scheduled ? (
                    <div>
                      <DatePicker
                        values={{
                          startDate: schedule.startDate
                            ? schedule.startDate
                            : new Date(),
                          endDate: schedule.endDate
                            ? schedule.endDate
                            : new Date(),
                        }}
                        onChange={handleSchedule}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="rounded-lg bg-white shadow-md">
                <div className="container p-6">
                  <div className="text-[20px]">Collection</div>
                  {!isFetching && (
                    <SearchTable
                      apiURL={`${process.env.REACT_APP_ADMIN_URL}/collections`}
                      usage="Collection"
                      columnName="name"
                      save={handleCollection}
                      selectedValues={collection}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
