import React, { MouseEventHandler } from "react";
import images from "../../constants/images";
import { ReactComponent as Chevron } from "../../constants/images/icons/Chevron.svg";
import { ReactComponent as DoubleChevron } from "../../constants/images/icons/DoubleChevron.svg";
import { ReactComponent as Filter } from "../../constants/images/icons/Filter.svg";
import { Spinner } from "../Spinner/Spinner";
import { Checkbox } from "./Checkbox";

export type TableHeader = {
  key: string;
  heading: string;
  isSortable?: boolean;
};

type TableProps = {
  headers: TableHeader[];
  rows: {
    id: number;
    component: string | JSX.Element | JSX.Element[];
    isDeleted?: boolean;
    unarchive?: (id: number) => void;
  }[];
  pageNumber: number;
  setPageNumber: (p: number) => void;
  setItemsPerPage: (p: number) => void;
  itemsPerPage: number;
  totalPages: number;
  isMultipleRowSelectable?: boolean;
  multipleSelectOnClick?: MouseEventHandler<HTMLDivElement>;
  multipleSelectChecked?: boolean;
  multipleSelectedIds?: number[];
  setSelectProductIds?: React.Dispatch<React.SetStateAction<number[]>>;
  sortBy: string;
  setSortBy: (columnName: string) => void;
  sortOrder: "ASC" | "DESC";
  setSortOrder: (sortOrder: "ASC" | "DESC") => void;
  selectionPanel?: string | JSX.Element | JSX.Element[];
  isLoading?: boolean;
  loadingMessage?: string;
  selectedItemId?: (id: number) => void;
};

const perPageItems = [10, 20, 30, 40, 50];

export const Table = ({
  headers,
  pageNumber,
  rows,
  setItemsPerPage,
  itemsPerPage,
  setPageNumber,
  totalPages,
  isMultipleRowSelectable,
  multipleSelectOnClick,
  multipleSelectChecked = false,
  multipleSelectedIds = [],
  setSelectProductIds,
  selectionPanel,
  isLoading = false,
  loadingMessage = "",
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  selectedItemId,
}: TableProps) => {
  const PaginationList = ({
    currentPageNumber,
  }: {
    currentPageNumber: number;
  }) => {
    const numberOfPages = totalPages;
    return (
      <div className="flex justify-end items-center mt-4">
        {currentPageNumber !== 1 && (
          <>
            <DoubleChevron
              className="cursor-pointer rounded hover:bg-orange-300"
              onClick={() => setPageNumber(1)}
            />
            <Chevron
              className="cursor-pointer rounded hover:bg-orange-300"
              onClick={() => {
                if (pageNumber > 1) setPageNumber(pageNumber - 1);
              }}
            />
          </>
        )}
        <div className="pagination font-leagueSpartan-400 text-[16px] text-[#485056]">
          {[currentPageNumber - 1, currentPageNumber, currentPageNumber + 1]
            .filter((element) => element > 0 && element < numberOfPages + 1)
            .map((iteratingNumber) => (
              <button
                className={`hover:text-orange hover:rounded-lg hover:border hover:border-orange mx-1 py-2 px-3 hover:bg-lightOrange ${
                  pageNumber === iteratingNumber
                    ? "bg-orange-300 text-orange rounded-lg border border-orange"
                    : "bg-transparent"
                }`}
                key={iteratingNumber}
                onClick={() => setPageNumber(iteratingNumber)}
              >
                {iteratingNumber}
              </button>
            ))}
        </div>
        {currentPageNumber !== totalPages && (
          <>
            <Chevron
              className="cursor-pointer rounded hover:bg-orange-300 rotate-180"
              onClick={() => {
                if (pageNumber < numberOfPages) setPageNumber(pageNumber + 1);
              }}
            />

            <DoubleChevron
              className="cursor-pointer rounded hover:bg-orange-300 rotate-180"
              onClick={() => setPageNumber(numberOfPages)}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <table className="w-full table-auto text-center bg-white">
        <thead className="sticky top-0 z-30">
          <tr className="text-zinc-500 bg-gray-200">
            {isMultipleRowSelectable && (
              <th>
                <div className="mt-2">
                  <Checkbox
                    onClick={multipleSelectOnClick}
                    checked={multipleSelectChecked}
                  />
                </div>
              </th>
            )}
            {headers.map((header) => (
              <th
                key={header.key}
                className={`p-4 whitespace-nowrap ${
                  header.isSortable && "hover:bg-neutral-300 cursor-pointer"
                }`}
                onClick={() => {
                  if (!header.isSortable) return;
                  if (sortBy === header.key) {
                    setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
                    return;
                  }
                  setSortBy(header.key);
                }}
              >
                <div className="flex justify-center">
                  <span className="mt-1">{header.heading}</span>
                  <Filter className="my-auto ms-2" />
                </div>
              </th>
            ))}
          </tr>
          {selectionPanel && multipleSelectedIds.length > 0 && (
            <tr>
              <th colSpan={99}>{selectionPanel}</th>
            </tr>
          )}
        </thead>
        <tbody>
          {!isLoading ? (
            rows?.map((row) => (
              <tr
                key={row.id}
                className={`relative border-b-[1px] border-gray-200 hover:bg-orange-100 items-center text-center ${
                  row.isDeleted && "bg-gray-300"
                } ${multipleSelectedIds.includes(row.id) && "bg-orange-200"}`}
                onClick={() => selectedItemId?.(row.id)}
              >
                {row.isDeleted ? (
                  <td className="relative p-0">
                    {row.unarchive && (
                      <button
                        className="size-6"
                        onClick={() => {
                          row.unarchive!(row.id);
                        }}
                      >
                        <img src={images.unarchive} />
                      </button>
                    )}
                  </td>
                ) : (
                  <>
                    {isMultipleRowSelectable && (
                      <td
                        onClick={() => {
                          multipleSelectedIds.includes(row.id)
                            ? setSelectProductIds?.((prev) =>
                                prev?.filter((x) => x !== row.id),
                              )
                            : setSelectProductIds?.((prev) => [
                                ...prev,
                                row.id,
                              ]);
                        }}
                      >
                        <Checkbox
                          key={row.id}
                          checked={multipleSelectedIds.includes(row.id)}
                        />
                      </td>
                    )}
                  </>
                )}
                {row.component}
              </tr>
            ))
          ) : (
            <tr className="w-full text-center mx-auto">
              <td colSpan={99}>
                <div className="py-3">
                  <Spinner />
                  <span>{loadingMessage}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="flex justify-between items-center m-4">
        <div className="flex justify-start items-center mt-2 ">
          <label className="text-neutral-600 text-base">Rows per Page:</label>
          <select
            className="border-2 border-gray-400 rounded-lg mx-2 px-1 py-2"
            onChange={(e) => {
              setItemsPerPage(+e.target.value);
            }}
            value={itemsPerPage}
          >
            {perPageItems.map((val) => (
              <option key={val}>{val}</option>
            ))}
          </select>
        </div>

        <PaginationList currentPageNumber={pageNumber} />
      </div>
    </>
  );
};
