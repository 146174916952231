import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Theme } from "../../constants/types";
import { BreadcrumbContext } from "../../context/Breadcrumb.context";
import { SearchBar } from "../common/SearchBar";
import { Table, TableHeader } from "../common/Table";
import { ThemeRow } from "./ThemeRow";
import { ThemeSelectPanel } from "./ThemeSelectPanel";

const headers: TableHeader[] = [
  { heading: "THEME", key: "Theme.name", isSortable: true },
  { heading: "isHidden", key: "Theme.isHidden", isSortable: true },
  { heading: "isDeleted", key: "Theme.isDeleted", isSortable: true },
];

type TThemesTab = {
  action: (createNew: () => void) => void;
};

export const ThemesTab = ({ action }: TThemesTab) => {
  const { themeTable, setThemeTable } = useContext(BreadcrumbContext);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [selectThemeIds, setSelectThemeIds] = useState<number[]>([]);
  const [isFetchingThemes, setIsFetchingThemes] = useState(true);

  const multipleSelectOnClick = () => {
    if (selectThemeIds.length > 0) {
      setSelectThemeIds([]);
      return;
    }

    setSelectThemeIds((prev) => [...prev, ...themes.map((theme) => theme.id)]);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    setIsFetchingThemes(true);
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/products/themes`, {
        params: {
          limit: themeTable.limit,
          sort: themeTable.sort,
          sortOrder: themeTable.sortOrder,
          pageNumber: themeTable.pageNumber,
          searchInput: themeTable.searchInput,
        },
        signal: controller.signal,
      })
      .then((res) => {
        setThemes(res.data.data.themes);
        setThemeTable({
          ...themeTable,
          totalPages: res.data.data.totalPages,
        });
        setIsFetchingThemes(false);
      })
      .catch(() => setIsFetchingThemes(false));
    return () => controller.abort();
  }, [
    themeTable.limit,
    themeTable.sort,
    themeTable.sortOrder,
    themeTable.pageNumber,
    themeTable.searchInput,
  ]);

  const createNewAction = useCallback(() => {
    navigate("/themes/add");
  }, [navigate]);

  useEffect(() => {
    action(() => createNewAction);
  }, [action, createNewAction]);

  return (
    <div>
      <SearchBar
        onSearch={(value) => {
          setThemeTable({
            ...themeTable,
            searchInput: value,
            pageNumber: 1,
          });
        }}
      />

      <div className="mt-4">
        <Table
          headers={headers}
          rows={themes.map((theme) => ({
            id: theme.id,
            component: <ThemeRow theme={theme} />,
            isDeleted: theme.isDeleted,
          }))}
          pageNumber={themeTable.pageNumber}
          setPageNumber={(p) => {
            setThemeTable({ ...themeTable, pageNumber: p });
          }}
          setItemsPerPage={(p) => {
            setThemeTable({ ...themeTable, limit: p, pageNumber: 1 });
          }}
          itemsPerPage={themeTable.limit}
          totalPages={themeTable.totalPages}
          isMultipleRowSelectable={true}
          multipleSelectOnClick={multipleSelectOnClick}
          multipleSelectChecked={selectThemeIds.length > 0}
          multipleSelectedIds={selectThemeIds}
          setSelectProductIds={setSelectThemeIds}
          selectionPanel={
            <ThemeSelectPanel
              selectedIds={selectThemeIds}
              handleRemoveSelection={() => setSelectThemeIds([])}
            />
          }
          isLoading={isFetchingThemes}
          loadingMessage="Fetching Themes..."
          sortBy={themeTable.sort}
          setSortBy={(column) =>
            setThemeTable({
              ...themeTable,
              sort: column,
            })
          }
          sortOrder={themeTable.sortOrder}
          setSortOrder={(order: "ASC" | "DESC") =>
            setThemeTable({
              ...themeTable,
              sortOrder: order,
            })
          }
        />
      </div>
    </div>
  );
};
