import { useEffect, useState } from "react";
import images from "../../../constants/images";
import { DatePicker } from "../DatePicker/DatePicker";

interface DateAddedFilterProps {
  saveDates: (startDate: Date | null, endDate: Date | null) => void;
  selectedDates: {
    selectedStartDate: Date | null;
    selectedEndDate: Date | null;
  };
}

export const DateAddedFilter: React.FC<DateAddedFilterProps> = ({
  saveDates,
  selectedDates,
}) => {
  const [showBody, setShowBody] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    selectedDates.selectedStartDate || new Date(0),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    selectedDates.selectedEndDate || null,
  );

  const handleDates = (newStartDate: Date, newEndDate: Date | null) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  useEffect(() => {
    saveDates(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <div>
      <div className="flex justify-between border-t-2 border-y-slate-200 py-2">
        <div>DATE ADDED</div>
        <img
          src={showBody ? images.caretUp : images.caretDown}
          onClick={() => setShowBody(!showBody)}
        />
      </div>
      {showBody && (
        <div className="border-t-2 border-y-slate-100 pb-4">
          <DatePicker
            onChange={handleDates}
            values={{ startDate: startDate, endDate: endDate }}
          />
        </div>
      )}
    </div>
  );
};
