import images from "../../constants/images";
import { Copy } from "../common/Copy";

type PropTypes = {
  campaign: any;
  closeModal: () => void;
};

export const CampaignDetail = ({ campaign, closeModal }: PropTypes) => {
  const campaignDetails = [
    {
      title: "Campaign Id",
      value: campaign.id,
      allowCopy: true,
    },
    {
      title: "Campaign Name",
      value: campaign.name,
      allowCopy: true,
    },
    {
      title: "Campaign Budget",
      value: campaign.campaignBalance,
      allowCopy: true,
    },
    {
      title: "Corp Name",
      value: campaign.corp.organisationName,
      allowCopy: true,
    },
  ];
  return (
    <div className="bg-black bg-opacity-60 flex items-center justify-center overflow-scroll fixed top-0 left-0 w-full h-full z-40">
      <div className="bg-white p-8 w-96 rounded-md">
        <div className="flex justify-between">
          <div className="text-xl font-LeagueSpartan">Campaign Details</div>
          <button onClick={closeModal} id={campaign.id}>
            <img src={images.cross} />
          </button>
        </div>

        <div className="mt-8">
          {campaign &&
            campaignDetails &&
            campaignDetails.map((detail, index) => {
              return (
                <div
                  key={index}
                  id={campaign.id}
                  className="flex items-center justify-between mb-4"
                >
                  <div className="flex items-center">
                    <div className="text-left ml-4 text-sm font-LeagueSpartan">
                      <div className="text-slate-500" id="title">
                        {detail.title}
                      </div>
                      <div id="value" className="w-56 overflow-hidden">
                        {detail.value ? detail.value : "-"}
                      </div>
                    </div>
                  </div>
                  <div>{detail.allowCopy && <Copy text={detail.value} />}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
