import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Collection } from "../../constants/types";
import { formatDate } from "../../utils/api";
import { VisibilityDropdown } from "./VisibilityDropdown";

export const CollectionRow = ({ collection }: { collection: Collection }) => {
  const {
    id,
    name,
    description,
    picture,
    corp,
    updatedAt,
    collectionTags,
    startVisibility,
    endVisibility,
  } = collection;

  const update = async (startDate: Date, endDate: Date | null) => {
    const payload = {
      ...collection,
      startVisibility: startDate,
      endVisibility: endDate,
      collectionTags: collectionTags.map((ct) => ct.product.id),
      corp: corp.map((c) => c.corp.id),
    };

    axios
      .put(
        `${process.env.REACT_APP_ADMIN_URL}/collections/${payload.id}`,
        payload,
      )
      .then((res) => {
        toast.success(`Collection ${id}'s date was updated successful`);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <td className="p-2">
        <Link draggable="false" to={`/collections/${id}`}>
          <div className="flex">
            <img
              draggable="false"
              src={picture}
              alt=""
              className="max-w-24 object-contain"
            />
            <div className="max-w-96">
              <div className="p-2 items-center text-left">
                <div className="hover:underline hover:underline-offset-2 ">
                  {name}
                </div>
                <span className="text-slate-500 text-sm">{description}</span>
              </div>
            </div>
          </div>
        </Link>
      </td>
      <td className="p-2 text-left text-nowrap">
        <ul data-tooltip-target="tooltip-default">
          {corp.map((oneCorp) => (
            <li
              key={oneCorp.corp.id.toString()}
              id={oneCorp.corp.id.toString()}
            >
              ({oneCorp.corp.id}) {oneCorp.corp.organisationName}
            </li>
          ))}
        </ul>
      </td>
      <td className="p-2">
        <VisibilityDropdown
          VisibilityComponentProp={{
            startVisibility: new Date(startVisibility),
            endVisibility: endVisibility ? new Date(endVisibility) : null,
          }}
          saveDates={update}
        />
      </td>
      <td className="p-2">{formatDate(updatedAt)}</td>
      <td className="p-2">{collectionTags.length}</td>
    </>
  );
};
