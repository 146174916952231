import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { UserContext } from "../context/UserContextProvider";
import { LoginPage } from "../pages/LoginPage";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";

export const Main = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return <LoginPage />;
  }

  return (
    <div className="min-w-full grid grid-cols-12 bg-white">
      <div className="col-span-12 bg-neutrals-200">
        <Header />
      </div>
      <Sidebar />
      <div className="col-span-10 bg-neutrals-200 mt-0.5 min-h-screen">
        <Outlet />
      </div>
    </div>
  );
};
