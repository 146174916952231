import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Charity } from "../../constants/types";
import { formatDate } from "../../utils/api";
import { VisibilityDropdown } from "./VisibilityDropdown";

export const DonationRow = ({ charity }: { charity: Charity }) => {
  const { id, name, pic1, createdAt, startVisibility, endVisibility } = charity;
  const update = async (startDate: Date, endDate: Date | null) => {
    const payload = {
      ...charity,
      startVisibility: startDate,
      endVisibility: endDate,
    };

    axios
      .put(`${process.env.REACT_APP_ADMIN_URL}/charity/${payload.id}`, payload)
      .then((res) => {
        toast.success(`Charity ${id}'s date was updated successful`);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <>
      <td className="p-2">
        <Link draggable="false" to={`/donation/${id}`}>
          <div className="flex">
            <img
              draggable="false"
              src={pic1}
              alt=""
              className="max-w-24 object-contain"
            />
            <div className="max-w-96">
              <div className="p-2 flex items-center text-left">
                <span className="hover:underline hover:underline-offset-2">
                  {name}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </td>
      <td className="p-2">
        <VisibilityDropdown
          VisibilityComponentProp={{
            startVisibility: new Date(startVisibility),
            endVisibility: endVisibility ? new Date(endVisibility) : null,
          }}
          saveDates={update}
        />
      </td>
      <td className="p-2">{formatDate(createdAt.toString())}</td>
    </>
  );
};
