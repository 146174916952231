import images from "../../constants/images";

type SearchBarProps = {
  onSearch: (value: string) => void;
  className?: string;
};

export const SearchBar = ({ onSearch, className }: SearchBarProps) => {
  return (
    <div
      className={`focus-within:border-orange-300 focus-within:border-2 flex items-center bg-white rounded-lg border border-neutrals-400 ${className}`}
    >
      <img src={images.search} alt="" className="m-3" />
      <input
        type="text"
        placeholder="Search"
        className="px-2 outline-none focus:ring-0 border-none rounded-lg font-leagueSpartan-300 h-full w-full text-lg"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearch(e.currentTarget.value);
          }
        }}
      />
    </div>
  );
};
