import { ReactComponent as Delivered } from "../../constants/images/icons/Delivered.svg";
import { ReactComponent as GiftBorder } from "../../constants/images/icons/GiftBorder.svg";
import { ReactComponent as Pending } from "../../constants/images/icons/Pending.svg";
import { ReactComponent as Transit } from "../../constants/images/icons/Transit.svg";
import { DashboardDateSelect } from "../common/DashboardDateSelect";

type StatsPanelProps = {
  giftLinks: number;
  pending: number;
  inTransit: number;
  delivered: number;
};

export const StatsPanel = ({
  giftLinks,
  pending,
  inTransit,
  delivered,
}: StatsPanelProps) => {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-4">
        <DashboardDateSelect />
      </div>
      <div className="col-span-12 grid grid-cols-4 gap-6 mt-4">
        <div className=" border-l-4 border-orange-500 p-4 bg-white rounded-sm">
          <div className="">
            <div className="flex text-sm">
              <span className="flex items-center gap-5">
                Gift Links Sent <GiftBorder className="h-8" />
              </span>
            </div>
            <div className="flex justify-between col-span-4">
              <h1 className="text-4xl">{giftLinks}</h1>
            </div>
          </div>
        </div>
        <div className="border-l-4 border-cyan-600 p-4 bg-white rounded-sm">
          <div className="">
            <div className="flex items-center gap-5 text-sm">
              Pending Procurement <Pending className="h-8" />
            </div>
            <div className="font-leagueSpartan-500 text-3xl">
              <div className="flex justify-between">
                <h1 className="text-4xl">{pending}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-flow-col border-l-4 border-yellow-400 p-4 bg-white rounded-sm">
          <div className="">
            <div className="flex items-center gap-5 text-sm">
              In Transit <Transit className="h-8" />
            </div>
            <div className="font-leagueSpartan-500 text-3xl">
              <div className="flex justify-between">
                <h1 className="text-4xl">{inTransit}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-flow-col border-l-4 border-purple-600 p-4 bg-white rounded-sm">
          <div className="">
            <div className="flex items-center gap-5 text-sm">
              Delivered <Delivered className="h-8" />
            </div>
            <div className="font-leagueSpartan-500 text-3xl">
              <div className="flex justify-between">
                <h1 className="text-4xl">{delivered}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
