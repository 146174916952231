import { createContext, useState } from "react";

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

export const OrderContext = createContext<{
  filters: {
    time: {
      from: Date;
      to: Date;
    };
    q: string;
    limit: number;
    pageNumber: number;
    mode: string[];
    status: string[];
    campaigns: number[];
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      time: {
        from: Date;
        to: Date;
      };
      q: string;
      limit: number;
      pageNumber: number;
      mode: string[];
      status: string[];
      campaigns: number[];
    }>
  >;
  statsDateRange: {
    from: Date;
    to: Date;
  };
  setStatsDateRange: React.Dispatch<
    React.SetStateAction<{
      from: Date;
      to: Date;
    }>
  >;
  campaign: string;
  setCampaign: React.Dispatch<React.SetStateAction<string>>;
}>({
  filters: {
    time: {
      from: new Date(0),
      to: new Date(),
    },
    q: "",
    limit: 10,
    pageNumber: 1,
    mode: [],
    status: [],
    campaigns: [],
  },
  setFilters: () => {},
  statsDateRange: {
    from: new Date(new Date().getTime() - 2592000000),
    to: new Date(),
  },
  setStatsDateRange: () => {},
  campaign: "",
  setCampaign: () => {},
});

export const OrderContextProvider = ({ children }: Props) => {
  const [statsDateRange, setStatsDateRange] = useState({
    from: new Date(new Date().getTime() - 2592000000), //default time range is 30 days
    to: new Date(),
  });

  const [filters, setFilters] = useState<{
    time: {
      from: Date;
      to: Date;
    };
    q: string;
    limit: number;
    pageNumber: number;
    mode: string[];
    status: string[];
    campaigns: number[];
  }>({
    time: {
      from: new Date(0),
      to: new Date(),
    },
    q: "",
    limit: 10,
    pageNumber: 1,
    mode: [],
    status: [],
    campaigns: [],
  });

  const value = {
    filters,
    setFilters,
    statsDateRange,
    setStatsDateRange,
    campaign: "",
    setCampaign: () => {},
  };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};
