import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { AppContextProvider } from "./context/AppContextProvider";
import { BreadcrumbContextProvider } from "./context/Breadcrumb.context";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <BreadcrumbContextProvider>
        <App />
      </BreadcrumbContextProvider>
    </AppContextProvider>
  </React.StrictMode>,
);
