import { ReactNode, useState } from "react";

type TPopUp = {
  info: ReactNode;
  onClick: () => void;
  truncatedInfo: string;
};

export const HoverPopUp = ({ info, onClick, truncatedInfo }: TPopUp) => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  return (
    <td
      className="cursor-pointer"
      onMouseEnter={() => setPopupVisible(true)}
      onMouseLeave={() => setPopupVisible(false)}
    >
      {isPopupVisible ? (
        <div
          className="mx-4 flex overflow-hidden w-40 top-0 left-0 p-2 bg-white border border-gray-200 rounded shadow text-slate-500"
          onClick={onClick}
        >
          {info ? info : "NA"}
        </div>
      ) : (
        <div className="w-24">{truncatedInfo}</div>
      )}
    </td>
  );
};
