import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Corp } from "../../constants/types";
import { SearchBar } from "../common/SearchBar";
import { Table, TableHeader } from "../common/Table";
import { CorpsRow } from "./CorpsRow";

const headers: TableHeader[] = [
  { heading: "ID", key: "id", isSortable: true },
  { heading: "Organisation", key: "organisationName", isSortable: true },
  { heading: "Email", key: "email", isSortable: true },
  { heading: "Phone Number", key: "phoneNumber", isSortable: true },
  { heading: "Full Name", key: "fullName", isSortable: true },
  { heading: "Referral ID", key: "referralId", isSortable: true },
  { heading: "Gov ID", key: "govId", isSortable: true },
  { heading: "Tax ID", key: "taxId", isSortable: true },
  { heading: "Tax Number", key: "taxNumber", isSortable: true },
];
type CacheEntry = {
  cachedCorps: any[];
  cachedTotalPages: number;
};

type Cache = {
  [key: string]: CacheEntry;
};
export const CorpsPage = () => {
  const navigate = useNavigate();
  const [corps, setCorps] = useState<Corp[]>([]);
  const [isFetchingCorps, setIsFetchingCorps] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [cache, setCache] = useState<Cache>({});

  //cache search results to reduce overall api calls
  useEffect(() => {
    const cacheKey = `${searchInput}-${pageNumber}-${sortBy}-${sortOrder}`;
    if (cache[cacheKey]) {
      const { cachedCorps, cachedTotalPages } = cache[cacheKey];
      setCorps(cachedCorps);
      setTotalPages(cachedTotalPages);
      setIsFetchingCorps(false);
    } else {
      const controller = new AbortController();
      setIsFetchingCorps(true);
      axios
        .get(`${process.env.REACT_APP_ADMIN_URL}/corp`, {
          params: {
            limit: itemsPerPage,
            sort: sortBy,
            sortOrder,
            pageNumber,
            searchInput,
          },
          signal: controller.signal,
        })
        .then((res) => {
          setCorps(res.data.data.results);
          setTotalPages(res.data.data.totalPages);
          setIsFetchingCorps(false);
          setCache((prevCache) => ({
            ...prevCache,
            [cacheKey]: {
              cachedCorps: res.data.data.results,
              cachedTotalPages: res.data.data.totalPages,
            },
          }));
        })
        .catch(() => setIsFetchingCorps(false));
      return () => controller.abort();
    }
  }, [itemsPerPage, pageNumber, searchInput, sortBy, sortOrder, cache]);

  const handleAddCorp = () => {
    navigate("/corps/add");
  };

  const createNewAction = useCallback(() => {
    navigate("/corps/add");
  }, [navigate]);

  return (
    <div className="bg-slate-50 min-h-screen p-6">
      <div className="flex justify-between align-middle mb-4">
        <h1 className="text-3xl">Corps Management</h1>
        <button
          className="bg-orange-500 text-white rounded-lg px-4 py-3"
          onClick={handleAddCorp}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span className="px-2">New Corp</span>
        </button>
      </div>
      <SearchBar
        onSearch={(value) => {
          setSearchInput(value);
          setPageNumber(1);
        }}
      />
      <div className="mt-4">
        <Table
          headers={headers}
          rows={corps.map((corp) => ({
            id: corp.id,
            component: <CorpsRow corp={corp} pageNumber={pageNumber} />,
          }))}
          pageNumber={pageNumber}
          setPageNumber={(p) => setPageNumber(p)}
          setItemsPerPage={(p) => setItemsPerPage(p)}
          itemsPerPage={itemsPerPage}
          totalPages={totalPages}
          isLoading={isFetchingCorps}
          loadingMessage="Fetching corps..."
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      </div>
    </div>
  );
};
