import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ProductContext } from "../../context/Product.context";
import { DatePicker } from "../common/DatePicker/DatePicker";
import { DragAndDrop } from "../common/DragAndDrop";
import { SearchTable } from "../common/SearchTable";
import { DeliveryField } from "./DeliveryField";
import { PickUpField } from "./PickUpField";
import { VariantsField } from "./VariantsField";

type propType = {
  title: string;
};

enum Visibility {
  Live = "live",
  Scheduled = "scheduled",
  Unlisted = "unlisted",
}

enum ProductType {
  PHYSICAL = "Physical",
  DIGITAL = "Digital",
}

export const AddProductPage = (props: propType) => {
  const { title } = props;

  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const location = useLocation();
  const { product, setProduct, setSchedule, setAzureImageUrl } =
    useContext(ProductContext);

  const [profit, setProfit] = useState(0);
  const [categoryTags, setCategoryTags] = useState<number[]>([]);
  const [collectionTags, setCollectionTags] = useState<number[]>([]);
  const [visibility, setVisibility] = useState<string>(Visibility.Live);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const fromPage = location.state?.fromPage || 1;

  useEffect(() => {
    setScheduleWithVisibilityStatus(visibility);
  }, [visibility]);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    const controller = new AbortController();
    if (id) {
      axios
        .get(`${process.env.REACT_APP_ADMIN_URL}/products/${id}`, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          const productData = data.data;
          setProduct(productData);
          setCategoryTags(
            productData.categoryTags.map(
              (categoryTag: { category: { id: any } }) =>
                categoryTag.category.id,
            ),
          );
          setCollectionTags(
            productData.collectionTags.map(
              (collectionTag: { collection: { id: any } }) =>
                collectionTag.collection.id,
            ),
          );
          visibilityStatus(
            new Date(productData.startVisibility),
            productData.endVisibility
              ? new Date(productData.endVisibility)
              : null,
          );
          setIsFetching(false);
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          toast(error.message);
          setIsFetching(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [id, setProduct]);

  const getAzureImageUrl = (imgUrlArray: string[]) => {
    setAzureImageUrl(imgUrlArray);
  };

  const handleSchedule = (startDate: Date, endDate: Date | null) => {
    setSchedule(startDate, endDate);
  };

  const handleCategory = (idArr: number[]) => {
    setCategoryTags(idArr);
  };

  const handleCollection = (idArr: number[]) => {
    setCollectionTags(idArr);
  };

  useEffect(() => {
    const smiliePrice = product.listPrice ? product.listPrice : 0;
    const costPrice = product.costPrice ? product.costPrice : 0;
    let newProfit = smiliePrice - costPrice;
    setProfit(newProfit);
  }, [product.listPrice, product.costPrice]);

  const add = async () => {
    const payload = {
      ...product,
      categoryTags: categoryTags,
      collectionTags: collectionTags,
    };

    axios
      .post(`${process.env.REACT_APP_ADMIN_URL}/products`, payload)
      .then((res) => {
        toast.success("Product upload successful");
        navigate("/products", { state: { page: fromPage } });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const update = async () => {
    const payload = {
      ...product,
      categoryTags: categoryTags,
      collectionTags: collectionTags,
    };

    axios
      .put(`${process.env.REACT_APP_ADMIN_URL}/products/${payload.id}`, payload)
      .then((res) => {
        toast.success("Product update successful");
        navigate("/products", { state: { page: fromPage } });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const visibilityStatus = (
    startVisibility: Date,
    endVisibility: Date | null,
  ) => {
    let visibilityStatus = Visibility.Unlisted;
    const todayDate = new Date();
    const nullDate = new Date(0);
    if (
      startVisibility <= todayDate &&
      (endVisibility === null ||
        endVisibility.toString() === nullDate.toString())
    ) {
      visibilityStatus = Visibility.Live;
    } else if (
      (endVisibility !== null &&
        startVisibility.toString() !== nullDate.toString() &&
        endVisibility >= todayDate) ||
      (startVisibility > todayDate &&
        (endVisibility === null ||
          endVisibility.toString() === nullDate.toString()))
    ) {
      visibilityStatus = Visibility.Scheduled;
    }
    setVisibility(visibilityStatus);
  };

  const setScheduleWithVisibilityStatus = (visibility: string) => {
    if (visibility === Visibility.Live) {
      setSchedule(new Date(), null);
    } else if (visibility === Visibility.Unlisted) {
      setSchedule(
        new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
        new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
      );
    }
  };

  return (
    <div className="container p-4">
      <div className="flex justify-between items-center">
        <div className="text-[32px]">
          {id ? "Edit" : "Add"} {title}
        </div>
        <div className="gap-1 flex align-middle">
          <button
            className="w-full rounded-lg px-2 pt-1 text-slate-400"
            onClick={() => navigate("/products", { state: { page: fromPage } })}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-lg px-4 pt-1 text-white bg-zinc-600"
            onClick={id ? update : add}
          >
            Save
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-6 mt-4">
        {/* left column */}
        <div className="col-span-7 gap-6">
          <div className="grid gap-6">
            <div className="rounded-lg bg-white shadow-md">
              <div className=" p-6">
                <div className="text-[20px]">Media</div>
                <div className="justify-center">
                  <DragAndDrop
                    getAzureImageUrl={getAzureImageUrl}
                    updateImgUrl={[
                      product.image_1 || "",
                      product.image_2 || "",
                      product.image_3 || "",
                      product.image_4 || "",
                      product.image_5 || "",
                      product.image_6 || "",
                    ]}
                    numOfImages={6}
                  />
                </div>
              </div>
            </div>

            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div>
                  <div className="flex">
                    <p className="text-[16px] font-semibold">Brand</p>
                    <span className="italic text-slate-500 text-xs pl-1.5 self-center">
                      required
                    </span>
                  </div>
                  <input
                    value={product.brand}
                    type="text"
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Title text"
                    onChange={(e) =>
                      setProduct((product) => ({
                        ...product,
                        brand: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="mt-3">
                  <div className="flex">
                    <p className="text-[16px] font-semibold">Title</p>
                    <span className="italic text-slate-500 text-xs pl-1.5 self-center">
                      required
                    </span>
                  </div>
                  <input
                    type="text"
                    value={product.name}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Title text"
                    onChange={(e) =>
                      setProduct((product) => ({
                        ...product,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="mt-3">
                  <div className="flex">
                    <p className="text-[16px] font-semibold">Description</p>
                    <span className="italic text-slate-500 text-xs pl-1.5 self-center">
                      required
                    </span>
                  </div>
                  <textarea
                    value={product.description}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="Enter description here"
                    rows={5}
                    onChange={(e) =>
                      setProduct((product) => ({
                        ...product,
                        description: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="mt-3">
                  <div className="flex">
                    <p className="text-[16px] font-semibold">Platform URL</p>
                  </div>
                  <input
                    type="text"
                    value={product.sellPlatformUrl ?? ""}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    placeholder="www.example.com"
                    onChange={(e) =>
                      setProduct((product) => ({
                        ...product,
                        sellPlatformUrl:
                          e.target.value === "" ? null : e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="mt-3">
                  <p className="text-[16px] font-semibold">Item Type</p>
                  <select
                    name="productType"
                    value={product.productType}
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
                    onChange={(e) =>
                      setProduct((product) => ({
                        ...product,
                        productType: e.target.value as ProductType,
                      }))
                    }
                  >
                    <option value={ProductType.PHYSICAL}>Physical</option>
                    <option value={ProductType.DIGITAL}>Digital</option>
                  </select>
                </div>
              </div>
            </div>

            <VariantsField />

            <div className={"rounded-lg bg-white shadow-md"}>
              <div className="container p-6">
                <span className="text-[20px] font-semibold">Quantity</span>
                <div className="flex justify-between items-center">
                  <span>SKU (Stock Keeping Unit)</span>
                  <div>
                    <input
                      type="text"
                      value={product.sku}
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="SKU"
                      name="SKU"
                      onChange={(e) =>
                        setProduct((product) => ({
                          ...product,
                          sku: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <span>Quantity Limitations</span>
                  <div>
                    <input
                      type="number"
                      value={product.quantityLimitation}
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="0"
                      name="quantityLimit"
                      onChange={(e) =>
                        setProduct((product) => ({
                          ...product,
                          quantityLimitation: Number(e.target.value),
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {product.productType !== "Digital" ? (
              <div className="rounded-lg bg-white shadow-md">
                <div className="container p-6">
                  <div className="text-[20px]">Pickup/Delivery</div>

                  <PickUpField />

                  <DeliveryField />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div className="text-[20px]">Pricing</div>
                <div className="flex justify-between items-center">
                  <span className="font-thin">Smilie Price</span>
                  <div>
                    <input
                      type="number"
                      value={product.listPrice}
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="$0.00"
                      name="smiliePrice"
                      onChange={(e) =>
                        setProduct((product) => ({
                          ...product,
                          listPrice: Number(e.target.value),
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-thin">Cost Price</span>
                  <div>
                    <input
                      type="number"
                      value={product.costPrice}
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 mt-2 focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="$0.00"
                      name="costPrice"
                      onChange={(e) =>
                        setProduct((product) => ({
                          ...product,
                          costPrice: Number(e.target.value),
                        }))
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-thin">Retail Price</span>
                  <div>
                    <input
                      type="number"
                      value={product.retailPrice}
                      className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg text-right text-slate-600 mt-2 focus:border-2 focus:border-orange-600 outline-none"
                      placeholder="$0.00"
                      name="retailPrice"
                      onChange={(e) =>
                        setProduct((product) => ({
                          ...product,
                          retailPrice: Number(e.target.value),
                        }))
                      }
                      required
                    />
                  </div>
                </div>
                <div className="border-b-[1px] my-4"></div>
                <div className="flex justify-between items-center">
                  <span>Profit</span>
                  <div className="text-[30px] font-bold text-slate-400">
                    ${profit.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* right column */}
        <div className="col-span-5">
          <div className="grid gap-6">
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div className="text-[20px]">Product Visibility</div>
                <div className="justify-center">
                  <select
                    name="visibility"
                    className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg} ${border-[1px] border-[neutral-400] w-full rounded-lg my-4 focus:border-2 focus:border-orange-600 outline-none"
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                  >
                    <option value={Visibility.Live}>Live</option>
                    <option value={Visibility.Scheduled}>Scheduled</option>
                    <option value={Visibility.Unlisted}>Unlisted</option>
                  </select>
                </div>
                {visibility === Visibility.Scheduled ? (
                  <div>
                    <DatePicker
                      values={{
                        startDate:
                          product.startVisibility instanceof Date ||
                          typeof product.startVisibility === "string"
                            ? new Date(product.startVisibility)
                            : new Date(),
                        endDate:
                          product.endVisibility instanceof Date ||
                          typeof product.endVisibility === "string"
                            ? new Date(product.endVisibility)
                            : new Date(),
                      }}
                      onChange={handleSchedule}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div className="text-[20px]">Collection</div>
                {!isFetching && (
                  <SearchTable
                    apiURL={`${process.env.REACT_APP_ADMIN_URL}/collections`}
                    usage="Collection"
                    columnName="name"
                    save={handleCollection}
                    selectedValues={collectionTags}
                  />
                )}
              </div>
            </div>
            <div className="rounded-lg bg-white shadow-md">
              <div className="container p-6">
                <div className="text-[20px]">Product Organisation</div>
                {!isFetching && (
                  <SearchTable
                    apiURL={`${process.env.REACT_APP_ADMIN_URL}/categories`}
                    usage="Category"
                    columnName="name"
                    save={(idArr) => handleCategory(idArr)}
                    selectedValues={categoryTags}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
