import React from "react";

interface FilterProps {
  filterComponents: JSX.Element[];
  onClick: () => void;
}

export const FilterProp: React.FC<FilterProps> = ({
  filterComponents,
  onClick,
}) => {
  return (
    <div className="bg-white w-80 p-2 absolute rounded-lg right-24 top-88 z-50 border-2 border-slate-200">
      {filterComponents?.map((filterComponent, index) => (
        <React.Fragment key={index}>{filterComponent}</React.Fragment>
      ))}
      <div>
        <button
          onClick={onClick}
          className="px-4 py-2 bg-orange-500 rounded-lg text-white"
        >
          Filter
        </button>
      </div>
    </div>
  );
};
