type ModalProps = {
  children: JSX.Element;
  isModalVisible: boolean;
};

export const Modal = ({ children, isModalVisible }: ModalProps) => {
  return (
    <>
      {isModalVisible && (
        <div className="bg-black bg-opacity-60 flex items-center justify-center overflow-scroll fixed top-0 left-0 w-full h-full z-40">
          {children}
        </div>
      )}
    </>
  );
};
