import { useState } from "react";

export const Copy = (text: any) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const timeout = () => {
    setTimeout(function () {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="flex">
      {isCopied && (
        <div className="text-xs text-left mr-2 text-slate-600 relative left-0 z-50">
          Copied!
        </div>
      )}
      <div
        onClick={() => {
          copyToClipboard(text.text);
          setIsCopied(true);
          timeout();
        }}
        onBlur={() => setIsCopied(false)}
        className="hover:text-orange-500 text-gray-300"
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="currentColor"
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 13.8333H3.33333C2.4496 13.8323 1.60237 13.4807 0.97748 12.8559C0.352588 12.231 0.00105857 11.3837 0 10.5L0 3.83333C0.00105857 2.9496 0.352588 2.10237 0.97748 1.47748C1.60237 0.852588 2.4496 0.501059 3.33333 0.5L10 0.5C10.8837 0.501059 11.731 0.852588 12.3559 1.47748C12.9807 2.10237 13.3323 2.9496 13.3333 3.83333V10.5C13.3323 11.3837 12.9807 12.231 12.3559 12.8559C11.731 13.4807 10.8837 13.8323 10 13.8333ZM16 13.1667V4.5C16 4.32319 15.9298 4.15362 15.8047 4.0286C15.6797 3.90357 15.5101 3.83333 15.3333 3.83333C15.1565 3.83333 14.987 3.90357 14.8619 4.0286C14.7369 4.15362 14.6667 4.32319 14.6667 4.5V13.1667C14.6667 13.6971 14.456 14.2058 14.0809 14.5809C13.7058 14.956 13.1971 15.1667 12.6667 15.1667H4C3.82319 15.1667 3.65362 15.2369 3.5286 15.3619C3.40357 15.487 3.33333 15.6565 3.33333 15.8333C3.33333 16.0101 3.40357 16.1797 3.5286 16.3047C3.65362 16.4298 3.82319 16.5 4 16.5H12.6667C13.5504 16.4989 14.3976 16.1474 15.0225 15.5225C15.6474 14.8976 15.9989 14.0504 16 13.1667Z" />
        </svg>
      </div>
    </div>
  );
};
