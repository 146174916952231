import { useEffect, useState } from "react";
import { Checkbox } from "../common/Checkbox";
import { SearchTable } from "../common/SearchTable";

enum StatusOptions {
  Activated = "Activated",
  Deactivated = "Deactivated",
  Opened = "Opened",
}

export type TGiftLinksFilters = {
  campaigns: number[];
  status: string[];
};

type TGiftLinksFilterWindow = {
  values: TGiftLinksFilters;
  onFiltersChange: ({ campaigns, status }: TGiftLinksFilters) => void;
};

export const GiftLinksFilterWindow = ({
  values,
  onFiltersChange,
}: TGiftLinksFilterWindow) => {
  const url = process.env.REACT_APP_ADMIN_URL;
  const [filters, setFilters] = useState<TGiftLinksFilters>(values);

  const handleStatusSelect = (option: string) => {
    setFilters((prev) => ({
      ...prev,
      status: prev.status.includes(option)
        ? prev.status.filter((item) => item !== option)
        : [...prev.status, option],
    }));
  };

  const handleCampaignSelect = (idArr: number[]) => {
    setFilters((prev) => ({
      ...prev,
      campaigns: idArr,
    }));
  };

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters, onFiltersChange]);

  return (
    <div className="container p-4">
      <h3 className="text-xl">Campaign</h3>
      <SearchTable
        apiURL={`${url}/campaigns/`}
        usage="Campaign"
        save={(idArr) => handleCampaignSelect(idArr)}
        columnName="name"
        selectedValues={filters.campaigns}
      />

      <h3 className="text-xl pt-4 mt-4 border-t-[1px]">Status</h3>
      <div>
        <div className="flex mt-2">
          {/* Activated */}
          <Checkbox
            onClick={() => {
              handleStatusSelect(StatusOptions.Activated);
            }}
            checked={filters.status.includes(StatusOptions.Activated)}
          />
          <span className="ps-2">{StatusOptions.Activated}</span>
        </div>

        {/* Deactivated */}
        <div className="flex mt-2">
          <Checkbox
            onClick={() => {
              handleStatusSelect(StatusOptions.Deactivated);
            }}
            checked={filters.status.includes(StatusOptions.Deactivated)}
          />
          <span className="ps-2">{StatusOptions.Deactivated}</span>
        </div>

        <div className="flex mt-2">
          <Checkbox
            onClick={() => {
              handleStatusSelect(StatusOptions.Opened);
            }}
            checked={filters.status.includes(StatusOptions.Opened)}
          />
          <span className="ps-2">{StatusOptions.Opened}</span>
        </div>
      </div>
    </div>
  );
};
