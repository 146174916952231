import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { capitalize } from "../../utils/api";
import { Checkbox } from "../common/Checkbox";
import { SearchTable } from "../common/SearchTable";

export enum OrderStatusOptions {
  Pending = "Pending",
  Procured = "Procured",
  Completed = "Completed",
}

export type TOrderFilters = {
  mode?: string[];
  status: string[];
  campaigns: number[];
};

type TOrdersFilterWindow = {
  values: TOrderFilters;
  onFiltersChange: ({ mode, status, campaigns }: TOrderFilters) => void;
};

export const OrdersFilterWindow = ({
  values,
  onFiltersChange,
}: TOrdersFilterWindow) => {
  const [modeOptions, setModeOptions] = useState<any[]>([]);
  const [filters, setFilters] = useState<TOrderFilters>(values);
  const url = process.env.REACT_APP_ADMIN_URL;

  const handleModeSelect = (mode: string) => {
    setFilters((prev) => ({
      ...prev,
      mode: prev.mode?.includes(mode)
        ? prev.mode.filter((item) => item !== mode)
        : [mode],
    }));
  };

  const handleStatusSelect = (status: string) => {
    setFilters((prev) => ({
      ...prev,
      status: prev.status.includes(status)
        ? prev.status.filter((item) => item !== status)
        : [...prev.status, status],
    }));
  };

  const handleCampaignSelect = (idArr: number[]) => {
    setFilters((prev) => ({
      ...prev,
      campaigns: idArr,
    }));
  };

  const getModeOptions = useCallback(() => {
    axios.get(`${url}/orders/mode`).then((resp) => {
      setModeOptions(resp.data.data);
    });
  }, [url]);

  useEffect(() => {
    getModeOptions();
  }, [getModeOptions]);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters, onFiltersChange]);

  return (
    <div className="container p-4 w-84">
      <h3 className="text-xl">Campaign</h3>
      <SearchTable
        apiURL={`${url}/campaigns/`}
        usage="Campaign"
        save={(idArr) => handleCampaignSelect(idArr)}
        columnName="name"
        selectedValues={filters.campaigns}
      />

      <h3 className="text-xl pt-4 mt-4 border-t-[1px]">Status</h3>
      <div>
        {Object.values(OrderStatusOptions).map((item) => (
          <div key={item} className="flex mt-2">
            <Checkbox
              checked={filters.status.includes(item)}
              onClick={() => {
                handleStatusSelect(item);
              }}
            />
            <span className="ps-2">{item}</span>
          </div>
        ))}
      </div>

      <h3 className="text-xl pt-4 mt-4 border-t-[1px]">Mode</h3>
      <div>
        {modeOptions.map((item) => {
          return (
            <div key={item.id} className="flex mt-2">
              <Checkbox
                checked={filters.mode?.includes(item.name) || false}
                onClick={() => {
                  handleModeSelect(item.name);
                }}
              />
              <span className="ps-2">
                {capitalize(item.name.split("_").join(" "))}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
