import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as Plus } from "../../constants/images/icons/Plus.svg";
import { Checkbox } from "./Checkbox";
import { SearchBar } from "./SearchBar";

type SearchTableProps = {
  apiURL: string;
  usage: string;
  save: (idArr: number[]) => void;
  columnName: string;
  selectedValues?: number[];
};

export const SearchTable = ({
  apiURL,
  usage,
  save,
  columnName,
  selectedValues = [],
}: SearchTableProps) => {
  const [data, setData] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>(selectedValues);
  const [addNew, setAddNew] = useState(false);
  const [createValue, setCreateValue] = useState<string>("");

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const LIMIT = 10;

  const handleSearch = (value: string) => {
    setPageNumber(1);
    setSearchInput(value);
  };

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNumber((prev) => prev + 1);
      }
    }),
  );

  const handleSelect = (id: number) => {
    const item = selectedItems.find((item) => item === id);
    let newItems: number[] = [];
    if (item) {
      newItems = selectedItems.filter((item) => item !== id);
    } else {
      const newItem = data.find((row) => row.id === id);
      if (!newItem) {
        return;
      }
      newItems = [...selectedItems, newItem.id];
    }
    save(newItems);
    setSelectedItems(newItems);
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(apiURL, {
        params: {
          limit: LIMIT,
          pageNumber,
          searchInput,
          selectedValues,
        },
      });
      if (pageNumber === 1) {
        setData(response.data.data.results);
      } else {
        setData((prevData) => [...prevData, ...response.data.data.results]);
      }
      setTotalPages(response.data.data.totalPages);
    } catch (error) {
      toast.error("Error fetching data");
    }
    setIsLoading(false);
  }, [pageNumber, searchInput, apiURL]);

  useEffect(() => {
    setSelectedItems(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    setIsLoading(false);
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber <= totalPages || pageNumber === 1) {
      getData();
    }
  }, [pageNumber, getData, totalPages]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const handleAddNew = async () => {
    const payload = {
      name: createValue,
    };

    axios
      .post(`${apiURL}`, payload)
      .then(() => {
        toast(`${usage} successfully created!`, {
          bodyClassName: "text-green-500 ",
          progressClassName: "bg-green-500",
        });
        setAddNew(false);
        getData();
      })
      .catch(() => {
        toast(`Error creating new ${usage}`, {
          bodyClassName: "text-red-500 ",
          progressClassName: "bg-red-500",
        });
      });
  };

  return (
    <>
      <SearchBar onSearch={handleSearch} />

      <div className="h-64 overflow-scroll scrollbar-hide pe-2">
        {data.map((item, i) => (
          <div
            className="flex mt-2"
            key={item.id}
            ref={i === data.length - 1 ? setLastElement : null}
          >
            <Checkbox
              onClick={() => handleSelect(item.id)}
              checked={selectedItems.includes(item.id)}
            />
            <span className="ps-2">{item[columnName]}</span>
          </div>
        ))}
        {isLoading && <div>Loading...</div>}
      </div>

      <div className="flex justify-between mt-2 text-[18px]">
        <button className="flex items-center" onClick={() => setAddNew(true)}>
          <Plus />
          <span className="ps-2 pt-1">Create New {usage}</span>
        </button>
      </div>

      {addNew && (
        <div>
          <div className="mt-3">
            <p className="text-[16px] font-semibold">Name:</p>
            <input
              type="text"
              name="name"
              className="border-[1px] border-[neutral-400] p-2 text-[15px] rounded-lg w-full focus:border-2 focus:border-orange-600 outline-none"
              placeholder={`${usage} ${columnName}`}
              onChange={(e) => setCreateValue(e.target.value)}
            />
          </div>

          <div className="mt-3 flex justify-end">
            <button
              className="rounded-lg bg-orange-500 text-white px-2 py-1"
              onClick={handleAddNew}
            >
              Create
            </button>
          </div>
        </div>
      )}
    </>
  );
};
