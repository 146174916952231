import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DataResponse, ProductSelected } from "../../constants/types";
import { Checkbox } from "../common/Checkbox";
import { VoucherRow } from "./VoucherRow";
const headers = ["NAME", "Min Price", "Max Price"];

type CheckedItems = {
  [key: string]: boolean;
};

let checkAll = false;
let initialSelectedVouchers = new Set();

export const Vouchers = () => {
  const [data, setData] = useState<ProductSelected[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const ENV = process.env;
  const url = ENV.REACT_APP_ADMIN_URL;

  useEffect(() => {
    axios
      .get<DataResponse<ProductSelected[]>>(
        `${url}/api/admin/voucher/qwikcilver`,
      )
      .then((resp) => {
        const data: ProductSelected[] = resp.data.data;
        setData(data);

        initialSelectedVouchers = data.reduce(
          (set: Set<String>, obj: ProductSelected) => {
            if (obj.selected) {
              set.add(obj.sku);
            }
            return set;
          },
          new Set<String>(),
        );
        const vouchers = data.reduce(
          (acc, item) => ({ ...acc, [item.sku]: item.selected }),
          {},
        );
        setCheckedItems(vouchers);
        checkAll = Object.values(vouchers).every((selected) => selected);
      })
      .catch((error) => {
        toast.error("Error fetching data:", error);
      });
  }, [url]);

  const handleCheckAll = () => {
    checkAll = !checkAll;
    const checkedItemsCopy = Object.keys(checkedItems)
      .map((key) => ({
        [key]: checkAll,
      }))
      .reduce((newHashmap, entry) => ({ ...newHashmap, ...entry }), {});
    setCheckedItems(checkedItemsCopy);
  };
  const saveChanges = () => {
    setIsLoading(true);
    const toAdd = Object.entries(checkedItems)
      .filter(
        ([sku, isChecked]) => isChecked && !initialSelectedVouchers.has(sku),
      )
      .map(([key]) => key);

    const toRemove = Object.entries(checkedItems)
      .filter(
        ([sku, isChecked]) => !isChecked && initialSelectedVouchers.has(sku),
      )
      .map(([key]) => key);
    axios
      .post(`${url}/api/admin/voucher/qwikcilver/sync`, {
        provider: "Qwikcilver",
        toAdd,
        toRemove,
      })
      .then((response) => {
        alert("Successfully saved");
      })
      .catch((error) => {
        alert("An error has occurred - " + error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSelected = (sku: string) => {
    const checkedItemsCopy = { ...checkedItems };
    checkedItemsCopy[sku] = !checkedItemsCopy[sku];
    setCheckedItems(checkedItemsCopy);
  };

  return (
    <div className="bg-slate-50 min-h-screen">
      <div className="p-6 grid grid-flow-row gap-6 ">
        <div className="flex justify-between align-middle">
          <div className="flex justify-between gap-2">
            <h1 className="text-3xl">Voucher Management</h1>
          </div>

          <div className="grid grid-flow-col gap-2 my-1 text-sm">
            <button
              className={`${
                isLoading ? "bg-gray-400" : "bg-orange-500"
              } text-white rounded-lg px-4 flex py-3`}
              onClick={saveChanges}
              disabled={isLoading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              <span className="px-2">Save Changes</span>
            </button>
          </div>
        </div>
        <table className="m-2 w-full table-auto">
          <thead>
            <tr className="text-zinc-500 text-left bg-gray-200">
              <th className="align-middle justify-items-center">
                <Checkbox onClick={handleCheckAll} checked={checkAll} />
              </th>

              {headers.map((header) => (
                <th
                  key={header}
                  className={
                    header !== "NAME"
                      ? "p-2 align-items text-center px-4 "
                      : "p-2 w-5/12"
                  }
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <VoucherRow
                key={row.sku}
                row={row}
                onSelected={{
                  selected: checkedItems[row.sku],
                  handleSelect: () => handleSelected(row.sku),
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
