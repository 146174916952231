import { Link } from "react-router-dom";
import { Theme } from "../../constants/types";

export const ThemeRow = ({ theme }: { theme: Theme }) => {
  const { id, name, isHidden, isDeleted } = theme;

  return (
    <>
      <td className="p-2">
        <Link draggable="false" to={`/themes/${id}`}>
          <div className="flex">
            <div className="max-w-96">
              <div className="p-2 items-center text-left">
                <div className="hover:underline hover:underline-offset-2 ">
                  {name}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </td>

      <td className="p-2">{isHidden ? "Yes" : "No"}</td>
      <td className="p-2">{isDeleted ? "Yes" : "No"}</td>
    </>
  );
};
