import { useContext } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "./context/AppContextProvider";
import "./index.css";
import { router } from "./routes/AppRoutes";

export const App = () => {
  const { scroll } = useContext(AppContext);

  return (
    <div>
      <div
        className={`App bg-slate-50 font-LeagueSpartan ${
          !scroll && "max-h-screen overflow-hidden"
        }`}
      >
        <RouterProvider router={router} />
      </div>
      <ToastContainer />
    </div>
  );
};
