import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Charity } from "../../constants/types";
import { BreadcrumbContext } from "../../context/Breadcrumb.context";
import { SearchBar } from "../common/SearchBar";
import { Table, TableHeader } from "../common/Table";
import { DonationRow } from "./DonationRow";
import { DonationSelectPanel } from "./DonationSelectPanel";

const headers: TableHeader[] = [
  { heading: "ORGANISATION", key: "Charity.name", isSortable: true },
  { heading: "VISIBILITY", key: "VISIBILITY", isSortable: false },
  { heading: "DATE ADDED", key: "Charity.createdAt", isSortable: true },
];

type TCharityTab = {
  action: (createNew: () => void) => void;
};

export const DonationTab = ({ action }: TCharityTab) => {
  const { donationTable, setDonationTable } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const [charity, setCharity] = useState<Charity[]>([]);
  const [selectDonationIds, setSelectDonationIds] = useState<number[]>([]);
  const [isFetchingDonations, setIsFetchingDonations] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    setIsFetchingDonations(true);
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/charity`, {
        params: {
          limit: donationTable.limit,
          sort: donationTable.sort,
          sortOrder: donationTable.sortOrder,
          pageNumber: donationTable.pageNumber,
          searchInput: donationTable.searchInput,
        },
        signal: controller.signal,
      })
      .then((res) => {
        setCharity(res.data.data.results);
        setDonationTable({
          ...donationTable,
          totalPages: res.data.data.totalPages,
        });
        setIsFetchingDonations(false);
      })
      .catch(() => setIsFetchingDonations(false));
    return () => controller.abort();
  }, [
    donationTable.limit,
    donationTable.sort,
    donationTable.sortOrder,
    donationTable.pageNumber,
    donationTable.searchInput,
  ]);

  const multipleSelectOnClick = () => {
    if (selectDonationIds.length > 0) {
      setSelectDonationIds([]);
      return;
    }

    setSelectDonationIds((prev) => [
      ...prev,
      // TODO: Make api call to fetch all ids without pagination and add here
      ...charity.map((charity) => charity.id),
    ]);
  };

  const createNewAction = useCallback(() => {
    navigate("/donation/add");
  }, [navigate]);

  useEffect(() => {
    action(() => createNewAction);
  }, [action, createNewAction]);

  const unarchiveCharity = (id: number) => {
    axios
      .put(`${process.env.REACT_APP_ADMIN_URL}/charity/undelete/${id}`)
      .then((res) => {
        toast.success(`Charity ${id} has been unarchived`);
      })
      .catch((error) => {
        toast.error("Error encountered: ", error.message);
      });
  };

  return (
    <div>
      <SearchBar
        onSearch={(value) => {
          setDonationTable({
            ...donationTable,
            searchInput: value,
            pageNumber: 1,
          });
        }}
      />

      <div className="mt-4">
        <Table
          headers={headers}
          rows={charity.map((charity) => ({
            id: charity.id,
            component: <DonationRow charity={charity} />,
            isDeleted: charity.isDeleted,
            unarchive: unarchiveCharity,
          }))}
          pageNumber={donationTable.pageNumber}
          setPageNumber={(p) =>
            setDonationTable({ ...donationTable, pageNumber: p })
          }
          setItemsPerPage={(p) =>
            setDonationTable({ ...donationTable, pageNumber: 1, limit: p })
          }
          itemsPerPage={donationTable.limit}
          totalPages={donationTable.totalPages}
          isMultipleRowSelectable={true}
          multipleSelectOnClick={multipleSelectOnClick}
          multipleSelectChecked={selectDonationIds.length > 0}
          multipleSelectedIds={selectDonationIds}
          setSelectProductIds={setSelectDonationIds}
          selectionPanel={
            <DonationSelectPanel
              selectedIds={selectDonationIds}
              handleRemoveSelection={() => setSelectDonationIds([])}
            />
          }
          isLoading={isFetchingDonations}
          loadingMessage="Fetching products..."
          sortBy={donationTable.sort}
          setSortBy={(column) =>
            setDonationTable({ ...donationTable, sort: column })
          }
          sortOrder={donationTable.sortOrder}
          setSortOrder={(order: "ASC" | "DESC") =>
            setDonationTable({
              ...donationTable,
              sortOrder: order,
            })
          }
        />
      </div>
    </div>
  );
};
