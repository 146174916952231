import { useEffect, useState } from "react";
import { ReactComponent as Calendar } from "../../constants/images/icons/CalendarSolid.svg";
import { ReactComponent as Filter } from "../../constants/images/icons/Filter.svg";
import { ReactComponent as Refresh } from "../../constants/images/icons/Refresh.svg";
import { DatePicker } from "../common/DatePicker/DatePicker";
import { PopUpWindow } from "../common/PopUpWindow";
import { TOrderFilters } from "./OrdersFilterWindow";

export enum FilterOptions {
  AllDates = "All Dates",
  TwelveMonths = "12 Months",
  ThirtyDays = "30 Days",
  SevenDays = "7 Days",
  Today = "24 Hours",
}

const timeFilter = [
  { option: FilterOptions.AllDates, value: new Date().getTime() },
  { option: FilterOptions.TwelveMonths, value: 31536000000 }, //365 * 24 * 60 * 60 * 1000,
  { option: FilterOptions.ThirtyDays, value: 2592000000 }, //30 * 24 * 60 * 60 * 1000,
  { option: FilterOptions.SevenDays, value: 604800000 }, //7 * 24 * 60 * 60 * 1000,
  { option: FilterOptions.Today, value: 86400000 }, //24 * 60 * 60 * 1000,
];

export type TFilterPanel = {
  time: {
    from: Date;
    to: Date;
  };
  filter: TOrderFilters;
};

type FilterPanelProps = {
  value: TFilterPanel;
  filterWindow: JSX.Element;
  onFilterChange: (filters: TFilterPanel) => void;
  refresh: () => void;
};

export const FilterPanel = ({
  value,
  filterWindow,
  onFilterChange,
  refresh,
}: FilterPanelProps) => {
  const [selected, setSelected] = useState<FilterOptions>(
    FilterOptions.AllDates,
  );

  const [filters, setFilters] = useState<TFilterPanel>(value);

  const handleSelect = (option: FilterOptions, index: number) => {
    setSelected(option);
    setFilters((prev) => ({
      ...prev,
      time: {
        from: new Date(new Date().getTime() - timeFilter[index].value),
        to: new Date(),
      },
    }));
  };

  const handleDateSelect = (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate) {
      return;
    }
    setFilters((prev) => ({
      ...prev,
      time: {
        from: startDate,
        to: endDate,
      },
    }));
  };

  useEffect(() => {
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  const handleRefresh = () => {
    setSelected(FilterOptions.AllDates);
    setFilters({
      time: {
        from: new Date(0),
        to: new Date(),
      },
      filter: {
        campaigns: [],
        status: [],
        mode: [],
      },
    });
    refresh();
  };

  return (
    <div className="flex justify-between">
      {/* Date Select */}
      <div className="bg-white shadow-sm flex w-1/3">
        {timeFilter.map((item, i) => (
          <button
            key={item.option}
            className={`border-[1px] w-full text-sm py-2 ${
              selected === item.option
                ? "bg-slate-200 text-black font-bold "
                : "text-slate-600"
            }`}
            onClick={() => {
              handleSelect(item.option, i);
            }}
          >
            {item.option}
          </button>
        ))}
      </div>

      {/* Other buttons */}
      <div className="flex justify-end grid grid-cols-5 gap-2">
        <div className="col-span-2 relative">
          <PopUpWindow
            button={
              <button className="bg-white p-2 border-[1px] rounded-lg text-slate-400 flex justify-center items-center">
                <Calendar />
                <span className="ms-2 pt-1">Select Dates</span>
              </button>
            }
            children={
              <div className="p-2 w-96">
                <DatePicker
                  values={{
                    startDate: filters.time.from?.getTime()
                      ? filters.time.from
                      : new Date(),
                    endDate: filters.time.to?.getTime()
                      ? filters.time.to
                      : new Date(),
                  }}
                  onChange={handleDateSelect}
                />
              </div>
            }
          />
        </div>
        <div className="col-span-2">
          <PopUpWindow
            button={
              <button className=" w-full h-full bg-white p-2 border-[1px] rounded-lg text-slate-400 flex justify-center items-center">
                <Filter />
                <span className="ms-2 pt-1">Filter</span>
              </button>
            }
            children={filterWindow}
          />
        </div>
        <button className="col-span-1" onClick={handleRefresh}>
          <Refresh className="stroke-slate-400 fill-slate-400" />
        </button>
      </div>
    </div>
  );
};
