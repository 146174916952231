import images from "../../constants/images";
import { Product } from "../../constants/types";
import { Copy } from "../common/Copy";

type PropTypes = {
  orders: any;
  closeModal: () => void;
};

export const ProductDetail = ({ orders, closeModal }: PropTypes) => {
  const ordersDetails = orders.map((order: { product: Product }) => {
    return [
      {
        title: "Brand",
        value: order.product.brand,
        allowCopy: true,
      },
      {
        title: "Name",
        value: order.product.name,
        allowCopy: true,
      },
      {
        title: "Platform URL",
        value: order.product.sellPlatformUrl,
        allowCopy: true,
      },
      {
        title: "Cost Price",
        value: order.product.costPrice,
        allowCopy: true,
      },
      {
        title: "List Price",
        value: order.product.listPrice,
        allowCopy: true,
      },
      {
        title: "Delivery Cost",
        value: order.product.deliveryFee,
        allowCopy: true,
      },
    ];
  });
  return (
    <div className="bg-black bg-opacity-60 flex items-center justify-center overflow-scroll fixed top-0 left-0 w-full h-full z-40">
      <div className="bg-white p-8 w-96 rounded-md">
        <div className="flex justify-between">
          <div className="text-xl font-LeagueSpartan">Product Details</div>
          <button onClick={closeModal} id={orders.id}>
            <img src={images.cross} />
          </button>
        </div>

        <div className="mt-8">
          {ordersDetails &&
            ordersDetails.map((order: any[], index: number) => {
              return (
                <div key={index}>
                  {order.map((detail, detailIndex) => {
                    return (
                      <div
                        key={detailIndex}
                        className="flex items-center justify-between mb-4"
                      >
                        <div className="flex items-center">
                          <div className="text-left ml-4 text-sm font-LeagueSpartan">
                            <div className="text-slate-500" id="title">
                              {detail.title}
                            </div>
                            <div id="value" className="w-56 overflow-hidden">
                              {detail.value ? detail.value : "-"}
                            </div>
                          </div>
                        </div>
                        <div>
                          {detail.allowCopy && <Copy text={detail.value} />}
                        </div>
                      </div>
                    );
                  })}
                  <div className="text-center text-slate-600">~</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
