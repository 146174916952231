import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as Archive } from "../../constants/images/icons/Archive.svg";
import { ReactComponent as Cross } from "../../constants/images/icons/Cross.svg";
import { ReactComponent as Plus } from "../../constants/images/icons/Plus.svg";
import { SelectCollection } from "./SelectCollection";

export const DonationSelectPanel = ({
  selectedIds,
  handleRemoveSelection,
}: {
  selectedIds: number[];
  handleRemoveSelection: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const url = process.env.REACT_APP_ADMIN_URL;
  const [displaySelectCollection, setDisplaySelectCollection] =
    useState<boolean>(false);

  const handleManyDelete = async () => {
    const payload = selectedIds;
    axios
      .delete(`${url}/charity/`, { data: payload })
      .then((res) => {
        toast.success("Selected charities were successfully deleted");
      })
      .catch((error) => {
        toast.error("Error encountered: ", error.message);
      });
  };
  return (
    <div className="flex justify-between w-full py-1 border-b-2 bg-white">
      <div className="flex items-center">
        <div>
          <p className="px-6 text-orange-500 border-r-2">
            {selectedIds.length} selected
          </p>
        </div>
        <div>
          <button
            onClick={() => setDisplaySelectCollection(!displaySelectCollection)}
            className="flex justify-center hover:bg-slate-300 rounded-lg p-2 m-1 font-s"
          >
            <Plus className="inline pe-1 pb-1" />
            <span>Add to Collection</span>
          </button>
        </div>
        <div>
          {displaySelectCollection && (
            <SelectCollection
              selectedIds={selectedIds}
              attributeAddedToCollection="charity"
            />
          )}
        </div>
        <div>
          <button
            onClick={handleManyDelete}
            className="hover:bg-slate-300 rounded-lg p-2 m-1 font-s"
          >
            <Archive className="inline pe-1 pb-1" />
            <span>Archive</span>
          </button>
        </div>
      </div>

      <div className="flex justify-end px-3">
        <button onClick={handleRemoveSelection}>
          <Cross className="inline pb-1 w-6 h-6 me-4" />
        </button>
      </div>
    </div>
  );
};
