import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Collection } from "../../constants/types";
import { BreadcrumbContext } from "../../context/Breadcrumb.context";
import { SearchBar } from "../common/SearchBar";
import { Table, TableHeader } from "../common/Table";
import { CollectionRow } from "./CollectionRow";
import { CollectionSelectPanel } from "./CollectionSelectPanel";

type TCollectionTab = {
  action: (createNew: () => void) => void;
};

const headers: TableHeader[] = [
  { heading: "COLLECTION", key: "Collection.name", isSortable: true },
  //TODO: update account field to later version of admin/collection api call. API call should return corp name as well
  { heading: "VISIBLE TO", key: "Collection.corp.id", isSortable: true },
  { heading: "VISIBILITY", key: "VISIBILITY", isSortable: false },
  { heading: "LAST UPDATED", key: "Collection.updatedAt", isSortable: true },
  {
    heading: "ITEM COUNT",
    key: "Collection.collectionTags.length",
    isSortable: true,
  },
];

export const CollectionTab = ({ action }: TCollectionTab) => {
  const { collectionTable, setCollectionTable } = useContext(BreadcrumbContext);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [selectCollectionIds, setSelectCollectionIds] = useState<number[]>([]);

  const [isFectingCollections, setIsFectingCollections] = useState(true);

  const multipleSelectOnClick = () => {
    if (selectCollectionIds.length > 0) {
      setSelectCollectionIds([]);
      return;
    }

    setSelectCollectionIds((prev) => [
      ...prev,
      // TODO: Make api call to fetch all ids without pagination and add here
      ...collections.map((collection) => collection.id),
    ]);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    setIsFectingCollections(true);
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/collections`, {
        params: {
          limit: collectionTable.limit,
          sort: collectionTable.sort,
          sortOrder: collectionTable.sortOrder,
          pageNumber: collectionTable.pageNumber,
          searchInput: collectionTable.searchInput,
        },
        signal: controller.signal,
      })
      .then((res) => {
        setCollections(res.data.data.results);
        setCollectionTable({
          ...collectionTable,
          totalPages: res.data.data.totalPages,
        });
        setIsFectingCollections(false);
      })
      .catch(() => setIsFectingCollections(false));
    return () => controller.abort();
  }, [
    collectionTable.limit,
    collectionTable.sort,
    collectionTable.sortOrder,
    collectionTable.pageNumber,
    collectionTable.searchInput,
  ]);

  const createNewAction = useCallback(() => {
    navigate("/collections/add");
  }, [navigate]);

  useEffect(() => {
    action(() => createNewAction);
  }, [action, createNewAction]);

  const unarchiveCollection = (id: number) => {
    axios
      .put(`${process.env.REACT_APP_ADMIN_URL}/collections/undelete/${id}`)
      .then((res) => {
        toast.success(`Collection ${id} has been unarchived`);
      })
      .catch((error) => {
        toast.error("Error encountered: ", error.message);
      });
  };

  return (
    <div>
      <SearchBar
        onSearch={(value) => {
          setCollectionTable({
            ...collectionTable,
            searchInput: value,
            pageNumber: 1,
          });
        }}
      />

      <div className="mt-4">
        <Table
          headers={headers}
          rows={collections.map((collection) => ({
            id: collection.id,
            component: <CollectionRow collection={collection} />,
            isDeleted: collection.isDeleted,
            unarchive: unarchiveCollection,
          }))}
          pageNumber={collectionTable.pageNumber}
          setPageNumber={(p) => {
            setCollectionTable({ ...collectionTable, pageNumber: p });
          }}
          setItemsPerPage={(p) => {
            setCollectionTable({ ...collectionTable, limit: p, pageNumber: 1 });
          }}
          itemsPerPage={collectionTable.limit}
          totalPages={collectionTable.totalPages}
          isMultipleRowSelectable={true}
          multipleSelectOnClick={multipleSelectOnClick}
          multipleSelectChecked={selectCollectionIds.length > 0}
          multipleSelectedIds={selectCollectionIds}
          setSelectProductIds={setSelectCollectionIds}
          selectionPanel={
            <CollectionSelectPanel
              selectedIds={selectCollectionIds}
              handleRemoveSelection={() => setSelectCollectionIds([])}
            />
          }
          isLoading={isFectingCollections}
          loadingMessage="Fetching Collections..."
          sortBy={collectionTable.sort}
          setSortBy={(column) =>
            setCollectionTable({
              ...collectionTable,
              sort: column,
            })
          }
          sortOrder={collectionTable.sortOrder}
          setSortOrder={(order: "ASC" | "DESC") =>
            setCollectionTable({
              ...collectionTable,
              sortOrder: order,
            })
          }
        />
      </div>
    </div>
  );
};
