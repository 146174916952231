import axios from "axios";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as Archive } from "../../constants/images/icons/Archive.svg";
import { ReactComponent as Cross } from "../../constants/images/icons/Cross.svg";
import { ReactComponent as Plus } from "../../constants/images/icons/Plus.svg";
import { ReactComponent as Delete } from "../../constants/images/icons/Delete.svg";

import { SelectCorp } from "./SelectCorp";

export const ThemeSelectPanel = ({
  selectedIds,
  handleRemoveSelection,
}: {
  selectedIds: number[];
  handleRemoveSelection: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const url = process.env.REACT_APP_ADMIN_URL;
  const [displaySelectCorp, setDisplaySelectCorp] = useState<boolean>(false);

  const handleManyArchive = async () => {
    selectedIds.forEach((id) => {
      axios
        .patch(`${url}/products/themes/${id}/archive`)
        .then((res) => {
          toast.success(`Theme ${id} was successfully archived`);
        })
        .catch((error) => {
          toast.error(
            `Error encountered while archiving theme ${id}: ${error.message}`,
          );
        });
    });
  };

  const handleManyDelete = async () => {
    selectedIds.forEach((id) => {
      axios
        .delete(`${url}/products/themes/${id}`)
        .then((res) => {
          toast.success(`Theme ${id} was successfully deleted`);
        })
        .catch((error) => {
          toast.error(
            `Error encountered while deleting theme ${id}: ${error.message}`,
          );
        });
    });
  };

  const handleAddToAccount = async () => {
    setDisplaySelectCorp(!displaySelectCorp);
  };

  return (
    <div className="flex justify-between w-full py-1 border-b-2 bg-white">
      <div className="flex items-center">
        <div>
          <p className="px-6 text-orange-500 border-r-2">
            {selectedIds.length} selected
          </p>
        </div>
        <div>
          <button
            className="flex justify-center hover:bg-slate-300 rounded-lg p-2 m-1 font-s"
            onClick={handleAddToAccount}
          >
            <Plus className="inline pe-1 pb-1" />
            <span>Add to Account</span>
          </button>
        </div>
        <div>
          {displaySelectCorp && (
            <SelectCorp selectedItems={selectedIds} itemType="theme" />
          )}
        </div>
        <div>
          <button
            onClick={handleManyArchive}
            className="hover:bg-slate-300 rounded-lg p-2 m-1 font-s"
          >
            <Archive className="inline pe-1 pb-1" />
            <span>Archive</span>
          </button>
        </div>
        <div>
          <button
            onClick={handleManyDelete}
            className="hover:bg-slate-300 rounded-lg p-2 m-1 font-s"
          >
            <Delete className="inline pe-1 pb-1" />
            <span>Delete</span>
          </button>
        </div>
      </div>
      <div className="flex justify-end px-3">
        <button onClick={handleRemoveSelection}>
          <Cross className="inline pb-1 w-6 h-6 me-4" />
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};
