import axios from "axios";
import { useEffect, useState } from "react";
import { SearchBar } from "../common/SearchBar";
import { Table, TableHeader } from "../common/Table";
import { ProductRow } from "./ProductRow";

export const TableWrapper = (
  apiUrl: string,
  headers: TableHeader[],
  selectedIds: (ids: number[]) => void,
  setSelectedIds: number[],
  isLoadingData: boolean,
) => {
  const [data, setData] = useState<any[]>([]);
  const [selectedDataIds, setSelectedDataIds] =
    useState<number[]>(setSelectedIds);
  const [isFetching, setIsFetching] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");

  useEffect(() => {
    setSelectedDataIds(setSelectedIds);
  }, [isLoadingData]);

  useEffect(() => {
    selectedIds(selectedDataIds);
  }, [selectedDataIds]);

  useEffect(() => {
    const controller = new AbortController();
    setIsFetching(true);

    axios
      .get(apiUrl, {
        params: {
          limit: itemsPerPage,
          sort: sortBy,
          sortOrder,
          pageNumber,
          searchInput,
          selectedValues: selectedDataIds,
        },
        signal: controller.signal,
      })
      .then((res) => {
        setData(res.data.data.results);
        setTotalPages(res.data.data.totalPages);
        setIsFetching(false);
      })
      .catch(() => setIsFetching(false));
    return () => controller.abort();
  }, [
    itemsPerPage,
    pageNumber,
    searchInput,
    sortBy,
    sortOrder,
    selectedDataIds,
  ]);

  const multipleSelectOnClick = () => {
    if (selectedDataIds.length > 0) {
      setSelectedDataIds([]);
      return;
    }

    setSelectedDataIds((prev) => [...prev, ...data.map((item) => item.id)]);
  };

  return (
    <div className="gap-6 mt-4 rounded-lg bg-white shadow-md">
      <div className="container p-6">
        <div className="text-xl">Product</div>
        <SearchBar
          onSearch={(value) => {
            setSearchInput(value);
            setPageNumber(1);
          }}
        />
        <div className="mt-2">
          <Table
            headers={headers}
            rows={data.map((item) => ({
              id: item.id,
              component: <ProductRow product={item} />,
            }))}
            pageNumber={pageNumber}
            setPageNumber={(p) => setPageNumber(p)}
            setItemsPerPage={(p) => setItemsPerPage(p)}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            isMultipleRowSelectable={true}
            multipleSelectOnClick={multipleSelectOnClick}
            multipleSelectChecked={selectedDataIds.length > 0}
            multipleSelectedIds={selectedDataIds}
            setSelectProductIds={setSelectedDataIds}
            isLoading={isFetching}
            loadingMessage="Fetching products..."
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
        </div>
      </div>
    </div>
  );
};
